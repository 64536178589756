import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"

const Navbar = props => {
  const [menu, setMenu] = useState(props.menu)
  const [title,setTitle] = useState(props.title)
  const [indexSet, setindexSet] = useState("")
  console.log()
  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.valuename === items[i].valuename) {
        matchingMenuItem = items[i]
        break
      }
    }
    // if (matchingMenuItem) {
    //   activateParentDropdown(matchingMenuItem)
    // }
  })
  const hideShowDropDown = index => {
    setindexSet(index)
  }
  // function activateParentDropdown(item) {
  //   item.classList.add("active")
  //   const parent = item.parentElement
  //   console.log(parent.parentElement)
  //   if (parent) {
  //     parent.classList.add("active") // li
  //     const parent2 = parent.parentElement
  //     parent2.classList.add("active") // li
  //     const parent3 = parent2.parentElement
  //     if (parent3) {
  //       parent3.classList.add("active") // li
  //       const parent4 = parent3.parentElement
  //       if (parent4) {
  //         parent4.classList.add("active") // li
  //         const parent5 = parent4.parentElement
  //         if (parent5) {
  //           parent5.classList.add("active") // li
  //           const parent6 = parent5.parentElement
  //           if (parent6) {
  //             parent6.classList.add("active") // li
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return false
  // }
  console.log(indexSet)
  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {props.menu !== undefined
                  ? props.menu.map((d, index) => (
                      <li className="nav-item dropdown" key={index}>
                        {
                          title ==`/${d.value}` ?
                          <Link
                          to={`${d.value}`}
                          className="nav-link dropdown-toggle arrow-none"
                          style={{  color:'#FFF'}}
                        >
                          {props.t(`${d.label}`)}
                          {d.submenu !== undefined ? (
                            d.submenu.length !== 0 ? (
                              <div
                                className="arrow-down"
                                onClick={() => hideShowDropDown(index)}
                              ></div>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </Link>
                          :  <Link
                          to={`${d.value}`}
                          className="nav-link dropdown-toggle arrow-none"
                          
                        >
                          {props.t(`${d.label}`)}
                          {d.submenu !== undefined ? (
                            d.submenu.length !== 0 ? (
                              <div
                                className="arrow-down"
                                onClick={() => hideShowDropDown(index)}
                              ></div>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </Link>
                        }
                       
                        {d.submenu !== undefined ? (
                          d.submenu.length !== 0 ? (
                            <div
                              className={classname("dropdown-menu", {
                                show: indexSet === index ? true : false,
                              })}
                            >
                              {d.submenu.map((k, index) => (
                                <Link
                                  to={`${k.value}`}
                                  className="dropdown-item"
                                  key={index}
                                >
                                  {props.t(`${k.label}`)}
                                </Link>
                              ))}
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </li>
                    ))
                  : ""}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  title:PropTypes.string,
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
  menu: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
