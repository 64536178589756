import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { Link } from "react-router-dom"
import classnames from "classnames"
import TextEditor from "UI/TextEditor"
import { AvField } from "availity-reactstrap-validation"
import { deleteUploadFile, postUploadFile } from "data/fileuplaod"
import { url } from "routes"
import { postPageContent } from "data/pageStructure"
import MultiRowTable from "UI/Multirow/MultiRowTable"
import { EmptyObject } from "constants/emptypobject"
const CustomeTab = ({data,submitFormData,singleItem,saveData}) => {
    const [datas,setDatas]=useState(data)
    const [customActiveTab, setcustomActiveTab] = useState(0)
    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
          setcustomActiveTab(tab)
        }
      }
      const onEditorChange = (e,type,i) => {
    
        const d =[...datas]
        d[i][type] = e.editor.getData();
        console.log(d)
        setDatas([...d])
  
      }
      const onChangeInput = (e,type,i) => {
        const {name,value} = e.target;
        const d =[...datas]
        d[i][type] = value;
        console.log(d)
        setDatas([...d])
      }
      const uploadImage = async (e, name, type) => {
        const d =[...datas]
        console.log(d[type][name]) 
         for (let i = 0; i < e.target.files.length; i++) {
            await postUploadFile(e.target.files[i], 'page').then(result => {
              if (result.success) {
               console.log(result.photo)
                d[type][name].push(result.photo)
              
              }
            })
          }
        
          setDatas([...d])
       
      }
      const deleteUploadFiles = (e, ind,i) => {
        const d =[...datas]
        d[i]['photo']
        const filteD  =  d[i]['photo'].filter((d,l)=> l !==ind);
        deleteUploadFile(d[i]['photo'][ind],'page').then(result=>{
          console.log(result)
          if(result.success){
        d[i]['photo'] = filteD;
        setDatas([...d])
        }else{

        }
     })
       
      }

  // const postAddMoreData = (e, values,type) => {
  //   console.log(values[type])
   
  // }
   const changeTableRow = (e, ins,inputList,i) => {
    const d =[...datas]
 
    const {name,value} = e.target;
  
   d[i]['addmore'][ins][name] =value;

    if( d[i]['addmore'].length === parseInt(ins + 1) ){
      d[i]['addmore'].push({ ...inputList})
      d[i]['addmore'][parseInt(ins + 1)]['id'] =Date.now().toString()
    }
      setDatas([...d])
}
 console.log(datas) 
 const deletedDataTable = (e, index,type,i) => {
 
  const d =[...datas]
  console.log(d[i][type])
  d[i][type].splice(index,1);
  console.log(d[i][type])
  setDatas([...d])
}
console.log(singleItem)
    return(
        <Card>
<CardBody>
<CardHeader>{singleItem.name}   
  {
    singleItem.pagedescriptionid != undefined ?
    <a href={`${url.commonUrls}/${singleItem.pagedescriptionid.slug}/${singleItem.slug}?search=admin`} className="text-danger" target="_blank" rel="noreferrer">
    <i
      className="mdi mdi-eye font-size-18"
      id="deletetooltip"
      
    />
  </a>
    :<a href={`${url.commonUrls}/${singleItem.slug}?search=admin`} className="text-danger" target="_blank" rel="noreferrer">
    <i
      className="mdi mdi-eye font-size-18"
      id="deletetooltip"
      
    />
  </a>
  }
 </CardHeader>

  <Nav tabs className="nav-tabs-custom nav-justified">
    {
       datas !== null && datas !== undefined && datas.map((d,i)=>(
            <NavItem key={i}>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === i,
              })}
              onClick={() => {
                toggleCustom(i)
              }}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">{d.descriptiontype.name}</span>
            </NavLink>
          </NavItem>
        ))
    }
   
    
  </Nav>

  <TabContent
    activeTab={customActiveTab}
    className="p-3 text-muted"
  >
   {
       datas !== null && datas !== undefined && datas.map((d,i)=>(
        <TabPane tabId={i} key={i}>
          <Row>
          <h6>{d.descriptiontype.name}</h6>

            <CardText className="mb-0">
              {
                d.descriptiontype.descriptiontype== 'description'?
                <TextEditor
                title={d.descriptiontype.name}
                description={d[d.descriptiontype.descriptiontype]}
                onEditorChange={e => onEditorChange(e,d.descriptiontype.descriptiontype,i)}
               
              />
                :d.descriptiontype.descriptiontype== 'photo'?
                <>
                <input
                className="form-control"
                type='file'
                onChange={e => uploadImage(e,'photo',i)}
               />
                 {
                  d['photo'].map((d, index) => (
      <Col lg={2} key={index}>
        <img
          className="rounded-circle avatar-xl"
          width="200"
          src={`${url.commonUrls}/image/page/${d}`}
        />
        <i
          className="mdi mdi-delete font-size-18"
          id="deletetooltip"
          onClick={e => deleteUploadFiles(e, index, i,'page')}
          style={{ position: "relative", top: "-39px" }}
        />
      </Col>
    ))
                  }
                </>
                :d.descriptiontype.descriptiontype== 'input'?
                
                  <input
                  className="form-control"
                  type={`${d.descriptiontype.descriptiontype}`}
                  name={`${d.descriptiontype.descriptiontype}`}
                  value={d[d.descriptiontype.descriptiontype]}
                  onChange={(e)=>onChangeInput(e, d.descriptiontype.descriptiontype,i)}
                 />
               
                :d.descriptiontype.descriptiontype == 'textarea'?
                <textarea
                className="form-control"
                name={`${d.descriptiontype.descriptiontype}`}
                value={d[d.descriptiontype.descriptiontype]}
                onChange={(e)=>onChangeInput(e, d.descriptiontype.descriptiontype,i)}
                rows="300" 
                cols="50"
               ></textarea>
              :
                <>
                {d.descriptiontype.descriptiontype}
                <MultiRowTable
                deletedDataTable={(e,index) => deletedDataTable(e,index ,'addmore',i)}
                handleValidSubmits={(e,v)=>postAddMoreData(e,v,'addmore')} 
                inputList={
                  d.descriptiontype.descriptiontype == 'addone' ?
                  { id: Date.now().toString(), title: ''}
                  : d.descriptiontype.descriptiontype == 'addtwo' ?
                  { id: Date.now().toString(), title: '',value:'',}
                  : d.descriptiontype.descriptiontype == 'addthree' ?
                  { id: Date.now().toString(), title: '',value:'',value1:'',}
                  : d.descriptiontype.descriptiontype == 'addfour' ?
                  { id: Date.now().toString(), title: '',value:'',value1:'',value2:'',}
                  : { id: Date.now().toString(), title: '',value:'',value1:'',value2:'',value3:'',}
                } 
                type={'addmore'}
                changeTableRow={(e,ins,inputList) => changeTableRow(e, ins,inputList,i,   d.descriptiontype.descriptiontype == 'addone' ?
                { id: Date.now().toString(), title: ''}
                : d.descriptiontype.descriptiontype == 'addtwo' ?
                { id: Date.now().toString(), title: '',value:'',}
                : d.descriptiontype.descriptiontype == 'addthree' ?
                { id: Date.now().toString(), title: '',value:'',value1:'',}
                : d.descriptiontype.descriptiontype == 'addfour' ?
                { id: Date.now().toString(), title: '',value:'',value1:'',value2:'',}
                : { id: Date.now().toString(), title: '',value:'',value1:'',value2:'',value3:'',}) }
                 addMoreTable={ datas[i]['addmore']} 
                multiRowheader={
                  d.descriptiontype.descriptiontype == 'addone' ?
                  [{ title: "Sl. No." }, { title: "Title" },{ title: 'Deleted' }]
                  : d.descriptiontype.descriptiontype == 'addtwo' ?
                  [{ title: "Sl. No." }, { title: "Title" },{title:'Value'},{ title: 'Deleted' }]
                  : d.descriptiontype.descriptiontype == 'addthree' ?
                  [{ title: "Sl. No." }, { title: "Title" },{title:'Value'},{title:'Value 1'},{ title: 'Deleted' }]
                  : d.descriptiontype.descriptiontype == 'addfour' ?
                  [{ title: "Sl. No." }, { title: "Title" },{title:'Value'},{title:'Value 1'},{title:'Value 2'},{ title: 'Deleted' }]
                  :[{ title: "Sl. No." }, { title: "Title" },{title:'Value'},{title:'Value 1'},{title:'Value 2'},{title:'Value 3'},{ title: 'Deleted' }]}
                 
               />
                </>
              }
            <br/>
            <br/>
           
              <Row>
                <Col lg={11}></Col>

                <Col lg={1}>
                  <div className="mb-3">
                    {
                      saveData && saveData != undefined && saveData != null ?
                      <Button                      
                      type="submit"
                      color="success" onClick={(e)=>submitFormData(e,data)} 
                      disabled
                      >
                     Save Loading...
                    </Button>
                      :  <Button                      
                      type="submit"
                      color="success" onClick={(e)=>submitFormData(e,data)} >
                     Save
                    </Button>
                    }
                   
                  </div>
                </Col>
              </Row>
            
            </CardText>
          </Row>
         
      </TabPane>
       ))
  }
    
   
  </TabContent>
</CardBody>
</Card>
    )
}
CustomeTab.propTypes = {
    saveData: PropTypes.bool,
    submitFormData: PropTypes.func,
    data: PropTypes.array,
    singleItem: PropTypes.object
}
export default CustomeTab