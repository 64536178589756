import React, { useState } from "react";
import PropTypes from 'prop-types';
import Select from "react-select"
import BootstrapTable from "react-bootstrap-table-next"
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,

} from "reactstrap"
import UiLoading from "UI/loading";
const ReactTable = ({searchValueLists,searchType,selectSearchData, message,searchFilter,selctdropDownList,isLoading, title, datas, columns, changeTable, addButton }) => {
  const [rowData,setRowData]= useState([])
  const [searchValue,setSearchValue] = useState(false)
  const [searchLists,setSearchLists]= useState({})
  const [searchDats,setSearchDats]= useState('')
  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    bgColor: (row, rowIndex) => (rowIndex > 1 ? '#00BFFF' : '#00FFFF'),
    onSelect: (row, isSelect, rowIndex, e) => {
      console.log(isSelect)
      console.log(row)
      const rowList=[...rowData]
       if(e.target.checked){
        if(!rowList.some(kl=> `${kl}` == `${row._id}`)){
          rowList.push(row._id)
          setRowData([...rowList])
        }
     
       }else{
       let d = rowList.filter(d=> `${d}` != `${row._id}`)
         setRowData([...d])
        
       }
    },
    onSelectAll: (isSelect, rows) => {
      if(isSelect){
        const d = rows.map(d=> d._id)
        console.log(d)
        setRowData([...d])
      }else{
        setRowData([])
      }
    
    }
  };
  const rowStyle2 = (row, rowIndex) => {
    console.log(row.duplicatestatus)
    const style = {};
  
    if (!row.duplicatestatus) {
      style.backgroundColor = '#c8e6c9';
      style.color = '#000000';
    }else{
      style.backgroundColor = 'red';
      style.color = '#fff';
    }
    return  style
  };
  const searchList=(e,search)=>{
    console.log(search)
    setSearchValue(search)
    setSearchLists({})
    setSearchDats('')
    
  }
  console.log(searchValue)
  const selectSearchType=(e)=>{
   setSearchLists(e)
   setSearchDats('')
  }
  const allSearchData=(e)=>{
    const {name,value} = e.target;
    setSearchDats(value)
  }
  return (
    <Card>
    <CardBody>
      {
        isLoading ? 
        <UiLoading/>
        :
        <React.Fragment>
        <Row className="mb-2">
          <Col sm="3">

    <div className="mb-3 templating-select select2-container">
    <Select
        value={searchFilter}
        onChange={(e) => selctdropDownList(e,rowData)}
        options={
          addButton !== undefined ?
          addButton ?
          [{label:'Default',value:'Default'},{label:'Duplicate',value:'Duplicate'},{label:'Export',value:'Export'},{label:'Multiple Deleted',value:'Multiple Deleted'},{label:'Sample',value:'Sample'}]
          : [{label:'Default',value:'Default'},{label:'Export',value:'Export'},{label:'Multiple Deleted',value:'Multiple Deleted'},{label:'Sample',value:'Sample'}]
          :  [{label:'Default',value:'Default'},{label:'Duplicate',value:'Duplicate'},{label:'Export',value:'Export'},{label:'Multiple Deleted',value:'Multiple Deleted'},{label:'Sample',value:'Sample'}]
        }
        classNamePrefix="select2-selection"
      />
    </div>
    
          </Col>
      {
        searchValue ? 
        <>
        {
          Object.keys(searchLists).length != 0 && searchLists.label == 'Import'? 
          <Col sm="4">

          <div className="mb-3 templating-select select2-container">
          <input type="file" className="form-control " />
          
          </div>
          
                </Col>
          
          :       
          <Col sm="4">
    
    <div className="mb-3 templating-select select2-container">
    <input type="text" className="form-control " value={searchDats} onChange={(e=>allSearchData(e))}/>
    
    </div>
    
          </Col>
        }
 
      <Col sm="2">
  <div className="mb-3 templating-select select2-container">
<Select
    value={searchLists}
    onChange={(e) => selectSearchType(e)}
    options={[{label:'Default',value:'Default'},{label:'Active',value:'Active'},{label:'In Active',value:'In Active'},{label:'Import',value:'Import'}]}
    classNamePrefix="select2-selection"
  />
</div>
      </Col>
      <Col sm="1">

      <div className="mb-3 templating-select select2-container">

      <Button
                            type="submit"
                            color="success"
                            onClick={()=>searchValueLists( searchDats,searchLists)}
                          >
                            Search
                          </Button>
      </div>

      </Col>
      <Col sm="2">
            <div className="text-sm-end">
              {
                addButton !== undefined ?
                  addButton ?
                  <>
                    <Button
                    type="button"
                    color="success"
                    className="btn-rounded  mb-2 me-2"
                    onClick={(e)=>searchList(e,!searchValue)}
                  >
                    <i className="mdi mdi-magnify me-1" />
                    
                  </Button>
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={(e)=>changeTable(e,'Add',title)}
                    >
                      <i className="mdi mdi-plus me-1" />
                      {/* {`Add ${title}`} */}
                    </Button>
                  </>
                    : ''

                  : 
                  <>
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded  mb-2 me-2"
                    onClick={(e)=>searchList(e,!searchValue)}
                  >
                    <i className="mdi mdi-magnify me-1" />
                    
                  </Button>
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded  mb-2 me-2"
                    onClick={(e)=>changeTable(e,'Add',title)}
                  >
                    <i className="mdi mdi-plus me-1" />
                    
                  </Button>
                  </>
              }



            </div>
          </Col>
        </>
        
        :<Col sm="9">
        <div className="text-sm-end">
          {
            addButton !== undefined ?
              addButton ?
              <>
                <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={(e)=>searchList(e,!searchValue)}
              >
                <i className="mdi mdi-magnify me-1" />
                
              </Button>
                <Button
                  type="button"
                  color="success"
                  className="btn-rounded  mb-2 me-2"
                  onClick={(e)=>changeTable(e,'Add',title)}
                >
                  <i className="mdi mdi-plus me-1" />
                  </Button>
              </>
                : ''

              : 
              <>
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={(e)=>searchList(e,!searchValue)}
              >
                <i className="mdi mdi-magnify me-1" />
                
              </Button>
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={(e)=>changeTable(e,'Add',title)}
              >
                <i className="mdi mdi-plus me-1" />
                
              </Button>
              </>
          }



        </div>
      </Col>
      }    
    </Row>
        <Row>
          <Col xl="12">
            <div className="table-responsive">
              <BootstrapTable
                keyField="_id"
                responsive
                bordered={true}
                striped={false}

                data={datas}
                columns={columns}
                classes={
                  "table align-middle table-nowrap table-check"
                }
                headerWrapperClasses={"table-light"}
                selectRow={ selectRow }
                
                rowStyle={ rowStyle2 }
              />
            </div>

          </Col>
        </Row>

      </React.Fragment>
      }
          
       
          



    </CardBody>
    </Card>
  )
}
ReactTable.propTypes = {
  isLoading: PropTypes.bool,
  datas: PropTypes.array,
  columns: PropTypes.array,
  tablelink: PropTypes.string,
  title: PropTypes.string,
  changeTable: PropTypes.func,
  addButton: PropTypes.bool,
  searchFilter:PropTypes.object,
  selctdropDownList: PropTypes.func,
  message: PropTypes.string,
  searchType: PropTypes.bool,
  selectSearchData: PropTypes.func,
  searchValueLists: PropTypes.func,
};
export default ReactTable