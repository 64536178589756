
import {url} from 'routes';
export const getpageStructureStatus=async(table,status)=>{
    return fetch(`${url.page}/status?table=${table}&&status=${status}`,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
export const getpageStructure=async(table,status)=>{
    return fetch(`${url.page}?table=${table}&&status=${status}`,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}

export const postPageStructure=async(data,table,operation)=>{
    return fetch(`${url.page}?table=${table}&&operation=${operation}`,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
export const getPageContent=async(id,type)=>{
    return fetch(`${url.page}/page-description/${id}?type=${type}`,{
        method:'GET',
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}


export const postPageContent=async(data,type)=>{
    return fetch(`${url.page}/page-description?type=${type}`,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}