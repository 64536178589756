import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import Cookies from 'js-cookie'
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"



import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"



// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/images.png"
import { url } from "routes"
import { getCountRegister,getUserRegister } from "data/authentication"
import UiLoading from "UI/loading"
import { generate_random_number, generate_random_string } from "data/middlefunctions"


const Login = props => {
  const [titleData,setTitleData]= useState(localStorage.getItem('company')!== undefined && localStorage.getItem('company')!== null ?JSON.parse(localStorage.getItem('company')) :{})
  const [userData,setUserData]= useState(localStorage.getItem('user')!== undefined && localStorage.getItem('user')!== null ?JSON.parse(localStorage.getItem('user')) :{})
  const [randomnumber,setRandomNmber] = useState('123456')
  const [isLoading,setIsLoading] = useState(true)
  const [message,setMessage] = useState('')
  const [error,setError] = useState('')
  const [typofLogin,setTypeofLogin] = useState('super')
  const [loginLoding,setLoginLoading] = useState(false)
  // handleValidSubmit
  
  useEffect(async ()=>{
    setRandomNmber(generate_random_number(6))
    await getCountRegister().then(result=>{
      console.log(result.data)
    
      if(result.success){
        if(result.count == 0){
          localStorage.setItem('company',JSON.stringify(result.data))
          window.location.href=`${process.env.PUBLIC_URL}/register`
        }else{
         setIsLoading(false)
        }
      }else{
       
        window.location.href=`${process.env.PUBLIC_URL}/`
      }
    })
   },[])

const changeAdmin=(e)=>{
  const {name,value} = e.target;
  setTypeofLogin(value)
}
const handleValidSubmit=(e,value)=>{
  if(`${randomnumber}` == `${value.capthca}`){
    setLoginLoading(true)
  getUserRegister(value,typofLogin).then(result=>{
   
    if(result.success){
      localStorage.setItem('company',JSON.stringify(result.data))
      localStorage.setItem('menu',JSON.stringify(result.modulelist))
      localStorage.setItem('typeofuser',JSON.stringify(result.typeofuser))
      localStorage.setItem('user',JSON.stringify(result.data))
      Cookies.set('employee',result.token)
      if(result.modulelist.length !== 0 ){
        
        window.location.href=`${process.env.PUBLIC_URL}/${result.modulelist[0].value}`
      }else{
        window.location.href=`/dashboard`
      }
    }else{
      setLoginLoading(false)
      setMessage(result.message)
    }
   
  })
}else{
  setError('Wrong Captcha')
}
}
console.log(titleData)
  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | {titleData.name}</title>
        <link rel="manifest" href={`${titleData.favicon !== undefined && titleData.favicon !== null ?  Object.keys(titleData).length !== 0? `${url.commonUrls}/image/${titleData.imagetype}/${titleData.favicon}`:`${process.env.PUBLIC_URL}/favicon.ico`:`${process.env.PUBLIC_URL}/favicon.ico`}`}/>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back  !</h5>
                        <p>Login {titleData.name}.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid"/>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={`${titleData.log !== undefined && titleData.log !== null ?`${url.commonUrls}/image/${userData.logo}/${titleData.logo}`:`${process.env.PUBLIC_URL}/favicon.png`}`}
                            alt=""
                            className="rounded-circle"
                            //height="40"
                            style={{height:'100%',width:'100%'}} 
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                  {
                    isLoading ? <UiLoading />
                    : 
                    <>
                      <p style={{textAlign:'center',color:'red'}}>{message}</p>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleValidSubmit}
                    >
                      {error !== '' ? <Alert color="danger">{error}</Alert>  :''} 
                      {/* <Row>
                    
                      <Col sm={2}></Col>       

             <Col sm={4}>  <input
                                className="form-check-input"
                                type="radio"
                                name='typeoflogin'
                                value='super'
                                checked={typofLogin === 'super' ? true :false}
                                onClick={changeAdmin}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleRadios1"
                              >
                              <b>Super Admin</b> 
                              </label></Col>           
                      
             <Col sm={4}>  <input
                                className="form-check-input"
                                type="radio"
                                name='typeoflogin'
                                value='employee'
                                checked={typofLogin === 'employee' ? true :false}
                                onClick={changeAdmin}
                              
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleRadios1"
                              >
                                <b>Employee</b> 
                              </label></Col> 
      
                             
                       
                        </Row> */}
                     
                   
                      <div className="mb-3">
                        <AvField
                          name="userid"
                          label="User ID"
                          value=""
                          className="form-control"
                          placeholder="User ID"
                          type="text"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value=""
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="capthcacode"
                          label="Capthca Code"
                          className="form-control"
                          placeholder="Enter Email Id / User Id"
                          type="text"
                          value={randomnumber}
                          disabled={true}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                            name="capthca"
                            label="Type Capthca"
                            className="form-control"
                            placeholder="Type Capthca"
                            type="text"
                            value=''
                           
                        />
                      </div>
                      <div className="mt-3 d-grid">
                        {
                          loginLoding?
                          <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={true}
                        >
                          Validation...
                        </button>
                          :
                          <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>

                        }
                      
                      </div>

                      

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                    </>
                  }
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
                <p>
                  © {new Date().getFullYear()} Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by The BigChimp Company
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
