import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"


import { getpageStructureLinkStatus, getpageStructurLink, postPageStructureLink } from "data/pageStructureLink"
import InputFormElement from "UI/InputFormElement"
import { getpageStructure } from "data/pageStructure"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { getEmployee } from "data/systemadmin/employee"
import { isEmpty } from "data/middlefunctions"
import UiLoading from "UI/loading"

const PageGallery = () => {
  const [allPageLoading, setAllPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  const [addButton, setAddButton] = useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({ pagedescription: '', status: true })
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [pagedescriptionid, setPageDescriptionId] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [cardValue, setCardValue] = useState({})
  const [inputType, setInputType] = useState([
    { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
    { title: `pagedescriptionid`, name: 'pagedescriptionid', type: 'hidden', value: 'pagedescriptionid', disabled: '', },
    { title: `New's Category`, name: 'pagedescription', type: 'text', value: '', disabled: true, gride: 12 },
    { title: 'Content Title', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 12,disabled:true },
    { title: 'Main Gallery', name: 'gallerymain', type: 'file', value: [], label: 'Main Gallery', disabled: '', required: true, gride: 12  },
    { title: 'Gallery Image', name: 'gallery', type: 'file',value: [], label: 'Gallery Image',multiple:true, disabled: '', required: true, gride: 12  },
  
  ])

  const [cardElement, setCardElement] = useState([])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    await getpageStructure('Different Page Design', 'All').then(async result => {
      const d = []
      if (result.success) {
        for (let i = 0; i < result.data.length; i++) {
          if(result.data[i].dynamics == true){
            console.log(d.length)
            await getpageStructureLinkStatus(result.data[i]._id, 'All').then(results => {
              d.push({
                _id: result.data[i]._id,
                name: result.data[i].name,
                title: result.data[i].name,
                total: results.total,
                active: results.active,
                inactive: results.inactive,
                getStatusData: getpageStructurLink,
                getData: getpageStructurLink,
                getPostData: postPageStructureLink,
                statusList: 'All',
                HeaderColumns: [
                  {
                    dataField: "_id",
                    text: "SL. No",
                    sort: true,
                    // eslint-disable-next-line react/display-name
                    formatter: (cell, prod, index) => index + 1,
                  },
                  {
                    dataField: "name",
                    text: "Content Title",
                    sort: true,
  
                  },
                  {
                    dataField: "",
                    text: "Status",
                    sort: true,
                    formatter: (cell, prod, index) =>
                      prod.status == true ? <p>ON</p> : <p>OFF</p>,
                  },
                  {
                    dataField: "action",
                    isDummyField: true,
                    text: "Action",
                    // eslint-disable-next-line react/display-name
                    formatter: (cell, prod, index) => (
                      <>
                        <div className="d-flex gap-3">
                          <Link to="#" className="text-success">
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                              onClick={e => changeTable(e, "Edit", parseInt(d.length-1), prod, result.data[parseInt(d.length-1)]._id)}
                            />
                          </Link>
                         
                          <Link to="#" className="text-danger">
                            <i
                              className="mdi mdi-eye font-size-18"
                              id="deletetooltip"
                              onClick={e => changeTable(e, "View", parseInt(d.length-1), prod, result.data[parseInt(d.length-1)]._id)}
                            />
                          </Link>
                        </div>
                      </>
                    ),
                  },
  
  
                ],
  
                isLoading: false,
                addButton: false,
              })
            })
          }

        }
        setCardElement(d)
        setAllPageLoading(false)
      }
    })
    console.log(cardElementStatus)

    setIsLoading(false)

  }, [])
  const changeTable = async (e, type, index, prod, pagedescriptionid) => {
    console.log(prod)
    setPageDescriptionId(pagedescriptionid)
    e.preventDefault()
    setCardValue(prod)

    setIndexValue(index)
    setAllPageLoading(true)
    const cardElementList = [...cardElement]
    const inputLists = [...inputType]
    if (cardElementList.length !== 0) {
      setAddButton(cardElementList[index].addButton)
      setTitle(cardElementList[index].name)
    } else {
      setAddButton(false)
      setTitle(prod.name)
    }

    if (type == 'Table' || type == true || type == false) {
      setColumns(cardElementList[index].HeaderColumns)
      setOperationTable('Table')

      await cardElement[index].getData(cardElementList[index]._id, type == 'Table' ? "All" : type).then(result => {

        if (result.success) {
          setData(result.data)
          setAllPageLoading(false)
        } else {
          setData([])
          setAllPageLoading(false)
        }
      })

    } else if (type == 'Add') {

      if (prod.name !== undefined) {
        setTitle(prod.name)
      } else {
        setTitle(cardElementList[index].title)
      }



      for (let i = 0; i < inputLists.length; i++) {
        if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
          inputLists[i].value = []
          await inputLists[i].outputData(inputLists[i].table, inputLists[i].status).then(results => {
            for (let k = 0; k < results.data.length; k++) {

              let data = {
                label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                value: results.data[k]._id,
              }
              inputLists[i].value.push(data)
            }
          })
        }
      }


      console.log(singleItem)
      setSingleItem({ status: true, pagedescription: prod.pagedescription, pagedescriptionid: pagedescriptionid })

      setInputType([...inputLists])
      setOperationTable('Add')
      setAllPageLoading(false)
      // setIsLoading(false)
    } else {

      setInputType([...inputLists])
      if (type == 'Edit' || type == 'View' || type == 'Deleted') {

        await getpageStructurLink(prod.name, prod._id).then(async results => {
      setTitle(results.data.pagedescriptionid.name)
          setSingleItem({ pagedescription: results.data.pagedescriptionid.name, ...results.data })
          for (let i = 0; i < inputLists.length; i++) {

            if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
              inputLists[i].value = []
              await inputLists[i].outputData(inputLists[i].table, inputLists[i].status).then(results => {
                for (let k = 0; k < results.data.length; k++) {

                  let data = {
                    label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                    value: results.data[k]._id,
                  }
                  inputLists[i].value.push(data)
                }
              })
            }
          }

          setInputType([...inputLists])
          setOperationTable(type)
          setAllPageLoading(false)
        })
      }

    }
  }


  const postData = (e, values) => {
    console.log(indexValue)
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    if (operationsTable == 'Edit') {
      delete values['pagedescriptionid'];
    }
    if (values.name !== undefined) {
      values['slug'] = values.name.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.name;
    }
    if (values.title !== undefined) {
      values['slug'] = values.title.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.title;
    }
    setAllPageLoading(true)
    postPageStructureLink(isEmpty(values), cardElementList[indexValue].name, operationsTable).then(result => {
      if (result.success) {
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        getpageStructurLink(cardElementList[indexValue]._id, 'All').then(result => {
          if (result.success) {
            setData(result.data)
            setAllPageLoading(false)
          } else {
            setData([])
            setAllPageLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  console.log(singleItem)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Page Manager | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
              <Breadcrumbs
                title={"Dynamic Page Dashboard"}
                breadcrumbItem={"Page Manager"}
                bredcumlink={"dynamic-page-structure"}
              />
              : <Breadcrumbs
                title={'Page Manager'}
                breadcrumbItem={title}
                bredcumlink={'page-structure-link'}
              />
          }

          <Row>
            {


              allPageLoading ?
                <UiLoading cardElementOption={!allPageLoading} />
                :
                operationsTable == 'Card' ?
                  cardElement.map((d, index) => (
                    <Col sm={4} md={3} key={index}>

                      <GalleryCardElementwithEvent
                        data={d}
                        changeTable={(e, type) => changeTable(e, type, index, { pagedescription: d.name }, d._id)}
                        addButton={d.addButton}
                        isLoading={isLoading}
                      />
                    </Col>
                  ))
                  :
                  operationsTable == 'Table' ?
                    <Card>
                      <ReactTable
                        title={title}
                        datas={data}
                        changeTable={(e, type) => changeTable(e, type, indexValue, { pagedescription: title }, pagedescriptionid)}
                        columns={columns}
                        addButton={addButton}

                      />




                    </Card>
                    :


                    <InputFormElement
                      operationsTable={operationsTable}
                      singleItem={singleItem}

                      bredcumlink={'InformationPage'}
                      viewIcon={false}
                      filetype='single'

                      title={title}
                      changeTable={(e, type) => changeTable(e, type, indexValue, { pagedescription: title }, pagedescriptionid)}
                      inputType={inputType}
                      postData={postData}
                      isLoading={isLoading}
                    />


            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default PageGallery
