import React, { useState } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Card, CardBody, Row, Col, CardTitle, Button, Table } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import ImageList from "UI/Image"
import TextEditor from "UI/TextEditor"
import SingleDropDown from "UI/SingleDropDown"
import MultipleDropDown from "UI/MultipledropDown"
import { postUploadFile,deleteUploadFile } from "data/fileuplaod"
import UiLoading from "UI/loading"

const InputFormElement = ({
  isLoading,
  postData,
  inputType,
  title,
  singleItem,
  changeTable,
  operationsTable,
  errorMessage,
  detedPurchaseProduct,
  saveData
}) => {
  console.log(inputType)
  const [changeDropDown,setChangeDropDown] = useState(false)
  const [singleItems, setSingleItems] = useState(singleItem)
  const [inputTypes, setInputTypes] = useState(inputType)
  const [imageLoading,setImageLoading] = useState(false)
  const  [imageMeassageloading,setImageLoadingMessage] = useState('')
  const [description, setDescription] = useState(
    singleItem.description !== undefined ? singleItem.description : ""
  )
  
  const selectValidation = (e, d) => {
    setChangeDropDown(true)
    const single ={...singleItems}
    console.log(e)
    single[d.name] = e.value
    const inputTypesList =[...inputTypes]
    let valueData =[]

    for(let jk=0; jk < inputTypesList.length;jk++){
      if(inputTypesList[jk].dependacy && inputTypesList[jk].dependacy != undefined && inputTypesList[jk].dependacy != null ){
        if(inputTypesList[jk].dependacyList.some(dl=> dl == d.name)){
            for(let il=0; il <inputTypesList[jk].dependancyValue.length;il++){
             if(inputTypesList[jk].dependancyValue[il][d.name].some(d=> d == `${e.value}`) ){
               valueData.push(inputTypesList[jk].dependancyValue[il])
               }
            }
            if(inputTypesList[jk].parentList == d.name){
            for(let kl=0; kl <inputTypesList[jk].childDependancy.length;kl++){
                delete single[inputTypesList[jk].childDependancy[kl]]
              }
            }
            inputTypesList[jk].value=valueData
          }
      } 
    }
    setInputTypes([...inputTypesList])
    console.log(single)
    setSingleItems({ ...single })
  }

  const onEditorChange = (e, d) => {
    setSingleItems({ ...singleItems })
    singleItems[d] = e.editor.getData()
    setSingleItems({ ...singleItems })
  }
  const onChangeRadio = e => {
    const { name, value } = e.target

    if (value === "false" || value === false) {
      singleItems[name] = false
      setSingleItems({ ...singleItems })
    } else if (value === "true" || value === true) {
      singleItems[name] = true
      setSingleItems({ ...singleItems })
    } else  {
      singleItems[name] = value
      setSingleItems({ ...singleItems })
    }
  }
  const selctdropDownList = (e,select, d) => {
    console.log(e,d)
    const single ={...singleItems}
    if(select !== undefined){
      let difference = select.filter(x => !e.includes(x));
      console.log('Removed: ', difference); 
  
      if(detedPurchaseProduct !== undefined){
        detedPurchaseProduct(difference)
      }
    }
    const inputTypesList =[...inputTypes]
    const ds= []
    for (let i = 0; i < e.length; i++) {
      ds.push(e[i].value)
    }
    
    let valueData =[]
    for(let jk=0; jk < inputTypesList.length;jk++){
      if(inputTypesList[jk].dependacy && inputTypesList[jk].dependacy != undefined && inputTypesList[jk].dependacy != null ){
        if(inputTypesList[jk].dependacyList.some(dl=> dl == d)){
          for(let il=0; il <inputTypesList[jk].value.length;il++){
             if(JSON.stringify(ds) == JSON.stringify(inputTypesList[jk].value[il][d])){
                 valueData.push(inputTypesList[jk].value[il])
               }
            }
            inputTypesList[jk].value=valueData
          }
      } 
    }
    
   setInputTypes([...inputTypesList])
   single[d] = ds
   console.log(single)
    setSingleItems({ ...single })
  }
  const uploadImage = async (e, name, type) => {
    console.log(name)
    const single ={...singleItems}
    console.log(single)
    setImageLoading(true)
    setImageLoadingMessage('Image Loading...')
    if(singleItems[name] !== undefined){
      const photoData = [...singleItems[name]]
      
      for (let i = 0; i < e.target.files.length; i++) {
        await postUploadFile(e.target.files[i], type,single[name]).then(result => {
          if (result.success) {
            photoData.push(result.photo)
            setImageLoading(false)
            setImageLoadingMessage('Image Loading Successfully')
          } else {
            singleItems[name] = ""
            setSingleItems({ ...singleItems })
            setImageLoading(false)
            setImageLoadingMessage('Image Loading Error')
          }
        })
      }
      singleItems[name] = photoData
  
      setSingleItems({ ...singleItems })
    }else{
      const photoData = []
      
      for (let i = 0; i < e.target.files.length; i++) {
        await postUploadFile(e.target.files[i], type).then(result => {
          if (result.success) {
           
            photoData.push(result.photo)
          
          } else {
            singleItems[name] = ""
            setSingleItems({ ...singleItems })
          }
        })
      }
      singleItems[name] = photoData
      setSingleItems({ ...singleItems })
      setImageLoading(false)
    }
   
  }
  const deleteUploadFiles = (e, ind, d,uploadFileType,index) => {

    const inputTypeData = [...inputTypes]
   let  photoData = [...singleItems[inputTypeData[index].name]];
    const filteD  = photoData.filter((d,i)=> i !==ind);
    singleItems[inputTypeData[index].name] =filteD;
    setSingleItems({ ...singleItems })
   deleteUploadFile(photoData[ind],uploadFileType).then(result=>{
    console.log(result)
      if(result.success){
        // const filteD  = photoData.filter((d,i)=> i !==ind);
        // singleItems[type] =filteD;
        setSingleItems({ ...singleItems })
      }else{

      }
    })
  }
 
  const deletedAreaPincode=(area)=>{
    const pincodes =[...singleItems.pincode]
    const findInde = pincodes.findIndex(d=> d.area == area)
    const filterData = pincodes.filter((_,ik)=> ik !== findInde )
    singleItems.pincode =filterData
    setSingleItems({ ...singleItems })
  }
  const changeArea=(e,d,i)=>{
   const {name,value}= e.target;
    const pincodes =[...singleItems.pincode]
    pincodes[i].area=value
    singleItems.pincode =pincodes
    setSingleItems({ ...singleItems })
  }
  const changePincode=(e,d,i,picodeList)=>{
    const {name,value} =e.target;
    const pincodes =[...singleItems.pincode]
    pincodes[i].pincode=value.split(',')
    singleItems.pincode =pincodes
    setSingleItems({ ...singleItems })
    
  }
  const changeVarient=(e,i)=>{
    const {name,value} =e.target;
    const varientlist =[...singleItems.varientlist]
    console.log(varientlist)
    varientlist[i].name=value
    singleItems.varientlist =varientlist;
    setSingleItems({ ...singleItems })
  }
 const deletedVarient=(name)=>{
  const varientlist =[...singleItems.varientlist]
  const findInde = varientlist.findIndex(d=> d.name == name)
  const filterData = varientlist.filter((_,ik)=> ik !== findInde )
  singleItems.varientlist =filterData
  setSingleItems({ ...singleItems })
  }
  console.log(singleItems)
  const generateTable=(typeData)=>{
   
    const inputTypesList =[...inputTypes]

    const single ={...singleItems}
    console.log(single)
    let dkl=[]
    let d={}
        for(let kc=0 ; kc < single['category'].length;kc++){
                      for(let kt=0 ; kt < single['customertype'].length;kt++){
                        for(let kr=0 ; kr < single['region'].length;kr++){
                         
                          for(let kb=0 ; kb < single['billingtype'].length;kb++){
                            for(let km=0 ; km < single['billingmode'].length;km++){
                              const dls={
                                category:single['category'][kc],
                                customertype:single['customertype'][kt],
                                region:single['region'][kr],
                                billingtype:single['billingtype'][kb],
                                billingmode:single['billingmode'][km],
                                discount:'',
                                action:'Yes',
                              }
                            dkl.push(dls)
                      }
                    }
                      }
                      }
                
                    
                  
                }
          
         
                single['discount']=dkl
                inputTypesList.find(d=> d.type == 'dynamicdata').value=dkl
          console.log(single)
        setInputTypes([...inputTypesList])
        setSingleItems({...single})
  }
  const updateDiscountValue=(event,i)=>{
    const {name,value} = event.target;
    const single ={...singleItems}
    console.log(single['discount'][i])
    single['discount'][i]['discount']= value
    setSingleItems({...single})
  }
  const onChangeInput=(e,type,i)=>{
    const {name,value} = e.target;
    const single ={...singleItems}
    single[type][i][name] = value
    setSingleItems({...single})
  }

  const deletedDataTable = (e,type,i) => {
    const single ={...singleItems}
   
  const d=  single[type].filter((d,ik)=> ik != i);
   
    single[type] =d
    setSingleItems({...single})
  }
  const addRowData=(e,i,type,header)=>{
    const single ={...singleItems}
     if(single[type] ==undefined && single[type] == null){
      single[type]=[]
    }

    if(single[type].length != 0){

      if(single[type][single[type].length -1]['title'] != ''){
       const d= {}
        for(let jk=0; jk < Object.keys(header).length;jk++){
        d[Object.keys(header)[jk]]=''
        }
       
        single[type].push(d)
      }else{
        alert("Column Should Not Blank")
      }
    }else{
      single[type].push(header)
    }
   setSingleItems({...single})
  }
  const onChangeSelectInput=(e,type,v,i)=>{
    const single ={...singleItems}
    single[type][i][v]=e.value
  
    setSingleItems(single)
  }
  return (
    <Card>
      <CardBody>
    
        <>
        <div className="row">
          <div className="col-md-11">
            <CardTitle className="mb-4">
              {operationsTable} {title}
            </CardTitle>
          </div>
          <div className="col-md-1">
            <Button
              color="success"
              onClick={e => changeTable(e, "Table", title)}
            >
              Back
            </Button>
          </div>
        </div>
        <p style={{color:'red',textAlign:'center'}}>{errorMessage}</p>
        <AvForm onValidSubmit={(e,value)=>postData(e,value,singleItems)}>
          <div className="row">
            {inputTypes.map((d, index) => (
              <>
                {d.type === "radio" ? (
                  <>
                    <AvField
                      type="hidden"
                      name={d.name}
                      className="form-control"
                      value={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ] == false
                          ? "false"
                          : singleItems[
                              Object.keys(singleItems).filter(k => k == d.name)
                            ]
                      }
                      disabled={
                        operationsTable == "View" || operationsTable == "Delete"
                          ? true
                          : false
                      }
                    />
                    <Row>
                      <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                      <div className="mb-3"></div>
                        <CardTitle className="mb-4">{d.title}</CardTitle>

                        <Row>
                          {d.statustype.map((s, index) => (
                            <Col
                              sm={`${s.gride !== undefined ? s.gride : 3}`}
                              key={index}
                            >
                              <div className="mb-3"></div>
                              <input
                                className="form-check-input"
                                type="radio"
                                name={`${s.name}`}
                                value={s.value}
                                checked={
                                  `${
                                    singleItems[
                                      Object.keys(singleItems).filter(
                                        k => k == s.name
                                      )
                                    ]
                                  }` == `${s.value}`
                                    ? true
                                    : false
                                }
                                onClick={e => onChangeRadio(e, d.name)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleRadios1"
                              >
                                {s.title}
                              </label>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : d.type == "file" ? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    {/* <div className="mb-3"></div> */}
                    <AvField
                      type="hidden"
                      name={`${d.name}`}
                      value={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ] !== undefined
                          ? singleItems[
                              Object.keys(singleItems).filter(k => k == d.name)
                            ]
                          : ""
                      }
                      disabled={
                        operationsTable == "View" || operationsTable == "Delete"
                          ? true
                          : false
                      }
                    />
                    <ImageList
                      numberfile={d.multiple}
                      uploadImages={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ] !== undefined
                          ? singleItems[
                              Object.keys(singleItems).filter(k => k == d.name)
                            ]
                          : d.value
                      }
                      uploadtype={d.uploadtype}

                      uploadImage={e => uploadImage(e, d.name, d.uploadtype)}
                      deleteUploadFiles={(e, ind, d,uploadtype) => deleteUploadFiles(e, ind, d,uploadtype,index)}
                      // title={`${d.file !== undefined ? d.file : d.label}`}
                      label={`${d.label !== undefined ? d.label : d.file}`}
                      imageaccept={`${d.imageaccept}`}
                      typeOfImage={d.name}
                      imageLoading={imageLoading}
                      imageMeassageloading={imageMeassageloading}
                    />
                  </Col>
                ) : d.type == "description" ? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    <div className="mb-3"></div>
                    <AvField
                      type="hidden"
                      name={`${d.name}`}
                      value={ singleItems[
                        Object.keys(singleItems).filter(k => k == d.name)
                      ] !== undefined
                        ? singleItems[
                            Object.keys(singleItems).filter(k => k == d.name)
                          ]
                        : d.value}
                      disabled={
                        operationsTable == "View" || operationsTable == "Delete"
                          ? true
                          : false
                      }
                    />
                    <label>{d.title}</label>
                    <TextEditor
                      title={`${d.title}`}
                      description={ singleItems[
                        Object.keys(singleItems).filter(k => k == d.name)
                      ] !== undefined
                        ? singleItems[
                            Object.keys(singleItems).filter(k => k == d.name)
                          ]
                        : d.value}
                      onEditorChange={e => onEditorChange(e, d.name)}
                      disabled={
                        operationsTable == "View" || operationsTable == "Delete"
                          ? true
                          : false
                      }
                    />
                  </Col>
                ) : d.type == "select" || d.type == "consType" ? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    <div className="mb-3"></div>
                    <AvField
                      type="hidden"
                      name={`${d.name}`}
                       value={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ] !== undefined
                          ? singleItems[
                              Object.keys(singleItems).filter(k => k == d.name)
                            ]
                          : []
                      }
                    />
                    <SingleDropDown
                      title={`${d.title}`}
                      dropDownList={d.value}
                      selectDrop={
                        singleItems[d.name]
                        
                      }
                      selctdropDownList={e => selectValidation(e, d)}
                      disabled={
                      d.disabled
                          ? true
                          : false
                      }
                    />
                  </Col>
                ) : d.type == "multiple" || d.type == "multiConsType"? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    <div className="mb-3"></div>
                  
                    <AvField
                      type="hidden"
                      name={`${d.name}`}
                      value={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ] !== undefined
                          ? singleItems[
                              Object.keys(singleItems).filter(k => k == d.name)
                            ]
                          : []
                      }
                    />
                    <MultipleDropDown
                      title={d.title}
                      dropDownList={d.value}
                      selectDrop={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ]
                      }
                      disabled={d.disabled}
                      selctdropDownList={(e,select) => selctdropDownList(e,select, d.name)}
                    />
                  </Col>
                ) :d.type == "dependecy" ? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    <div className="mb-3"></div>
                  
                    <AvField
                      type="hidden"
                      name={`${d.name}`}
                      value={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ] !== undefined
                          ? singleItems[
                              Object.keys(singleItems).filter(k => k == d.name)
                            ]
                          : []
                      }
                    />
                    <MultipleDropDown
                      title={d.title}
                      dropDownList={d.value}
                      selectDrop={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ]
                      }
                      disabled={d.disabled}
                      selctdropDownList={(e,select) => selctdropDownList(e,select, d.name)}
                    />
                  </Col>
                ):d.type == "button" ? 
                <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                   <div>&nbsp;</div>
                    <Button color="success" onClick={()=>generateTable(d.multiplicationData)} className="mt-4" style={{width:'100%'}}>
                            {d.title}
                          </Button>
                </Col>
                :d.type == "dynamicdata" ? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    
                  
                      <AvField
                        type="hidden"
                        name={`${d.name}`}
                        value={`${
                          singleItems[
                            Object.keys(singleItems).filter(k => k == d.name)
                          ]
                        }`}
                    
                      />
                      {
                       
                          <Table className="table table-bordered mt-3 mb-0">
                            {
                               singleItems['discount'] != undefined &&  singleItems['discount'] != null &&  singleItems['discount'].length != 0 ?
                               <thead>
                               <tr>
                                 <th>Sl.</th>
                                 <th>Category</th>
                                 <th>Customer Type</th>
                                 <th>Region</th>
                                 <th>Billing Type</th>
                                 <th>Billing Mode</th>
                                 <th>Action</th>
                                 <th>Discount</th>
                               </tr>
                             </thead>
                               
                               :''
                            }
                            
                          <tbody>
                          {
                            singleItems['discount'] != undefined &&  singleItems['discount'] != null &&  singleItems['discount'].length != 0 &&  singleItems['discount'].map((dk,i)=>(
                            <tr key={i} style={{verticalAlign:"middle"}}>
                              <th scope="row">{parseInt(i+1)}</th>
                              <td>{inputType.find(d=> d.name == 'category').value.find(dkl=> dkl.value == dk.category).label}</td>
                              <td>{inputType.find(d=> d.name == 'customertype').value.find(dkl=> dkl.value == dk.customertype).label}</td>
                              <td>{inputType.find(d=> d.name == 'region').value.find(dkl=> dkl.value == dk.region).label}</td>
                              <td>{inputType.find(d=> d.name == 'billingtype').value.find(dkl=> dkl.value == dk.billingtype) != undefined && inputType.find(d=> d.name == 'billingtype').value.find(dkl=> dkl.value == dk.billingtype) != null ?  inputType.find(d=> d.name == 'billingtype').value.find(dkl=> dkl.value == dk.billingtype).label:''}</td>
                              <td>{inputType.find(d=> d.name == 'billingmode').value.find(dkl=> dkl.value == dk.billingmode) != undefined && inputType.find(d=> d.name == 'billingmode').value.find(dkl=> dkl.value == dk.billingmode) != null ?  inputType.find(d=> d.name == 'billingmode').value.find(dkl=> dkl.value == dk.billingmode).label:''}</td>
                              <td>
                                <select className="form-control" name="action" value={dk.action != undefined && dk.action != null?dk.action:''} type="text" onChange={(event)=>updateDiscountValue(event,i)}>
                                <option value="">Default</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                               </select>
                              </td>
                              <td>
                                <input className="form-control" value={dk.discount} type="text" onChange={(event)=>updateDiscountValue(event,i)}/>
                              </td>
                            </tr>
                            ))}
                          </tbody>
                        </Table>
                        
                      }
                     
                  
                  </Col>
                ): d.type == 'addmore'?
                <>
                <Row>
                  <Col sm="11">
                  <p>{d.title}</p>
                  </Col>
                  <Col sm="1">
                  <Button
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"
                          onClick={(e)=>addRowData(e,'Add',d.name,d.headerValue)}
                         >
                           <i className="mdi mdi-plus me-1" />
                           
                         </Button>
                  </Col>
                </Row>
                <Table className="table table-bordered mb-0">
                  {
                     singleItems[`${d.name}`] != undefined && singleItems[`${d.name}`] != null && singleItems[`${d.name}`].length != 0 ?
                     <thead>
                     <tr>
                       {
                        d.headertitle != undefined && d.headertitle != null && d.headertitle.map((d, i) => (
                           <th key={i}>{d}</th>
                         ))
                       }
                      </tr>
                  </thead>
                    :''
                  }
                 
                 <tbody>
                      {
                       singleItems[`${d.name}`] != undefined && singleItems[`${d.name}`] != null && singleItems[`${d.name}`].map((dk,i)=>(
                          <tr key={i}>
                    <td>
                    {i + 1}
                    </td>
                    <td>

                          <input
                          className="form-control"
                          type='text'
                          name='title'
                          value={ singleItems[`${d.name}`].length != 0 ? dk['title']:''}
                          onChange={(e)=>onChangeInput(e,d.name,i)}
                         />
                    </td>
                    <td>

                          <input
                          className="form-control"
                          type='text'
                          name='url'
                          value={singleItems[`${d.name}`].length != 0 ? dk['url']:''}
                          onChange={(e)=>onChangeInput(e,d.name,i)}
                         />
                    </td>
                    <td>
                      
                        <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={(e) => deletedDataTable(e,d.name, i)} />
                      </td>
                         </tr>
                        ))
                      }
                 </tbody>
                </Table>
                </>
                :  d.type == 'selectaddmore'?
                <>
                <Row>
                  <Col sm="11">
                  <p>{d.title}</p>
                  </Col>
                  <Col sm="1">
                  <Button
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"
                          onClick={(e)=>addRowData(e,'Add',d.name,d.headerValue)}
                         >
                           <i className="mdi mdi-plus me-1" />
                           
                         </Button>
                  </Col>
                </Row>
                <Table className="table table-bordered mb-0">
                  {
                     singleItems[`${d.name}`] != undefined && singleItems[`${d.name}`] != null && singleItems[`${d.name}`].length != 0 ?
                     <thead>
                     <tr>
                       {
                        d.headertitle != undefined && d.headertitle != null && d.headertitle.map((d, i) => (
                           <th key={i}>{d}</th>
                         ))
                       }
                      </tr>
                  </thead>
                    :''
                  }
                 
                 <tbody>
                      {
                       singleItems[`${d.name}`] != undefined && singleItems[`${d.name}`] != null && singleItems[`${d.name}`].map((dk,i)=>(
                          <tr key={i}>
                    <td>
                    {i + 1}
                    </td>
                    <td>
                    <SingleDropDown
                      title={`${d.title}`}
                      dropDownList={d.value}
                      selectDrop={
                        dk.title
                        
                      }
                      selctdropDownList={e => onChangeSelectInput(e, d.name,'title',i)}
                     
                    />
                    </td>
                   
                    <td>
                      
                        <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={(e) => deletedDataTable(e,d.name, i)} />
                      </td>
                         </tr>
                        ))
                      }
                 </tbody>
                </Table>
                </>
                :d.type == "hiddens" ? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    
                    {singleItem[
                      Object.keys(singleItem).filter(k => k == d.name)
                    ] !== undefined ? (
                      <AvField
                        type="hidden"
                        name={`${d.name}`}
                        value={`${
                          singleItem[
                            Object.keys(singleItem).filter(k => k == d.name)
                          ]
                        }`}
                    
                      />
                    ) : d.value !== "" ? (
                      <AvField
                        type={`${d.type}`}
                        name={`${d.name}`}
                        value={`${d.value}`}
                        placeholder={d.placeholder != undefined && d.placeholder != null ? d.placeholder :''}
                        disabled={
                          operationsTable == "View" ||
                          operationsTable == "Delete"
                            ? true
                            : false
                        }
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                ):d.type == "hidden" ? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    
                    {singleItem[
                      Object.keys(singleItem).filter(k => k == d.value)
                    ] !== undefined ? (
                      <AvField
                        type={`${d.type}`}
                        name={`${d.name}`}
                        value={`${
                          singleItem[
                            Object.keys(singleItem).filter(k => k == d.value)
                          ]
                        }`}
                        disabled={
                          operationsTable == "View" ||
                          operationsTable == "Delete"
                            ? true
                            : false
                        }
                      />
                    ) : d.value !== "" ? (
                      <AvField
                        type={`${d.type}`}
                        name={`${d.name}`}
                        value={`${d.value}`}
                        disabled={
                          operationsTable == "View" ||
                          operationsTable == "Delete"
                            ? true
                            : false
                        }
                      />
                    ) : d.value == "" ? (
                      <AvField
                        type={`${d.type}`}
                        name={`${d.name}`}
                        value={`${d.title}`}
                        placeholder={d.placeholder != undefined && d.placeholder != null ? d.placeholder :''}
                        disabled={
                          operationsTable == "View" ||
                          operationsTable == "Delete"
                            ? true
                            : false
                        }
                      />
                    ) : (
                      "")}
                  </Col>
                ) :  d.type == "inlineedi"?
               
                
                singleItems[d.name]!== undefined !== undefined &&  singleItems[d.name] !== null?
                      
                        d.name == 'varientlist' ?
                        singleItems[d.name].map((d,i)=>(
                          < >
                            <Col sm={2} lg={2} key={i}>
                              <AvField
                              name={`varient`}
                              value={d.name}
                              label={'Pincode'}
                              className="form-control"
                              onChange={(e)=>changeVarient(e,i)}
                              />
                            </Col>
                            
                            <Col sm={1} lg={1} >
                            <div className="mb-3"></div>
                            <div className="mb-3"></div>
                            <div className="mb-3"></div>
                            <Link to="#" className="text-danger">
                    <i
                        className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                      onClick={(e)=>deletedVarient(d.name)}
                    />
                  </Link>
  
                            </Col>
                      
                          </>
                        ))
                        :
                      
                      singleItems[d.name].map((d,i)=>(
                        <Row key={i}>
                          <Col sm={2} lg={2} >
                            <AvField
                            name={`area`}
                            value={d.area}
                            label={'Pincode'}
                            className="form-control"
                            onChange={(e)=>changeArea(e,d,i)}
                            />
                          </Col>
                          <Col sm={8} lg={8} >
                            <AvField
                            label="Pincode"
                            type="textarea"
                            name={`area`}
                            value={d.pincode.toString()}
                            className="form-control"
                            onChange={(e)=>changePincode(e,d,i,d.pincode.toString())}
                            />
                          </Col>
                          <Col sm={1} lg={1} >
                          <div className="mb-3"></div>
                          <div className="mb-3"></div>
                          <div className="mb-3"></div>
                          <Link to="#" className="text-danger">
                  <i
                      className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={(e)=>deletedAreaPincode(d.area)}
                  />
                </Link>

                          </Col>
                    
                        </Row>
                      ))
                      :''
                
                :d.type == "slug" ? (
                  
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    <div className="mb-3"></div>
                  <AvField
                    type='text'
                    name='slug'
                    className="form-control"
                    id="taxtype-Input"
                   
                    value={`${slug}`}
                    placeholder={d.placeholder != undefined && d.placeholder != null ? d.placeholder :''}
                    label={
                      <>
                        {title} {d.title}{" "}
                        {d.required != "" ? (
                          <span style={{ color: "red" }}>*</span>
                        ) : (
                          ""
                        )}{" "}
                      </>
                    }
                    errorMessage="This is required"
                    disabled={
                      operationsTable == "View" || operationsTable == "Delete"
                        ? true
                        : false
                    }
                  />
                </Col>
                
                ):
                (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    <div className="mb-3"></div>
                    <AvField
                      type={`${d.type}`}
                      name={`${d.name}`}
                      className="form-control"
                      id="taxtype-Input"
                      // disabled={disableddata}
                      placeholder={d.placeholder != undefined && d.placeholder != null ? d.placeholder :''}
                      value={`${
                        d.type === "date"
                          ? singleItem[
                              Object.keys(singleItem).filter(k => k == d.name)
                            ] !== undefined
                            ? singleItem[
                                Object.keys(singleItem).filter(k => k == d.name)
                              ].split("T")[0]
                            : ""
                          : singleItem[
                              Object.keys(singleItem).filter(k => k == d.name)
                            ] !== undefined
                          ? singleItem[
                              Object.keys(singleItem).filter(k => k == d.name)
                            ]
                          : ""
                      }`}
                      required={`${d.required}`}
                      label={
                        <>
                          {d.title}{" "}
                          {d.required != "" ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : (
                            ""
                          )}{" "}
                        </>
                      }
                      errorMessage="This is required"
                      disabled={
                        operationsTable == "View" || operationsTable == "Delete"  || d.disabled 
                          ? true
                          : false
                      }
                    
                    />
                  </Col>
                )}
              </>
            ))}
          </div>

          <Row>
            <div className="mb-3"></div>
            <div className="mb-3"></div>
            <div className="mb-3"></div>
            <div className="mb-3"></div>
            <div className="mb-3"></div>
            <div className="mb-3"></div>
            <div className="mb-3"></div>
            <div className="mb-3"></div>

            <Col lg={2}></Col>
            <Col lg={10}>
              <Row>
                <Col lg={11}></Col>

                <Col lg={1}>
                  <div className="mb-3">
                    {
                      saveData && saveData != undefined && saveData != null?
                      <Button
                      type="submit"
                      color="success"
                      disabled={
                      true
                      }

                    >
                      {operationsTable} Loding ... 
                    </Button>
                      :<Button
                      type="submit"
                      color="success"
                      disabled={
                        operationsTable == "View" || operationsTable == "Delete"
                          ? true
                          : false
                      }
                    >
                      {operationsTable}
                    </Button>
                    }
                    
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </AvForm>
        </>
      
      </CardBody>
    </Card>
  )
}
InputFormElement.propTypes = {
  saveData: PropTypes.bool,
  isLoading: PropTypes.bool,
  inputType: PropTypes.array,
  singleItem: PropTypes.object,
  changeTable: PropTypes.func,
  title: PropTypes.string,
  operationsTable: PropTypes.string,
  postData: PropTypes.func,
  errorMessage:PropTypes.string,
  detedPurchaseProduct:PropTypes.func
}
export default InputFormElement
