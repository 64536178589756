export const generate_random_string = (string_length) => {
    let random_string = '';
    let random_ascii;
    for (let i = 0; i < string_length; i++) {
      random_ascii = Math.floor(Math.random() * 25 + 97);
      random_string += String.fromCharCode(random_ascii);
    }
    return random_string;
  };
  export const generate_random_number = (n) => {
    return Math.floor(Math.random() * 10) * Date.now().toString().substr(1, n);
  };


export const isEmpty =(obj)=>{
  Object.keys(obj).forEach(key => {
    if (obj[key] === null || obj[key] === '' || obj[key] === undefined || obj[key].length == 0 ) {
      delete obj[key];
    }
  });
  return obj
}
