import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"


import {getBannerStatus, getBanner, postBanner } from "data/cms/banner"
import InputFormElement from "UI/InputFormElement"

import UiLoading from "UI/loading"
import { getpageStructure } from "data/pageStructure"
import { getpageStructurLink } from "data/pageStructureLink"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { getEvent } from "data/event"

const Banner = () => {
  const [indexValue, setIndexValue] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [operationsTable, setOperationTable] = useState('')
  const [singleItem, setSingleItem] = useState({ status: true })
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [inputType, setInputType] = useState([])
  const [addButton, setAddButton] = useState([])
  const [cardElement, setCardElement] = useState([
    {
      name: 'All Page List',
      title: 'Dynamic Pages',
       total:0,
      active:0,
      inactive:0,
      statusList:'All',
      getStatusData: getBannerStatus,
      getData: getBanner,
      getPostData:postBanner,
    
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Page Name",
          sort: true,

        },
        
        
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0,prod)}
                  />
                </Link>
               
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
       
       
      ],
      inputType: [
          { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
          { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'allpage', disabled: '' },
          { title: 'Page Name', name: 'name', type: 'text', value: '',disabled:true },
          { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'allpage', disabled: '', required: true, },
         
      ],
      isLoading: true,
      addButton:false,
  },
    {
      name: 'All Dynamic Page',
      title: `Dynamic Sub Page's`,
       total:0,
      active:0,
      inactive:0,
      statusList:'All',
      getStatusData: getBannerStatus,
      getData: getBanner,
      getPostData:postBanner,
    
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Page Name",
          sort: true,

        },
        
        
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 1,prod)}
                  />
                </Link>
              
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 1,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
       
       
      ],
      inputType: [
          { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
          { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'alldynamicpage', disabled: '' },
          { title: 'Page Name', name: 'name', type: 'text', value: '', disabled:true },
          { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'alldynamicpage', disabled: '', required: true, },
          { title: 'Gallery Image', name: 'gallery', type: 'file', value: [],label:'Gallery Image',uploadtype:'allpage', disabled: '', required: true, },
        ],
      isLoading: true,
      addButton:false,
  },
  {
    name: 'Category',
    title: `Category`,
     total:0,
    active:0,
    inactive:0,
    statusList:'All',
    getStatusData: getBannerStatus,
    getData: getBanner,
    getPostData:postBanner,
  
    HeaderColumns: [
      {
        dataField: "_id",
        text: "SL. No",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => index + 1,
      },
      {
        dataField: "name",
        text: "Page Name",
        sort: true,

      },
      
      
      {
        dataField: "",
        text: "Status",
        sort: true,
        formatter: (cell, prod, index) =>
          prod.status == true ? <p>ON</p> : <p>OFF</p>,
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => (
          <>
            <div className="d-flex gap-3">
              <Link to="#" className="text-success">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={e => changeTable(e, "Edit", 1,prod)}
                />
              </Link>
            
              <Link to="#" className="text-danger">
                <i
                  className="mdi mdi-eye font-size-18"
                  id="deletetooltip"
                  onClick={e => changeTable(e, "View", 1,prod)}
                />
              </Link>
            </div>
          </>
        ),
      },
     
     
    ],
    inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'alldynamicpage', disabled: '' },
        { title: 'Page Name', name: 'name', type: 'text', value: '', disabled:true },
        { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'alldynamicpage', disabled: '', required: true, },
        { title: 'Gallery Image', name: 'gallery', type: 'file', value: [],label:'Gallery Image',uploadtype:'allpage', disabled: '', required: true, },
      ],
    isLoading: true,
    addButton:false,
},
  {
    name: 'All Products',
    title: `All Products`,
     total:0,
    active:0,
    inactive:0,
    statusList:'All',
    getStatusData: getBannerStatus,
    getData: getBanner,
    getPostData:postBanner,
  
    HeaderColumns: [
      {
        dataField: "_id",
        text: "SL. No",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => index + 1,
      },
      {
        dataField: "name",
        text: "Page Name",
        sort: true,

      },
      
      
      {
        dataField: "",
        text: "Status",
        sort: true,
        formatter: (cell, prod, index) =>
          prod.status == true ? <p>ON</p> : <p>OFF</p>,
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => (
          <>
            <div className="d-flex gap-3">
              <Link to="#" className="text-success">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={e => changeTable(e, "Edit", 1,prod)}
                />
              </Link>
            
              <Link to="#" className="text-danger">
                <i
                  className="mdi mdi-eye font-size-18"
                  id="deletetooltip"
                  onClick={e => changeTable(e, "View", 1,prod)}
                />
              </Link>
            </div>
          </>
        ),
      },
     
     
    ],
    inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'alldynamicpage', disabled: '' },
        { title: 'Page Name', name: 'name', type: 'text', value: '', disabled:true },
        { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'alldynamicpage', disabled: '', required: true, },
        { title: 'Gallery Image', name: 'gallery', type: 'file', value: [],label:'Gallery Image',uploadtype:'allpage', disabled: '', required: true, },
      ],
    isLoading: true,
    addButton:false,
},
  {
    name: 'Dynamic Multiple',
    title: `Dynamic Multiple`,
     total:0,
    active:0,
    inactive:0,
    statusList:'All',
    getStatusData: getBannerStatus,
    getData: getBanner,
    getPostData:postBanner,
  
    HeaderColumns: [
      {
        dataField: "_id",
        text: "SL. No",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => index + 1,
      },
      {
        dataField: "name",
        text: "Page Name",
        sort: true,

      },
      
      
      {
        dataField: "",
        text: "Status",
        sort: true,
        formatter: (cell, prod, index) =>
          prod.status == true ? <p>ON</p> : <p>OFF</p>,
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => (
          <>
            <div className="d-flex gap-3">
              <Link to="#" className="text-success">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={e => changeTable(e, "Edit", 1,prod)}
                />
              </Link>
            
              <Link to="#" className="text-danger">
                <i
                  className="mdi mdi-eye font-size-18"
                  id="deletetooltip"
                  onClick={e => changeTable(e, "View", 1,prod)}
                />
              </Link>
            </div>
          </>
        ),
      },
     
     
    ],
    inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'bannertype', name: 'bannertype', type: 'hidden', value: 'dynamic', disabled: '' },
        { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'alldynamicpage', disabled: '' },
        { title: 'Page Name', name: 'multipage', type: 'select', value: [], outputData: getpageStructure, outputDropDown: 'name', table: 'Different Page Design', status: true, disabled: '', required: true},
        { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'alldynamicpage', disabled: '', required: true, },
        { title: 'Banner Url', name: 'url', type: 'text', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Gallery Image', name: 'gallery', type: 'file', value: [],label:'Gallery Image',uploadtype:'allpage', disabled: '', required: true, },
      ],
    isLoading: true,
    addButton:true,
},
{
  name: 'Dynamic Sub Multiple',
  title: `Dynamic Sub Multiple`,
   total:0,
  active:0,
  inactive:0,
  statusList:'All',
  getStatusData: getBannerStatus,
  getData: getBanner,
  getPostData:postBanner,

  HeaderColumns: [
    {
      dataField: "_id",
      text: "SL. No",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, prod, index) => index + 1,
    },
    {
      dataField: "name",
      text: "Page Name",
      sort: true,

    },
    
    
    {
      dataField: "",
      text: "Status",
      sort: true,
      formatter: (cell, prod, index) =>
        prod.status == true ? <p>ON</p> : <p>OFF</p>,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cell, prod, index) => (
        <>
          <div className="d-flex gap-3">
            <Link to="#" className="text-success">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={e => changeTable(e, "Edit", 1,prod)}
              />
            </Link>
          
            <Link to="#" className="text-danger">
              <i
                className="mdi mdi-eye font-size-18"
                id="deletetooltip"
                onClick={e => changeTable(e, "View", 1,prod)}
              />
            </Link>
          </div>
        </>
      ),
    },
   
   
  ],
  inputType: [
      { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
      { title: 'bannertype', name: 'bannertype', type: 'hidden', value: 'sub', disabled: '' },
      { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'alldynamicpage', disabled: '' },
      { title: 'Page Name', name: 'dynamicpage', type: 'select', value: [], outputData: getpageStructurLink, outputDropDown: 'name', table: 'Different Page Design', status: "Drop Down", disabled: '', required: true },
      { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'alldynamicpage', disabled: '', required: true, },
      { title: 'Banner Url', name: 'url', type: 'text', value: '', disabled: '', required: true, gride: 12 },
      { title: 'Gallery Image', name: 'gallery', type: 'file', value: [],label:'Gallery Image',uploadtype:'allpage', disabled: '', required: true, },
    ],
  isLoading: true,
  addButton:true,
},
{
  name: 'Dynamic Category Multiple',
  title: `Dynamic Category Multiple`,
   total:0,
  active:0,
  inactive:0,
  statusList:'All',
  getStatusData: getBannerStatus,
  getData: getBanner,
  getPostData:postBanner,

  HeaderColumns: [
    {
      dataField: "_id",
      text: "SL. No",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, prod, index) => index + 1,
    },
    {
      dataField: "name",
      text: "Page Name",
      sort: true,

    },
    
    
    {
      dataField: "",
      text: "Status",
      sort: true,
      formatter: (cell, prod, index) =>
        prod.status == true ? <p>ON</p> : <p>OFF</p>,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cell, prod, index) => (
        <>
          <div className="d-flex gap-3">
            <Link to="#" className="text-success">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={e => changeTable(e, "Edit", 1,prod)}
              />
            </Link>
          
            <Link to="#" className="text-danger">
              <i
                className="mdi mdi-eye font-size-18"
                id="deletetooltip"
                onClick={e => changeTable(e, "View", 1,prod)}
              />
            </Link>
          </div>
        </>
      ),
    },
   
   
  ],
  inputType: [
      { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
      { title: 'bannertype', name: 'bannertype', type: 'hidden', value: 'category', disabled: '' },
      { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'alldynamicpage', disabled: '' },
      { title: 'Page Name', name: 'category', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Category', status: true, disabled: '', required: true},
      { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'alldynamicpage', disabled: '', required: true, },
      { title: 'Banner Url', name: 'url', type: 'text', value: '', disabled: '', required: true, gride: 12 },
      { title: 'Gallery Image', name: 'gallery', type: 'file', value: [],label:'Gallery Image',uploadtype:'allpage', disabled: '', required: true, },
    ],
  isLoading: true,
  addButton:true,
},
{
  name: 'Dynamic Product Multiple',
  title: `Dynamic Product Multiple`,
  total:0,
  active:0,
  inactive:0,
  statusList:'All',
  getStatusData: getBannerStatus,
  getData: getBanner,
  getPostData:postBanner,

  HeaderColumns: [
    {
      dataField: "_id",
      text: "SL. No",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, prod, index) => index + 1,
    },
    {
      dataField: "name",
      text: "Page Name",
      sort: true,

    },
    
    
    {
      dataField: "",
      text: "Status",
      sort: true,
      formatter: (cell, prod, index) =>
        prod.status == true ? <p>ON</p> : <p>OFF</p>,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cell, prod, index) => (
        <>
          <div className="d-flex gap-3">
            <Link to="#" className="text-success">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={e => changeTable(e, "Edit", 1,prod)}
              />
            </Link>
          
            <Link to="#" className="text-danger">
              <i
                className="mdi mdi-eye font-size-18"
                id="deletetooltip"
                onClick={e => changeTable(e, "View", 1,prod)}
              />
            </Link>
          </div>
        </>
      ),
    },
   
   
  ],
  inputType: [
      { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
      { title: 'bannertype', name: 'bannertype', type: 'hidden', value: 'product', disabled: '' },
      { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'alldynamicpage', disabled: '' },
      { title: 'Page Name', name: 'product', type: 'select', value: [], outputData: getEvent, outputDropDown: 'name', table: 'Category', status: "Drop Down", disabled: '', required: true},
      { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'alldynamicpage', disabled: '', required: true, },
      { title: 'Banner Url', name: 'url', type: 'text', value: '', disabled: '', required: true, gride: 12 },
      { title: 'Gallery Image', name: 'gallery', type: 'file', value: [],label:'Gallery Image',uploadtype:'allpage', disabled: '', required: true, },
    ],
  isLoading: true,
  addButton:true,
},
  ])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    for(let i=0;i<cardElementStatus.length;i++){
        cardElementStatus[i].getStatusData(cardElementStatus[i].name,cardElementStatus[i].statusList).then((res) => {
        cardElementStatus[i].active = res.active
        cardElementStatus[i].inactive = res.inactive
        cardElementStatus[i].total = res.total
        cardElementStatus[i].isLoading = false
        setCardElement([...cardElementStatus])
      })
    }
  setIsLoading(false)
  }, [])
  // const changeTable = async (e, type, index, prod) => {
  //   const cardElementList = [...cardElement]
  //   if (type == true || type == false) {
  //     setSingleItem({ status: true })
  //     setOperationTable('Table')
  //   } else {
  //     setOperationTable(type)
  //   }
  //   setIndexValue(index)

  //   setTitle(cardElementList[index].title)
  //   setColumns([...cardElementList[index].HeaderColumns])
  //   setInputType([...cardElementList[index].inputType])
  //   setAddButton(cardElementList[index].addButton)
  //   if (type == true || type == false) {
  //     setIsLoading(true)

  //     await getBanner(cardElementList[index].name, type).then(result => {
  //       if (result.success) {
  //         setData(result.data)
  //         setIsLoading(false)
  //       } else {
  //         setData([])
  //         setIsLoading(false)
  //       }

  //     })

  //   } else if (type == 'Table') {
  //     setIsLoading(true)
  //     await getBanner(cardElementList[index].name, "All").then(result => {

  //       if (result.success) {
  //         setData(result.data)
  //         setIsLoading(false)
  //       } else {
  //         setData([])
  //         setIsLoading(false)
  //       }
  //     })

  //   } else if (type == 'View' || type == 'Deleted' || type == 'Edit') {
  //     setIsLoading(true)

  //     setOperationTable(type)


  //     await getBanner(cardElement[index].name, prod._id).then(result => {

  //       if (result.success) {
  //         setSingleItem({ ...result.data })
  //         setIsLoading(false)
  //       } else {
  //         setSingleItem({})
  //         setIsLoading(false)
  //       }

  //     })


  //   }
  // }

  const changeTable = async (e, type, index,prod) => {
    e.preventDefault()
    setIsLoading(true)
    setIndexValue(index)
    const cardElementList = [...cardElement]
    setAddButton(cardElementList[index].addButton)
    setTitle(cardElementList[index].title)

  if (type == 'Table' || type == true || type == false) {
      setColumns(cardElementList[index].HeaderColumns)
      setOperationTable('Table')
    await cardElement[index].getData(cardElementList[index].name,type == 'Table'? "All" :type).then(result => {
        
        if (result.success) {
          setData(result.data)
        } else {
          setData([])
        }
      })
      setIsLoading(false)
    }else if (type == 'Add') {
    for(let i=0;i<cardElementList[index].inputType.length;i++){
      if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
        cardElementList[index].inputType[i].value=[]
        await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
            for (let k = 0; k < results.data.length; k++) {
           
                    let data = {
                        label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                        value: results.data[k]._id,
                    }
                    cardElementList[index].inputType[i].value.push(data)
                  }
            })
          }
      }
   

      setInputType([...cardElementList[index].inputType])
      setOperationTable('Add')
      const si ={status:true}
      setSingleItem({...si})
      setIsLoading(false)
    }else{
    
      setInputType([...cardElement[index].inputType])
      if(type == 'Edit' || type == 'View' || type == 'Deleted'){
        if(cardElement[index].name === 'Different Page Relation'){
          setSortTreeStatus(true)
        }
        await cardElement[index].getData(cardElement[index].name,prod._id).then(async results=>{
          setSingleItem(results.data)
          for(let i=0;i<cardElementList[index].inputType.length;i++){
        
            if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
              cardElementList[index].inputType[i].value=[]
              await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
                  for (let k = 0; k < results.data.length; k++) {
                 
                          let data = {
                              label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                              value: results.data[k]._id,
                          }
                          cardElementList[index].inputType[i].value.push(data)
                        }
                  })
                }
            }
          setIsLoading(false)
          setInputType([...cardElementList[index].inputType])
          setOperationTable(type)
        })
      }

    }
  }
  const postData = (e, values) => {

    const cardElementList = [...cardElement]
    console.log(cardElementList[indexValue].name)
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    console.log(values)
    postBanner(values, cardElementList[indexValue].name, operationsTable).then(result => {
      if (result.success) {
        setIsLoading(true)
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        getBanner(cardElementList[indexValue].name, status).then(result => {
          if (result.success) {
            setData(result.data)
            setIsLoading(false)
          } else {
            setData([])
            setIsLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  console.log(cardElement)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Banner | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == '' ?
              <Breadcrumbs
                title={"CMS"}
                breadcrumbItem={"Banner"}
                bredcumlink={"cms"}
              />
              : <Breadcrumbs
                title={'Banner'}
                breadcrumbItem={title}
                bredcumlink={'banner'}
              />
          }

          <Row>
            {

              operationsTable == '' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}>
                    {
                      isLoading ?

                        <UiLoading />

                        :
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type) => changeTable(e, type, index)}
                          addButton={d.addButton}
                        />
                    }

                  </Col>
                ))
                :
                operationsTable == 'Table' ?
                  <Card>
                    {
                      isLoading ?
                        <UiLoading />
                        : <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type) => changeTable(e, type, indexValue)}
                          columns={columns}
                          // addButton={add}
                          addButton={addButton}
                        />
                    }

                  </Card>
                  :
                  operationsTable == 'Add' || operationsTable == 'Edit' || operationsTable == 'Deleted' || operationsTable == 'View' ?
                    <>
                      {
                        isLoading ?
                          <UiLoading />
                          :
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'banner'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type,) => changeTable(e, type, indexValue)}
                            inputType={inputType}
                            postData={postData}
                          />
                      }
                    </>
                    : ''
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default withTranslation()(Banner)
