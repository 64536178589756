import React from 'react';
import {Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
export const UiLoading= ({cardElementOption})=>{
    return(
        <Card style={{width:'100%',height:'500px',textAlign:'center'}}>
          <CardBody >
          <div className="spinner-border text-primary" role="status">
  <span className="sr-only">Loading...</span>
</div>
          </CardBody>
        </Card>
         
    
       
    )
}
UiLoading.propTypes = {
  cardElementOption: PropTypes.bool,
};
export default UiLoading;