import PropTypes from "prop-types"
import React from "react"
import MetaTags from "react-meta-tags"
import { Button, Card, CardBody } from "reactstrap"

import { Link } from "react-router-dom"
import UiLoading from "UI/loading"

const SubGalleryCardElementWithEvent = ({ data, changeTable, addButton }) => {
  return (
    <Card>
      <CardBody>
        {
          data.isLoading?
          <UiLoading cardElementOption={data.isLoading} />
          :
          <>
           <h1 style={{ textAlign: "center", fontSize: "15px" }}>{data.title}</h1>
        <div className="text-center">
          <h1 style={{ textAlign: "center", fontSize: "30px",margin:'30px' ,color:'red'}}>
            {data.total}
          </h1>
         

          {addButton !== undefined ? (
            addButton ? (
              <Button
                type="button"
                color="info"
                className="btn-rounded  mb-2 me-2"
                onClick={e => changeTable(e, "Sub Card", data.name)}
                style={{
                  backgroundColor: "#556ee6",
                  color: "white",
                  border: "none",
                }}
              >
                <i className="mdi mdi-plus me-1" />
                Add
              </Button>
            ) : (
              ""
            )
          ) : (
            <Button
              type="button"
              color="info"
              className="btn-rounded  mb-2 me-2"
              onClick={e => changeTable(e, "Sub Card", data.title)}
              style={{
                backgroundColor: "#556ee6",
                color: "white",
                border: "none",
              }}
            >
              <i className="mdi mdi-plus me-1" />
              Add
            </Button>
          )}
          <Button
            type="button"
            color="info"
            className="btn-rounded  mb-2 me-2"
            onClick={e => changeTable(e, "Sub Card", data.name)}
            style={{
              backgroundColor: "#556ee6",
              color: "white",
              border: "none",
            }}
          >
            Sub Card
          </Button>
        </div>
          </>
        }
       
      </CardBody>
    </Card>
  )
}

SubGalleryCardElementWithEvent.propTypes = {
  t: PropTypes.any,
  data: PropTypes.object,
  changeTable: PropTypes.func,
  title: PropTypes.string,
  addButton: PropTypes.bool,
}

export default SubGalleryCardElementWithEvent
