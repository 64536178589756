
import {url} from 'routes';
export const getEventStatus=async(table,status,type)=>{
    console.log(url.event)
    return fetch(`${url.event}/status?table=${table}&&status=${status}`,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
export const getEvent=async(table,status,type)=>{
    return fetch(`${url.event}?table=${table}&&status=${status}&&type=${type}`,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}

export const postEvent=async(data,table,operation)=>{
    return fetch(`${url.event}?table=${table}&&operation=${operation}`,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
export const getPageContent=async(id)=>{
    return fetch(`${url.event}/page-description/${id}`,{
        method:'GET',
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}


export const postPageContent=async(data)=>{
    return fetch(`${url.event}/page-description`,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
export const getPageGallery=async(id)=>{
    return fetch(`${url.event}/page-gallery/${id}`,{
        method:'GET',
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}


export const postPageGallery=async(data)=>{
    return fetch(`${url.event}/page-gallery`,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}