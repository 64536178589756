import PropTypes from "prop-types"
import React from "react"
import MetaTags from "react-meta-tags"
import { Button, Card, CardBody } from "reactstrap"

import { Link } from "react-router-dom"
import UiLoading from "UI/loading"

const GalleryCardElementwithEvent = ({ data, changeTable, addButton,isLoading }) => {
  return (
    <Card>
      <CardBody>
        {
          data.isLoading ?
          <UiLoading />
          :
        
        
          <>
           <h1 style={{ textAlign: "center", fontSize: "15px" }}>{data.title}</h1>
        <div className="text-center">
          <h1 style={{ textAlign: "center", fontSize: "30px" }}>
            {data.total}
          </h1>
          <div
            style={{
              marginBottom: "15%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="button"
              color="info"
              className="btn-rounded  mb-2 me-2"
              style={{
                backgroundColor: "green",
                color: "white",
                border: "none",
              }}
              onClick={e => changeTable(e, true, data.name)}
            >
              Active ({data.active})
            </Button>

            {/* <div style={{ backgroundColor: '#d49ca6', paddingTop: '4%', paddingRight: '4%', paddingLeft: '4%', borderRadius: '10px' }}> */}
            <Button
              type="button"
              color="info"
              className="btn-rounded  mb-2 me-2"
              style={{ backgroundColor: "red", color: "white", border: "none" }}
              onClick={e => changeTable(e, false, data.title)}
            >
              In-Active ({data.inactive})
            </Button>
            {/* </div> */}
          </div>

          {addButton !== undefined ? (
            addButton ? (
              <Button
                type="button"
                color="info"
                className="btn-rounded  mb-2 me-2"
                onClick={e => changeTable(e, "Add", data.name)}
                style={{
                  backgroundColor: "#556ee6",
                  color: "white",
                  border: "none",
                }}
              >
                <i className="mdi mdi-plus me-1" />
                Add
              </Button>
            ) : (
              ""
            )
          ) : (
            <Button
              type="button"
              color="info"
              className="btn-rounded  mb-2 me-2"
              onClick={e => changeTable(e, "Add", data.title)}
              style={{
                backgroundColor: "#556ee6",
                color: "white",
                border: "none",
              }}
            >
              <i className="mdi mdi-plus me-1" />
              Add
            </Button>
          )}
          <Button
            type="button"
            color="info"
            className="btn-rounded  mb-2 me-2"
            onClick={e => changeTable(e, "Table", data.name)}
            style={{
              backgroundColor: "#556ee6",
              color: "white",
              border: "none",
            }}
          >
            Table
          </Button>
        </div>
          </>
        
          }
      </CardBody>
    </Card>
  )
}

GalleryCardElementwithEvent.propTypes = {
  isLoading: PropTypes.bool,
  t: PropTypes.any,
  data: PropTypes.object,
  changeTable: PropTypes.func,
  title: PropTypes.string,
  addButton: PropTypes.bool,
}

export default GalleryCardElementwithEvent
