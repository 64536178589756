
import {url} from '../../routes';
export const getMiscellaneousStatus=async(table,status)=>{
    return fetch(`${url.miscellaneous}/status?table=${table}&&status=${status}`,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
export const getMiscellaneous=async(table,status)=>{
    return fetch(`${url.miscellaneous}?table=${table}&&status=${status}`,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}

export const postMiscellaneous=async(data,table,operation)=>{
    console.log('gopal')
    console.log(data)
    return fetch(`${url.miscellaneous}?table=${table}&&operation=${operation}`,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
