import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"
import { Link } from "react-router-dom"
import { removeNodeAtPath } from 'react-sortable-tree';
import SortTreeRelation from "UI/sortTreeRelation"
import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"
import { getpageStructureLinkStatus, getpageStructurLink, postPageStructureLink,getPageContent,getPageGallery,postPageContent,postPageGallery } from "data/pageStructureLink"
import InputFormElement from "UI/InputFormElement"
import { getpageStructure } from "data/pageStructure"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { getEmployee } from "data/systemadmin/employee"
import { isEmpty } from "data/middlefunctions"
import UiLoading from "UI/loading"
import CustomImageTab from "UI/CustomImageTab"
import CustomeTab from "UI/CustomTab"
import { getSupplier } from "data/systemadmin/supplier"

const categoryStructureLink = () => {
  const [messageLoading,setMessagesLoading] = useState(false)
  const [messages,setMessages] = useState('')
  const [searchFilter,setSearchFilter] = useState({})
  const [saveData,setSaveData] = useState(false)
  const [typeofpage,setTypeOfPage] = useState('')
  const [allPageLoading, setAllPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  const [addButton, setAddButton] = useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({ pagedescription: '',alldocument:[], status: true,discountsetup:true,typeofcoupone:true,modeofprice:'variable', eventstructure:false })
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [pagedescriptionid, setPageDescriptionId] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [cardValue, setCardValue] = useState({})
  const [treeData,setTreeData] = useState([])
  const [inputType, setInputType] = useState([
    { title: 'categorylist', name: 'pagetype', type: 'hidden', value: 'categorylist', disabled: '' },
    { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
    { title: `productcategoryid`, name: 'productcategoryid', type: 'hidden', value: 'productcategoryid', disabled: '', },
    { title: `Type Of Content`, name: 'pagedescription', type: 'text', value: '', disabled: true, gride: 3 },
    { title: 'Title', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 3 },
    { title: 'Supplier', name: 'supplier', type: 'select', value: [], outputDropDown: 'name', outputData: getSupplier, table: 'Company List',filterData:getMiscellaneous,filterTable :'Category',filterType:true,filterList:'supplier', status: true, required: true, gride: 3 },
    { title: 'Type Of Meter', name: 'typeofmeter', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Type Of Meter',filterData:getMiscellaneous,filterTable :'Category',filterType:true,filterList:'typeofmeter', status: true, required: true, gride: 3 },
    { title: 'Category', name: 'category', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Category', filterData:getMiscellaneous,filterTable :'Category',filterType:true,filterList:'dependancycategory', status: true, required: true, gride: 4 },
    { title: 'Customer Type', name: 'customertype', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Customer Type', filterData:getMiscellaneous,filterTable :'Category',filterType:true,filterList:'customertype', status: true, required: true, gride: 4 },
    { title: 'Supply Region', name: 'region', type: 'multiple', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Supply Region', status: true, disabled: '', required: true, gride: 4 },
    { title: 'Billing Type', name: 'billingtype', type: 'multiple', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Bill Payment', status: true, disabled: '', required: true, gride: 4 },
    { title: 'Billing Mode', name: 'billingmode', type: 'multiple', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Billing Mode', status: true, disabled: '', required: true, gride: 4 },
    { title: 'Generate Discount', name: '', type: 'button', value: '', disabled: '', required: true, gride: 4 ,multiplicationData :['category','customertype','region','billingtype','billingmode','action']},
    { title: 'Package Start Date', name: 'startdate', type: 'date', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Package End date', name: 'enddate', type: 'date', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Coupone Amount', name: 'couponeamount', type: 'text', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Exit Vale', name: 'exitvalue', type: 'text', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Contarct Length', name: 'contractlength', type: 'text', value: '', disabled: '', required: true, gride: 4 },
    { title: 'All Document', name: 'alldocument', type: 'addmore', value: [],headertitle:['Sl.','Title','Url','Deleted'],headerBody:[],headerValue:{title:'',url:''}, disabled: '', required: true, gride: 4 },
    // { title: 'Discount', name: 'discount', type: 'multiple', value: [], dependacy:true,dependacyList:['category','supplier'],childDependancy:['category','discount'],parentList:'supplier',dependancyValue:[],dependancyType:'category', outputDropDown: 'name', outputData: getMiscellaneous, table: 'Discount Value', status: 'Drop Down', required: true, gride: 4 },
    { title: 'Discount Percentage', name: 'discount', type: 'dynamicdata', value: [], disabled: '', required: '', gride: 12 },
    { title: 'Short Note', name: 'sortdescription', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
    { title: 'Image', name: 'image', type: 'file', value: [], single: true,label:"Image", uploadtype: 'page' },
    { title: 'Package Reference Url', name: 'planrefference', type: 'text', value: '', disabled: '', required: '', gride: 6 },
    { title: 'Term Condition Url', name: 'termconditionurl', type: 'text', value: '', disabled: '', required: '', gride: 6 },
    { title: 'Admin Comment', name: 'admincomment', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
    { title: 'Supplier message', name: 'suppliermessage', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
    { title: 'Term And Condition', name: 'termand', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
    // { title: 'Discount Setup', name: 'discountsetup', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'discountsetup', value: true }, { title: 'In Active', name: 'discountsetup', value: false }] },
    { title: 'Type of Coupone', name: 'typeofcoupone', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: '%', name: 'typeofdiscoutn', value: '%' }, { title: 'Amount', name: 'typeofdiscoutn', value: 'Amount' }] },
    { title: 'Mode Of Price', name: 'modeofprice', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Variable', name: 'modeofprice', value: 'variable' }, { title: 'Fixed', name: 'modeofprice', value: 'Fixed' }] },
    { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] },
    { title: '', name: 'uploadtype', type: 'hidden', value: 'page', disabled: '' }
  ])

  const [cardElement, setCardElement] = useState([])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    await getMiscellaneous('Category', 'All').then(async result => {
     
      const d = []
      if (result.success) {
        for (let i = 0; i < result.data.length; i++) {
             if(result.data[i].packagestatus){
              await getpageStructureLinkStatus(result.data[i]._id, 'Category').then(results => {
                 d.push({
                   _id: result.data[i]._id,
                   name: result.data[i].name,
                   title: result.data[i].name,
                   typeofpage:result.data[i].typeofpage,
                   total: results.total,
                   active: results.active,
                   inactive: results.inactive,
                   getStatusData: getpageStructurLink,
                   getData: getpageStructurLink,
                   getPostData: postPageStructureLink,
                   statusList: 'All',
                   HeaderColumns: [
                     {
                       dataField: "_id",
                       text: "SL. No",
                       sort: true,
                       // eslint-disable-next-line react/display-name
                       formatter: (cell, prod, index) => index + 1,
                     },
                     {
                       dataField: "name",
                       text: "Content Title",
                       sort: true,
                   },
                     {
                       dataField: "name",
                       text: "Supplier",
                       sort: true,
                       formatter: (cell, prod, index) =>(
                         prod.supplier != undefined && prod.supplier != null ? prod.supplier.name :'No Supplier'
                       )
 
                     },
                  {
                   dataField: "name",
                   text: "Catrgory",
                   sort: true,
                   formatter: (cell, prod, index) =>(
                     prod.category != undefined && prod.category != null ? prod.category.map(d=> `${d.name},`) :'No Category'
                   )
                  },
                  {
                   dataField: "name",
                   text: "Customer",
                   sort: true,
                   formatter: (cell, prod, index) =>(
                    prod.customertype != undefined && prod.customertype != null && prod.customertype.length != 0 ? prod.customertype.map(d=>`${d.name},`):''
                  )
                  },
                   {
                   dataField: "startdate",
                   text: "Package Start",
                   sort: true,
                   sort: true,
                   formatter: (cell, prod, index) =>(
                     prod.startdate != undefined && prod.startdate != null ? prod.startdate.split('T')[0]:''
                   )
                      },
                     {
                       dataField: "enddate",
                       text: "Package End",
                       sort: true,
                       sort: true,
                       formatter: (cell, prod, index) =>(
                        prod.enddate != undefined && prod.enddate != null ? prod.enddate.split('T')[0]:''
                       )
                     },
                     {
                       dataField: "exitvalue",
                       text: "Exit Value",
                       sort: true,
                   },
                       {
                         dataField: "contractlength",
                         text: "Contract Length",
                         sort: true,
                     },
                       {
                         dataField: "modeofprice",
                         text: "Mode Of Price",
                         sort: true,
                     },
                       {
                         dataField: "couponeamount",
                         text: "Coupone",
                         sort: true,
                     },
                    {
                       dataField: "",
                       text: "Status",
                       sort: true,
                       formatter: (cell, prod, index) =>
                         prod.status == true ? <p>ON</p> : <p>OFF</p>,
                     },
                     {
                       dataField: "action",
                       isDummyField: true,
                       text: "Action",
                       // eslint-disable-next-line react/display-name
                       formatter: (cell, prod, index) => (
                         <>
                           <div className="d-flex gap-3">
                             <Link to="#" className="text-success">
                               <i
                                 className="mdi mdi-pencil font-size-18"
                                 id="edittooltip"
                                 onClick={e => changeTable(e, "Edit",parseInt(d.length-1), prod,result.data[i]._id,result.data[i])}
                               />
                             </Link>
                             <Link to="#" className="text-danger">
                               <i
                                 className="mdi mdi-delete font-size-18"
                                 id="deletetooltip"
                                 onClick={e => changeTable(e, "Deleted", parseInt(d.length-1), prod,result.data[i]._id,result.data[i])}
                               />
                             </Link>
                             <Link to="#" className="text-danger">
                               <i
                                 className="mdi mdi-eye font-size-18"
                                 id="deletetooltip"
                                 onClick={e => changeTable(e, "View", parseInt(d.length-1), prod,result.data[i]._id,result.data[i])}
                               />
                             </Link>
                           </div>
                         </>
                       ),
                     },
     
     
                   ],
     
                   isLoading: false,
                   addButton: true,
                 })
               })
             }
            
          

        }
        setCardElement(d)
        setAllPageLoading(false)
      }
    })
    // console.log(cardElementStatus)

    setIsLoading(false)

  }, [])
 
  const changeTable = async (e, type, index, prod, pagedescriptionid,prods) => {
    console.log(index)
    setTypeOfPage()
    // let inputTypes =[]
    let inputLists = [...inputType]
   
    
    
    // const inputLists = [...inputTypes]
    console.log(pagedescriptionid)
    setPageDescriptionId(pagedescriptionid)
    e.preventDefault()
    setCardValue(prods)

    setIndexValue(index)
    setAllPageLoading(true)
    setIsLoading(true)
    const cardElementList = [...cardElement]
  
      setAddButton(true)
      setTitle(prods.name)
    

    if (type == 'Table' || type == true || type == false) {
      setColumns(cardElementList[index].HeaderColumns)
      setOperationTable('Table')

      await cardElement[index].getData(pagedescriptionid, type == 'Table' ? `All` : `${type}`,'Category').then(result => {

        if (result.success) {
          setData(result.data)
          setAllPageLoading(false)
          setIsLoading(false)
        } else {
          setData([])
          setAllPageLoading(false)
          setIsLoading(false)
        }
      })

    } else if (type == 'Add') {

      if (prod.name !== undefined) {
        setTitle(prod.name)
      } else {
        setTitle(cardElementList[index].title)
      }


      for(let i=0;i<inputLists.length;i++){
        
        if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
          inputLists[i].value=[]
          await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(async results => {
           
            if(inputLists[i].filterType && inputLists[i].filterType != undefined && inputLists[i].filterType != null){
              
              for (let k = 0; k < results.data.length; k++) {
                let filetData =[]
                await inputLists[i].filterData(inputLists[i].filterTable,pagedescriptionid).then(result=>{
                 
                  if(result.success){
                    filetData=result.data[inputLists[i].filterList] != undefined && result.data[inputLists[i].filterList] != null ?result.data[inputLists[i].filterList] :[] 
                  }else{
                    filetData=[]
                  }
                
                })
               
                if(filetData.includes(results.data[k]._id)){
                  let data = {
                      label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                      value: results.data[k]._id,
                      ... results.data[k]
                  }
                  if(inputLists[i].dependacy && inputLists[i].dependacy != undefined && inputLists[i].dependacy != null){
                    inputLists[i].dependancyValue.push(data)
                  }else{
                    inputLists[i].value.push(data)
                  }
                }
              }
            }else{
              for (let k = 0; k < results.data.length; k++) {
             
                let data = {
                    label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                    value: results.data[k]._id,
                    ...results.data[k]
                }
                if(inputLists[i].dependacy && inputLists[i].dependacy != undefined && inputLists[i].dependacy != null){
                  inputLists[i].dependancyValue.push(data)
                }else{
                  inputLists[i].value.push(data)
                }
              }
            }
              
              })
                
           
            }
        }
     
      setSingleItem({ status: true,eventstructure:false,alldocument:[], pagedescription: prods.name, productcategoryid: prods._id })

      setInputType([...inputLists])
      setOperationTable('Add')
      setAllPageLoading(false)
      setIsLoading(false)
      // setIsLoading(false)
    } else {
       if (type == 'Edit' || type == 'View' || type == 'Deleted') {

        await getpageStructurLink(prod.name, prod._id).then(async results => {
        console.log(results)
  
          setSingleItem({ pagedescription: prods.name, ...results.data })

          for(let i=0;i<inputLists.length;i++){
        
            if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
              inputLists[i].value=[]
             await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(async resul => {
              
                if(inputLists[i].filterType && inputLists[i].filterType != undefined && inputLists[i].filterType != null){
             
                  for (let k = 0; k < resul.data.length; k++) {
                    let filetData =[]
                    await inputLists[i].filterData(inputLists[i].filterTable,results.data.productcategoryid                    ).then(result=>{
                     
                      if(result.success){
                        filetData=result.data[inputLists[i].filterList] != undefined && result.data[inputLists[i].filterList] != null ?result.data[inputLists[i].filterList] :[] 
                      }else{
                        filetData=[]
                      }
                    
                    })
                 
                    if(filetData.includes(resul.data[k]._id)){
                      let data = {
                          label: resul.data[k][Object.keys(resul.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                          value: resul.data[k]._id,
                      }
                      if(inputLists[i].dependacy && inputLists[i].dependacy != undefined && inputLists[i].dependacy != null){
                        inputLists[i].dependancyValue.push(data)
                      }else{
                        inputLists[i].value.push(data)
                      }
                      
                    }
                  }
                }else{
                  for (let k = 0; k < resul.data.length; k++) {
                 
                    let data = {
                        label: resul.data[k][Object.keys(resul.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                        value: resul.data[k]._id,
                    }
                    if(inputLists[i].dependacy && inputLists[i].dependacy != undefined && inputLists[i].dependacy != null){
                    inputLists[i].dependancyValue.push(data)
                    }else{
                      inputLists[i].value.push(data)
                    }
                  }
                }
                  
                  })
                    
               
                }
            }
      
          setInputType([...inputLists])
          setOperationTable(type)
          setIsLoading(false)
        })
      }

    }
  }


  const postData = (e, values,single) => {
  
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
 
    if (values.name !== undefined) {
      values['slug'] = values.name.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.name;
    }
    if (values.title !== undefined) {
      values['slug'] = values.title.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.title;
    }
    setAllPageLoading(true)
    setSaveData(true)
    console.log(single)
    values.discount=single['discount']
    values.alldocument = single['alldocument']
    console.log(values)
    postPageStructureLink(isEmpty(values), cardElementList[indexValue].name, operationsTable).then(result => {
      console.log(result.data.productcategoryid)
      alert(`Data ${operationsTable} Successfully`)
      setSaveData(false)
      if (result.success) {
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        getpageStructurLink(result.data.productcategoryid,true, 'Category').then(result => {
          if (result.success) {
            setData(result.data)
            setAllPageLoading(false)
          } else {
            setData([])
            setAllPageLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  const selctdropDownList=async (e, rowData,title)=>{
    console.log(rowData)
    setSearchFilter(e)
  
    setMessagesLoading(true)
    setMessages(`${e.label} Data Loading ...`)    
  await postPageStructureLink(isEmpty({datalist:rowData}), '', e.label).then(result => {
    console.log(result)
    if(result.success) {
      setMessages(` ${e.label} Data ${result.data} Successfully ...`)   
      setMessagesLoading(false)
      if(e.label == "Export" || e.label == "Sample"){
        window.location.href=`${url.commonUrls}/download/${result.data.fileName}`
      }else{
        //  setIsLoading(true) 

        //  setColumns([...cardElements.find(d => d.name === title).HeaderColumns])
        //   getMiscellaneous(title, status).then(result => {
        //     if (result.success) {
        //       setSearchFilter({})
        //       setData(result.data)
        //       setOperationTable('Table')
        //       setIsLoading(false)
        //     } else {
        //       setData([])
        //       setOperationTable('Table')
        //       setIsLoading(false)
        //     }
  
        //   })
       }
      } else {
        alert('Hello')
      }
    })
   
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Category Manager | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
              <Breadcrumbs
                title={"Dynamic Page Dashboard"}
                breadcrumbItem={"Page Manager"}
                bredcumlink={"dynamic-page-structure"}
              />
              : <Breadcrumbs
                title={'Category Link'}
                breadcrumbItem={title}
                bredcumlink={'category-list'}
              />
          }

          <Row>
            {


              isLoading ?
                <UiLoading />
                :
                operationsTable == 'Card' ?
                  cardElement.map((d, index) => (
                    <Col sm={4} md={3} key={index}>

                      <GalleryCardElementwithEvent
                        data={d}
                        changeTable={(e, type) => changeTable(e, type, index, { pagedescription: d.name,category:d._id }, d._id,d)}
                        addButton={d.addButton}
                        isLoading={isLoading}
                      />
                    </Col>
                  ))
                  :
                  operationsTable == 'Table' ?
                 
                    <Card>
                         {
                       messageLoading ?  
                        <p style={{textAlign: "center",color: 'green',paddingTop: '16px'}}>{messages}</p>
                       :''
                      }
                      <ReactTable
                        title={title}
                        datas={data}
                        changeTable={(e, type) => changeTable(e, type, indexValue, { pagedescription: cardValue.name }, cardValue._id,cardValue)}
                        columns={columns}
                        addButton={addButton}
                        selctdropDownList={(e,rowData)=>selctdropDownList(e,rowData,indexValue)}
                        searchFilter={searchFilter}
                        selectSearchData={(e,rowData)=>selectSearchData(e,rowData,indexValue)}
                        searchValueLists={(typelist,typedata)=>searchValueLists(typelist,typedata)}
                        isLoading={isLoading}
                      />




                    </Card>
                    :operationsTable == 'Tree' ?
                  
                    isLoading ?
                     <UiLoading cardElementOption ={!isLoading ?true :false}/>
                  :
                  <SortTreeRelation   isLoading={isLoading} treeData={treeData} saveTreeData={()=>saveTreeData()} treeDataValue={(tree)=>treeDataValue(tree)} removeNode={(rowInfo)=>removeNode(rowInfo,indexValue)}/>
                  
                  :operationsTable == 'Gallery' ?
                  
                  isLoading ?
                   <UiLoading cardElementOption ={!isLoading ?true :false}/>
                :
                <CustomImageTab  data={data} singleItem={singleItem} submitFormData={(e,data)=>submitImageData(e,data)}/>
                
                :operationsTable == 'Page Description' ?
                  
                isLoading ?
                 <UiLoading cardElementOption ={!isLoading ?true :false}/>
              :
              <CustomeTab  data={data} singleItem={singleItem} submitFormData={(e,data)=>submitFormData(e,data)}/>
              
              :      
                  isLoading ?
                  <UiLoading/>
               :

                    <InputFormElement
                      operationsTable={operationsTable}
                      singleItem={singleItem}

                      bredcumlink={'InformationPage'}
                      viewIcon={false}
                      filetype='single'

                      title={title}
                      changeTable={(e, type) => changeTable(e, type, indexValue, { pagedescription: cardValue.name }, cardValue._id,cardValue)}
                      inputType={inputType}
                      postData={postData}
                      isLoading={isLoading}
                      saveData={saveData}
                    />


            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default categoryStructureLink
