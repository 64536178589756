import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"
import { Link } from "react-router-dom"

import ReactTable from "UI/Table"

import InputFormElement from "UI/InputFormElement"

import UiLoading from "UI/loading"

import { getDropDownOperational, getPublishLocation,postPublishPincode } from "data/pageStructure/publisherlocation"
import { generate_random_number, generate_random_string, isEmpty } from "data/middlefunctions"

import DynamicCardElementwithEvent from "UI/DynamicCardElement"
const PublishedLocationList = () => {
    const [indexValue,setIndexValue] = useState()
    const [addButton,setAddButton] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [operationsTable, setOperationTable] = useState('')
    const [singleItem, setSingleItem] = useState({status:true,sku:generate_random_number() + generate_random_string(3),moq:1})
    const [status, setStatus] = useState('All')
    const [title, setTitle] = useState('')
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [inputType, setInputType] = useState([])
    const [cardElement, setCardElement] = useState([
        {
            name: 'Operational Country',
            title: 'Operational Country',
            'Operational': 0,
            'Blocked': 0,
            total: 0,
            activecolor:'green',
            incativecolor:'yellow',
            isLoading: true,
            addButton:false,
            outputData:getPublishLocation,
            status:'All',
            HeaderColumns: [
                {
                    dataField: "_id",
                    text: "SL. No",
                    sort: true,
                    // eslint-disable-next-line react/display-name
                    formatter: (cell, prod, index) => index + 1,
                },
                {
                    dataField: "country",
                    text: "Name",
                    sort: true,

                },
                {
                    dataField: "operation",
                    text: "Operation",
                    sort: true,
                    formatter:(cell,prod,index)=> prod.operation == true ? <p>ON</p> : <p>OFF</p>,
                },
                {
                    dataField: "symboles",
                    text: "Symbol",
                    sort: true,
                    
                },
                {
                    dataField: "code",
                    text: "Code",
                    sort: true,
                    
                },
              
                {
                    dataField: "",
                    text: "Status",
                    sort: true,
                    formatter: (cell, prod, index) =>
                        prod.status == true ? <p>ON</p> : <p>OFF</p>,
                },

                {
                    dataField: "action",
                    isDummyField: true,
                    text: "Action",
                    // eslint-disable-next-line react/display-name
                    formatter: (cell, prod, index) => (
                        <>
                            <div className="d-flex gap-3">
                                <Link to="#" className="text-success">
                                    <i
                                        className="mdi mdi-pencil font-size-18"
                                        id="edittooltip"
                                        onClick={e => changeTable(e, "Edit", 0, prod)}
                                    />
                                </Link>
                               
                              
                            </div>
                        </>
                    ),
                },
              
            ],
            inputType: [
                { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
                { title: 'Country', name: 'country', type: 'text', value: '', disabled: true, required: true, },
                { title: 'Operation Country', name: 'operation', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'operation', value: true }, { title: 'In Active', name: 'operation', value: false }] }
            ],
          
        },
        {
            name: 'Operational State',
            title: 'Operational State',
            'Operational': 0,
            'Blocked': 0,
            total: 0,
            activecolor:'green',
            incativecolor:'yellow',
            isLoading: true,
            addButton:false,
            outputData:getPublishLocation,
            status:'All',
            HeaderColumns: [
              {
                dataField: "_id",
                text: "SL. No",
                sort: true,
                // eslint-disable-next-line react/display-name
                formatter: (cell, prod, index) => index + 1,
              },
              {
                dataField: "country",
                text: "Country",
                sort: true,
                formatter: (cell, prod, index) => prod.country !== undefined && prod.country !== null ? prod.country.country : '',
              },
              
              {
                dataField: "name",
                text: "State",
                sort: true,
              
              },
            
              {
                dataField: "operation",
                text: "Operation",
                sort: true,
                formatter: (cell, prod, index) =>
                  prod.operation == true ? <p>ON</p> : <p>OFF</p>,
              },
              {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                // eslint-disable-next-line react/display-name
                formatter: (cell, prod, index) => (
                  <>
                    <div className="d-flex gap-3">
                      <Link to="#" className="text-success">
                        <i
                          className="mdi mdi-pencil font-size-18"
                          id="edittooltip"
                          onClick={e => changeTable(e, "Edit",1, prod)}
                        />
                      </Link>
                     
                      <Link to="#" className="text-danger">
                        <i
                          className="mdi mdi-eye font-size-18"
                          id="deletetooltip"
                          onClick={e => changeTable(e, "View",1, prod)}
                        />
                      </Link>
                    </div>
                  </>
                ),
              },
            ],
            inputType: [
              { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
         
              { title: 'State', name: 'name', type: 'text', value: '', disabled: true, required: true },
      
              { title: 'Operation Country', name: 'operation', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'operation', value: true }, { title: 'In Active', name: 'operation', value: false }] }
            ],
            isLoading: true,
            
          },
          {
            name: 'Operational City',
            title: 'Operational City',
            'Operational': 0,
            'Blocked': 0,
            total: 0,
            activecolor:'green',
            incativecolor:'yellow',
            isLoading: true,
            addButton:false,
            outputData:getPublishLocation,
            status:'All',
            HeaderColumns: [
              {
                dataField: "_id",
                text: "SL. No",
                sort: true,
                // eslint-disable-next-line react/display-name
                formatter: (cell, prod, index) => index + 1,
              },
              {
                dataField: "country",
                text: "Country",
                sort: true,
                formatter: (cell, prod, index) => prod.country !== undefined && prod.country !== null ? prod.country.country : '',
              },
              {
                dataField: "State",
                text: "State",
                sort: true,
                formatter: (cell, prod, index) => prod.state !== undefined && prod.state !== null ? prod.state.name : '',
              },
              {
                dataField: "name",
                text: "City",
                sort: true,
              
              },
              {
                dataField: "operation",
                text: "Operation",
                sort: true,
                formatter: (cell, prod, index) =>
                  prod.operation == true ? <p>ON</p> : <p>OFF</p>,
              },
              {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                // eslint-disable-next-line react/display-name
                formatter: (cell, prod, index) => (
                  <>
                    <div className="d-flex gap-3">
                      <Link to="#" className="text-success">
                        <i
                          className="mdi mdi-pencil font-size-18"
                          id="edittooltip"
                          onClick={e => changeTable(e, "Edit",2, prod)}
                        />
                      </Link>
                     
                      <Link to="#" className="text-danger">
                        <i
                          className="mdi mdi-eye font-size-18"
                          id="deletetooltip"
                          onClick={e => changeTable(e, "View",2, prod)}
                        />
                      </Link>
                    </div>
                  </>
                ),
              },
            ],
            inputType: [
              { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
              { title: 'Operational City', name: 'name', type: 'name', value: '', disabled: true, required: '' },
              { title: 'Operation Country', name: 'operation', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'operation', value: true }, { title: 'In Active', name: 'operation', value: false }] }
            ],
            isLoading: true,
         
          },
        
          {
            name: 'Operational Area',
            title: 'Operational Area',
            'Operational': 0,
            'Blocked': 0,
            total: 0,
            activecolor:'green',
            incativecolor:'yellow',
            isLoading: true,
            addButton:false,
            outputData:getPublishLocation,
            status:'All',
            HeaderColumns: [
              {
                dataField: "_id",
                text: "SL. No",
                sort: true,
                // eslint-disable-next-line react/display-name
                formatter: (cell, prod, index) => index + 1,
              },
              {
                dataField: "country",
                text: "Country",
                sort: true,
                formatter: (cell, prod, index) => prod.country !== undefined && prod.country !== null ? prod.country.country : '',
              },
              {
                dataField: "state",
                text: "State",
                sort: true,
                formatter: (cell, prod, index) => prod.state !== undefined && prod.state !== null? prod.state.name : '',
              },
        
      
              {
                dataField: "name",
                text: "Area",
                sort: true,
              
              },
              {
                dataField: "operation",
                text: "Operation",
                sort: true,
                formatter: (cell, prod, index) =>
                  prod.operation == true ? <p>ON</p> : <p>OFF</p>,
              },
              {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                // eslint-disable-next-line react/display-name
                formatter: (cell, prod, index) => (
                  <>
                    <div className="d-flex gap-3">
                      <Link to="#" className="text-success">
                        <i
                          className="mdi mdi-pencil font-size-18"
                          id="edittooltip"
                          onClick={e => changeTable(e, "Edit", 3, prod)}
                        />
                      </Link>
                     
                      <Link to="#" className="text-danger">
                        <i
                          className="mdi mdi-eye font-size-18"
                          id="deletetooltip"
                          onClick={e => changeTable(e, "View", 3, prod)}
                        />
                      </Link>
                    </div>
                  </>
                ),
              },
            ],
            inputType: [
              { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
              { title: 'Area', name: 'name', type: 'text', value: '', disabled: true, required: '' },
              { title: 'Operation Country', name: 'operation', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'operation', value: true }, { title: 'In Active', name: 'operation', value: false }] }
            ],
            isLoading: true,
     
          },
    ])

    useEffect(async () => {
        const cardElements = [...cardElement]
       
        for (let i = 0; i < cardElements.length; i++) {
            
            await getPublishLocation(cardElements[i].name, cardElements[i].status).then(async result => {
                for (let j = 0; j < cardElements[i].inputType.length; j++) {

                    if (cardElements[i].inputType[j].type === 'multiple' || cardElements[i].inputType[j].type === 'select') {
                        await getDropDownOperational(cardElements[i].inputType[j].table, cardElements[i].inputType[j].status).then(results => {
                        
                            for (let k = 0; k < results.data.length; k++) {
                                if (cardElements[i].inputType[j].filterType === 'yes') {
                                    let data = {
                                        label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElements[i].inputType[j].outputDropDown)],
                                        value: results.data[k]._id,
                                    }
                                    cardElements[i].inputType[j].value.push(data)
                                } else {
                                    let data = {
                                        label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElements[i].inputType[j].outputDropDown)],
                                        value: results.data[k]._id,
                                    }
                                    cardElements[i].inputType[j].value.push(data)
                                }


                            }
                        })
                    }
                }
                cardElements[i].isLoading = false;
                cardElements[i].total =  result.data.filter(g => g.operation == true).length; 
            
                cardElements[i]['Operational'] = result.data.filter(g => g.operation == true).length
                cardElements[i]['Blocked'] = result.data.filter(g => g.operation == false).length

            })

        }
        console.log(cardElements)
        setCardElement([...cardElements])
        setIsLoading(false)
    }, [])
    const changeTable = async (e, type, index, prod) => {
        if(type === true || type  == false){
            setOperationTable('Table')
        }else{
            setOperationTable(type)
        }
        setIndexValue(index)
      
        const cardElementList = [...cardElement]
        setAddButton(cardElementList[index].addButton)
        setTitle(cardElementList[index].title)
        setColumns([...cardElementList[index].HeaderColumns])
        setInputType([...cardElementList[index].inputType])
        if (type == true || type == false) {
            setIsLoading(true)
           
           
            await getPublishLocation(cardElementList[index].name, type).then(result => {
                if (result.success) {
                    setData(result.data)
                    setIsLoading(false)
                } else {
                    setData([])
                    setIsLoading(false)
                }

            })
           
        } else if (type == 'Table') {
            setIsLoading(true)

     
            await getPublishLocation(cardElementList[index].name, cardElementList[index].status).then(result => {
                console.log(result)
                if (result.success) {
                    setData(result.data)
                    setIsLoading(false)
                } else {
                    setData([])
                    setIsLoading(false)
                }
            })
           
        } else {
            setIsLoading(true)
             await getPublishLocation(cardElementList[index].name,prod._id).then(result => {
                console.log(result)
                if (result.success) {
                    setSingleItem({ ...result.data })
                    setIsLoading(false)
                } else {
                    setSingleItem({})
                    setIsLoading(false)
                }

            })

           
        } 
    }


    const postData = (e, values) => {
        const cardElementList = [...cardElement]
        if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
            values[Object.keys(values).filter(k => k == 'status')] = false
        }
        if (operationsTable == 'Add') {
            delete values['_id'];
        }
       
        postPublishPincode(isEmpty(values), cardElementList[indexValue].name, operationsTable).then(result => {
            if (result.success) {
              setIsLoading(true)
                setColumns([...cardElementList[indexValue].HeaderColumns])
                setOperationTable('Table')
                getPublishLocation(cardElementList[indexValue].name, status).then(result => {
                    if (result.success) {
                        setData(result.data)
                        setIsLoading(false)
                    } else {
                        setData([])
                        setIsLoading(false)
                    }

                })
            } else {
                alert('Hello')
            }
        })
    }
    console.log(cardElement)
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Published Area| {url.title}</title>
                </MetaTags>
                <Container fluid>
                    {
                        operationsTable == '' ?
                            <Breadcrumbs
                                title={"Dynamic Page Structure"}
                                breadcrumbItem={"Published Area"}
                                bredcumlink={"dynamic-page-structure"}
                            />
                            : <Breadcrumbs
                                title={'Publish Area'}
                                breadcrumbItem={title}
                                bredcumlink={'published-area'}
                            />
                    }

                    <Row>
                        {

                            operationsTable == '' ?
                                cardElement.map((d, index) => (
                                    <Col sm={4} md={3} key={index}>
                                        {
                                            isLoading ?

                                                <UiLoading />

                                                :
                                                <DynamicCardElementwithEvent
                                                    data={d}
                                                    changeTable={(e, type,) => changeTable(e, type, index)}
                                                    addButton={d.addButton}
                                                />
                                        }

                                    </Col>
                                ))
                                :
                                operationsTable == 'Table' ?
                                    <Card>
                                        {
                                            isLoading ?
                                                <UiLoading />
                                                : <ReactTable
                                                    title={title}
                                                    datas={data}
                                                    changeTable={(e, type, title) => changeTable(e, type, indexValue)}
                                                    columns={columns}
                                                    addButton={addButton}
                                                    selctdropDownList={(e,rowData)=>selctdropDownList(e,rowData,title)}
                                                    searchFilter={searchFilter}
                                                    selectSearchData={(e,rowData)=>selectSearchData(e,rowData,title)}
                                                    searchValueLists={(typelist,typedata)=>searchValueLists(typelist,typedata)}
                                                    isLoading={isLoading}
                                                />
                                        }

                                    </Card>
                                    :
                                    operationsTable == 'Add' || operationsTable == 'Edit' || operationsTable == 'Deleted' || operationsTable == 'View' ?
                                        <>
                                            {
                                                isLoading ?
                                                    <UiLoading />
                                                    :
                                                    <InputFormElement
                                                        operationsTable={operationsTable}
                                                        singleItem={singleItem}

                                                        bredcumlink={'catalog-list'}
                                                        viewIcon={false}
                                                        filetype='single'

                                                        title={title}
                                                        changeTable={(e, type) => changeTable(e, type, indexValue)}
                                                        inputType={inputType}
                                                        postData={postData}
                                                     
                                                    />
                                            }
                                        </>
                                        : ''
                        }

                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}



export default withTranslation()(PublishedLocationList)
