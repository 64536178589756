import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { url } from "routes"
import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"
import { getEvent,getEventStatus,postEvent,getPageContent,getPageGallery,postPageContent,postPageGallery} from "data/event"
import { getpageStructureLinkStatus,getpageStructurLink,postPageStructureLink} from "data/pageStructureLink"
import { getpageStructure } from "data/pageStructure"
import MultiRowTable from "UI/Multirow/MultiRowTable"
import InputFormElement from "UI/InputFormElement"
import UiLoading from "UI/loading"
import SubGalleryCardElementWithEvent from "UI/SubGalleryCardElementWithEvent"
// import { getpageStructurLink } from "data/pageStructureLink"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { isEmpty } from "data/middlefunctions"
import { Link } from "react-router-dom"
import { getEmployee } from "data/systemadmin/employee"
import CustomeTab from "UI/CustomTab"
import CustomImageTab from "UI/CustomImageTab"
const EventList = () => {
  const [saveData,setSaveData] = useState(false)
  const [page,setPage] = useState()
  const [typeofpage,settypeofpage] = useState()
  const [allPageLoading,setAllPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  const [addButton,setAddButton]=useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({pagedescription:'',status:true})
  const [productStructure,setProductStructure] = useState([])
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [pageDescription,setPageDescription] = useState([{name:'goapla'}])
  const [inputElemtnType,setInputElemtnType] = useState('')
  const [inputType, setInputType] = useState([
    { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
    { title: 'dynamicpage', name: 'uploadtype', type: 'hidden', value: '', disabled: '' },
    { title: 'page', name: 'page', type: 'hidden', value: 'page', disabled: '' },
    { title: 'typeofpage', name: 'typeofpage', type: 'hidden', value: 'typeofpage', disabled: '' },
    { title: 'Title', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 6 },
    { title: 'Price', name: 'price', type: 'text', value: '', disabled: '', required: true, gride: 6 },
    { title: 'Short Description', name: 'sortdescription', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
    { title: 'Feature', name: 'feature', type: 'textarea', value: '', disabled: '', required: '', gride: 6 },
    { title: 'Extra Feature', name: 'extrafeature', type: 'textarea', value: '', disabled: '', required: '', gride: 6 },
    { title: 'Add On', name: 'addon', type: 'textarea', value: '', disabled: '', required: '', gride: 6 },
    { title: 'Extra Package', name: 'extrapackage', type: 'multiple', value: [], outputDropDown: 'name', outputData: getEvent, table: 'Different Description Type', status: "Drop Down", required: true, gride: 4 },
    { title: 'Optional Package', name: 'optionalpackage', type: 'multiple', value: [], outputDropDown: 'name', outputData: getEvent, table: 'Different Description Type', status: "Drop Down", required: true, gride: 4 },
    { title: 'Order', name: 'order', type: 'text', value: '', disabled: '', required: '', gride: 4 },
    { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: '', gride: 12 },
    { title: 'Dependancy Page', name: 'pagedependencytype', type: 'select', value: [], outputDropDown: 'name', outputData: getpageStructure, table: 'Different Page Design', status: "All", required: true, gride: 4 },
    { title: 'Image', name: 'image', type: 'file', value: [], single: true,label:"Image", uploadtype: 'page' },
    { title: 'Vat Type', name: 'vattype', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'In Clude Vat', name: 'vattype', value: true }, { title: 'Exclude Vat', name: 'vattype', value: false }] },
    { title: 'Price Type', name: 'pricetype', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'One Time', name: 'pricetype', value: 'One Time' },{ title: 'Monthly', name: 'pricetype', value: 'Monthly' }, {  title: 'Quaterly', name: 'pricetype', value: 'Quaterly' }, {title: 'Yearly', name: 'pricetype', value: 'Yearly'}] },
    { title: 'Type Of Service', name: 'typeofservice', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Individual', name: 'typeofservice', value: true }, { title: 'Dependent', name: 'typeofservice', value: false }] },
    { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] },
    { title: '', name: 'uploadtype', type: 'hidden', value: 'page', disabled: '' }
  ])
  const [sortTreeStatus,setSortTreeStatus] = useState(false)
  const [cardElement, setCardElement] = useState([])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    await getpageStructure('Different Page Design','All').then(async result=>{
      const d=[]
      if(result.success){
        for(let i=0; i < result.data.length;i++){
          if(result.data[i].event == true){
            await getpageStructureLinkStatus(result.data[i]._id,'product').then(results=>{
              d.push({
                
                _id: result.data[i]._id,
                name: result.data[i].name,
                title: result.data[i].name,
                total:results.total,
                active:results.active,
                inactive:results.inactive,
                getStatusData: getEventStatus,
                getData: getEvent,
                getPostData:postEvent,
                statusList:'All',
                isLoading:false,
                addButton: false,
              })
            })
            
          }
            setCardElement(d)
          }
          
          
          setAllPageLoading(false)
        }
    })
   
    
    setIsLoading(false)
  
  }, [])
  const subchangeTable = async (e,prod) => {
  
    setIsLoading(true)
    let d=[]
    setOperationTable('Sub Card')
    await getpageStructurLink(prod._id,true).then(async results=>{
     
    for(let i=0; i < results.data.length;i++){
      await getEventStatus(`${results.data[i]._id}-${results.data[i].pagedescriptionid._id}`, 'all').then(result=>{
        console.log(result)
        d.push({
          pagelink:prod._id,
          _id: results.data[i]._id,
          name: results.data[i].name,
          title: results.data[i].name,
          productstructure:results.data[i].productstructure,
          total:result.total,
          active:result.active,
          inactive:result.inactive,
          getStatusData: getEventStatus,
          getData: getEvent,
          getPostData:postEvent,
          statusList:'All',
          HeaderColumns: [
            {
              dataField: "_id",
              text: "SL. No",
              sort: true,
              // eslint-disable-next-line react/display-name
              formatter: (cell, prod, index) => index + 1,
            },
            {
              dataField: "name",
              text: "Name",
              sort: true,
            },
            {
              dataField: "pagedescriptionlink",
              text: "Description Details",
              sort: true,
              formatter: (cell, prod, index) =>
                prod.pagedependencytype != undefined && prod.pagedependencytype != null &&  prod.pagedependencytype.eventstructurelink != null && prod.pagedependencytype.eventstructurelink != undefined && prod.pagedependencytype.eventstructurelink.length !== 0  ? <Button
                
                color="success"
                onClick={e => pageDescriptionList(e,prod._id,parseInt(d.length-1),'Event Content',prod.name)}
              >
             Content
              </Button>  : 'No Content',
              
            },
             {
              dataField: "action",
              isDummyField: true,
              text: "Action",
              // eslint-disable-next-line react/display-name
              formatter: (cell, prod, index) => (
                <>
                  <div className="d-flex gap-3">
                    <Link to="#" className="text-success">
                      <i
                        className="mdi mdi-pencil font-size-18"
                        id="edittooltip"
                        onClick={e => changeTable(e, "Edit", i,results.data[i].name, prod,results.data[i],prod.name)}
                      />
                    </Link>
                    <Link to="#" className="text-danger">
                      <i
                        className="mdi mdi-delete font-size-18"
                        id="deletetooltip"
                        onClick={e => changeTable(e, "Deleted", i,results.data[i].name, prod,results.data[i],prod.name)}
                      />
                    </Link>
                    <Link to="#" className="text-danger">
                      <i
                        className="mdi mdi-eye font-size-18"
                        id="deletetooltip"
                        onClick={e => changeTable(e, "View", i,results.data[i].companyname, prod,results.data[i],prod.name)}
                      />
                    </Link>
                  </div>
                </>
              ),
            },

           
           
          ],
  
          isLoading:false,
          addButton: true,
        })
      })
    }
    setCardElement([...d])
    
    setIsLoading(false)
     
    })
    

  
  }
  const changeTable = async (e, type, index, title,prod,c) => {
    
    setIsLoading(true)
    e.preventDefault()
    setIndexValue(index)
   
    setPage(prod._id)
    settypeofpage(prod.pagelink)
    const inputLists =[...inputType]
    if (type == 'Table' || type == true || type == false) {
     const cardElementList = [...cardElement]
     setAddButton(cardElementList[cardElementList.findIndex(d=> d.title == title)].addButton)
     setTitle(cardElementList[cardElementList.findIndex(d=> d.title == title)].title)
     setSortTreeStatus(cardElementList[cardElementList.findIndex(d=> d.title == title)].title)
      setColumns(cardElementList[cardElementList.findIndex(d=> d.title == title)].HeaderColumns)
      setOperationTable('Table')
    await cardElementList[cardElementList.findIndex(d=> d.title == title)].getData(cardElementList[cardElementList.findIndex(d=> d.title == title)]._id,type == 'Table'? "All" :type).then(result => {
       
        if (result.success) {
          setPageDescription(result.description)
          setData(result.data)
          setIsLoading(false)
        } else {
          setData([])
          setIsLoading(false)
        }
      })
     
    
    }else if (type == 'Add') {
      setTitle(title)
      for(let i=0;i<inputLists.length;i++){
        if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
          inputLists[i].value=[]
          await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(results => {
            if(inputLists[i].filtertype){
              console.log(results.data)
              for (let k = 0; k < results.data.length; k++) {
               
                if(prod.productstructure.includes(results.data[k]._id)){
                  let data = {
                      label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                      value: results.data[k]._id,
                  }
                  inputLists[i].value.push(data)
                }
              }
            }else{
              for (let k = 0; k < results.data.length; k++) {
             
                let data = {
                    label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                    value: results.data[k]._id,
                }
                inputLists[i].value.push(data)
              }
            }
              
              })
                
           
            }
        }
        singleItem.page=prod._id
        singleItem.typeofpage=prod.pagelink
        singleItem.status =true
        console.log(singleItem)
        setSingleItem({...singleItem})
        setOperationTable('Add')
        setInputType([...inputLists])
        setIsLoading(false)   
    }else{
    
      setInputType([...inputLists])
      if(type == 'Edit' || type == 'View' || type == 'Deleted'){
       
        await getEvent(prod.name,prod._id).then(async result=>{
         
          for(let i=0;i<inputLists.length;i++){
        
            if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
              inputLists[i].value=[]
              await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(results => {
                if(inputLists[i].filtertype){
              console.log(results.data)
              for (let k = 0; k < results.data.length; k++) {
                 
                if(prod.productstructure.includes(results.data[k]._id)){
                  let data = {
                      label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                      value: results.data[k]._id,
                  }
                  inputLists[i].value.push(data)
                }
              }
            }else{
              for (let k = 0; k < results.data.length; k++) {
             
                let data = {
                    label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                    value: results.data[k]._id,
                }
                inputLists[i].value.push(data)
              }
            }
                  })
                  setSingleItem(result.data)
                  setInputType([...inputLists])
                  setOperationTable(type)
                 
                }else{
                  setSingleItem(result.data)
                  setInputType([...inputLists])
                  setOperationTable(type)
                  
                }
            }
        
   
        })
      }
      setIsLoading(false)
    }
  }
  

  const postData = (e, values) => {
    setSaveData(true)
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
 
    if (values.name !== undefined) {
      var trimValue =  values.name.trim().replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['slug'] = trimValue.replace('--','-')
      values['metatitle'] = values.name.trim();
      if(operationsTable == 'Add'){

        values['pagename'] = trimValue.replace('--','-');
      }
      }
    if (values.title !== undefined) {
      var trimValue =  values.name.trim().replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['slug'] = trimValue
      values['metatitle'] = values.title.trim();
      if(operationsTable == 'Add'){
      values['pagename'] = trimValue
      }
      
    }
   
   
    cardElementList[cardElementList.findIndex(d=> d.title == title)].getPostData(isEmpty(values),cardElementList[cardElementList.findIndex(d=> d.title == title)]._id, operationsTable).then(async result => {
     
      if (result.success) {

       
        if(operationsTable == 'Add'){

          setOperationTable('Table')
          
          await cardElement[cardElementList.findIndex(d=> d.title == title)].getData(values.page, "All").then(results => {
           
              if (results.success) {
                setColumns(cardElementList[cardElementList.findIndex(d=> d.title == title)].HeaderColumns)
                setData(results.data)
                setIsLoading(false)
              } else {
                setData([])
                setIsLoading(false)
              }
            })
        }else{
          alert("Data Update Successfully")
          setSaveData(false)

        }
         
      } else {
        alert('Hello')
      }
    })
  }
  const pageDescriptionList=async(e,id,index,typeData,name)=>{
    setIsLoading(true)
    setTitle(name)
    await getPageContent(id).then((result)=>{
      console.log(result)
      if(result.success){
        setData(result.data)
        setSingleItem(result.page)
        setOperationTable('Page Description')
        setIsLoading(false)
      }else{
        setData([])
        setSingleItem({})
        setOperationTable('Page Description')
        setIsLoading(false)
      }
    })
  }
  const submitFormData=async(e,data)=>{
    e.preventDefault()
    console.log(data)
    setSaveData(true)
    await postPageContent(data).then(result=>{
      if(result.success){
        alert("Data Update Successfully")
        setSaveData(false)
      }else{
        alert("Data Update Successfully")
        setSaveData(false)
      }
    })
  }
  const pageGalleryList=async(e,id,index,typeData)=>{
    console.log("demo")
    setIsLoading(true)
    await getPageGallery(id).then((result)=>{
      console.log(result)
      if(result.success){
        setData(result.data)
        setSingleItem(result.single)
        setOperationTable("Gallery")
        setIsLoading(false)
      }else{
        setData([])
        setSingleItem({})
        setOperationTable("Gallery")
        setIsLoading(false)
      }
    })
  }
  const submitImageData=async(e,data)=>{
    console.log("hello")
    await postPageGallery(data).then(result=>{
      
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Product List | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
              <Breadcrumbs
                title={"Product Dashboard"}
                breadcrumbItem={"Product List"}
                bredcumlink={"school-dashboard"}
              />
              : <Breadcrumbs
                title={'Product List'}
                breadcrumbItem={title}
                bredcumlink={'all-event'}
              />
          }

          <Row>
            {
              operationsTable == 'Sub Card' ?
              isLoading?
              <UiLoading cardElementOption={!isLoading} />:
              cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}> 
                
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type) => changeTable(e, type,indexValue,d.name,d )}
                          addButton={d.addButton}
                          isLoading={isLoading}
                        />
                  </Col>
                ))
                :
              operationsTable == 'Card' ?
              isLoading?
              <UiLoading cardElementOption={!isLoading} />:
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}> 
                
                        <SubGalleryCardElementWithEvent
                          data={d}
                          changeTable={(e,) => subchangeTable(e,d)}
                          addButton={d.addButton}
                          isLoading={isLoading}
                        />
                  </Col>
                ))
                :
                operationsTable == 'Gallery' ?
                  
                  isLoading ?
                   <UiLoading cardElementOption ={!isLoading ?true :false}/>
                :
                <CustomImageTab  data={data} singleItem={singleItem} title={title} submitFormData={(e,data)=>submitImageData(e,data)}/>
                
                :operationsTable == 'Page Description' ?
                  
                isLoading ?
                 <UiLoading cardElementOption ={!isLoading ?true :false}/>
              :
              <CustomeTab  data={data} singleItem={singleItem} saveData={saveData} submitFormData={(e,data)=>submitFormData(e,data)}/>
              
              :
                operationsTable == 'Table' ?
                isLoading?
                <UiLoading cardElementOption={!isLoading} />
                :
                     <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type) => changeTable(e, type, indexValue,title,{page:page,typeofpage:typeofpage})}
                          columns={columns}
                          addButton={false}
                          
                        />
                :
              
               isLoading?
          <UiLoading cardElementOption={!isLoading} />
          :
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'InformationPage'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type) => changeTable(e, type, indexValue,title,{page:page,typeofpage:typeofpage})}
                            inputType={inputType}
                            postData={postData}
                            isLoading={isLoading}
                            saveData={saveData}
                          />
                      
               
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}


export default EventList
