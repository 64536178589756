import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

import { deleteUploadFile, postUploadFile } from "data/fileuplaod"
import { url } from "routes"

const CustomImageTab = ({data,singleItem,submitFormData,title,saveData}) => {
   console.log(title)
    const [datas,setDatas]=useState(data)
    const [customActiveTab, setcustomActiveTab] = useState(0)
    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
          setcustomActiveTab(tab)
        }
      }
    
      const uploadImage = async (e, name, type) => {
        const d =[...datas]
        console.log(d[type][name]) 
         for (let i = 0; i < e.target.files.length; i++) {
            await postUploadFile(e.target.files[i], 'page').then(result => {
              if (result.success) {
               console.log(result.photo)
                d[type][name].push(result.photo)
              
              }
            })
          }
        
          setDatas([...d])
       
      }
      const deleteUploadFiles = (e, ind,i) => {
        const d =[...datas]
        d[i]['photo']
        const filteD  =  d[i]['photo'].filter((d,l)=> l !==ind);
        deleteUploadFile(d[i]['photo'][ind],'page').then(result=>{
          console.log(result)
          if(result.success){
        d[i]['photo'] = filteD;
        setDatas([...d])
        }else{

        }
     })
       
      }


    return(
        <Card>
<CardBody>


  <Nav tabs className="nav-tabs-custom nav-justified">
    {
       datas !== null && datas !== undefined && datas.map((d,i)=>(
            <NavItem key={i}>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === i,
              })}
              onClick={() => {
                toggleCustom(i)
              }}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">{d.title}</span>
            </NavLink>
          </NavItem>
        ))
    }
   
    
  </Nav>

  <TabContent
    activeTab={customActiveTab}
    className="p-3 text-muted"
  >
   {
       datas !== null && datas !== undefined && datas.map((d,i)=>(
        <TabPane tabId={i} key={i}>
          <Row>
          <h6>{d.title}-{title}</h6>

            <CardText className="mb-0">
              {
              <>
              <label>Video Link</label>
               <input
                className="form-control"
                type='text'
                value={d.video}
               />
                <label>Image</label>
                <input
                className="form-control"
                type='file'
                onChange={e => uploadImage(e,'photo',i)}
                multiple={true}
               />
                  <Row>
                 {

                   d['photo'].map((d, index) => (
       <Col lg={2} key={index}>
         <img
           className="rounded-circle avatar-xl"
           width="200"
           src={`${url.commonUrls}/image/page/${d}`}
         />
         <i
           className="mdi mdi-delete font-size-18"
           id="deletetooltip"
           onClick={e => deleteUploadFiles(e, index, i,'page')}
           style={{ position: "relative", top: "-39px" }}
         />
       </Col>
     ))
                   }
                  </Row>
                </>
               
               
              }
            <br/>
            <br/>
           
              <Row>
                <Col lg={11}></Col>

                <Col lg={1}>
                  <div className="mb-3">
                    <Button                      
                      type="submit"
                      color="success" onClick={(e)=>submitFormData(e,datas)} >
                     Save
                    </Button>
                  </div>
                </Col>
              </Row>
            
            </CardText>
          </Row>
         
      </TabPane>
       ))
  }
    
   
  </TabContent>
</CardBody>
</Card>
    )
}
CustomImageTab.propTypes = {
  saveData: PropTypes.bool,
    title: PropTypes.string,
    submitFormData: PropTypes.func,
    data: PropTypes.array,
    singleItem: PropTypes.object
}
export default CustomImageTab