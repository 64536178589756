import PropTypes from "prop-types"
import React from "react"
import MetaTags from "react-meta-tags"
import { Button, Card, CardBody, Col, Media, Row } from "reactstrap"

import { Link } from "react-router-dom"
import UiLoading from "UI/loading"

const FundingCard = ({ data, changeTable, addButton }) => {
  const reports = [
    { title: "Total Donor", iconClass: "bx-copy-alt", description: "1,236" },
    { title: "Fund Collection", iconClass: "bx-archive-in", description: "$35, 723" },
  ]
  return (
    <Card>
      <CardBody>
        {data.isLoading ? (
          <UiLoading cardElementOption={data.isLoading} />
        ) : (
          <>
            <h1 style={{ textAlign: "center", fontSize: "15px" }}>
              {data.title}
            </h1>
            <div className="text-center">
              <h1 style={{ textAlign: "center", fontSize: "30px" }}>
                {data.total}
              </h1>
              <Row>
                {reports.map((report, key) => (
                  <Col md="6" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
              {/* <div
                style={{
                  marginBottom: "15%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  type="button"
                  color="info"
                  className="btn-rounded  mb-2 me-2"
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    border: "none",
                  }}
                  onClick={e => changeTable(e, true, data.name)}
                >
                  Active ({data.active})
                </Button>

                <Button
                  type="button"
                  color="info"
                  className="btn-rounded  mb-2 me-2"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                  }}
                  onClick={e => changeTable(e, false, data.title)}
                >
                  In-Active ({data.inactive})
                </Button>
              </div> */}

              {addButton !== undefined ? (
                addButton ? (
                  <Button
                    type="button"
                    color="info"
                    className="btn-rounded  mb-2 me-2"
                    onClick={e => changeTable(e, "Add", data.name)}
                    style={{
                      backgroundColor: "#556ee6",
                      color: "white",
                      border: "none",
                    }}
                  >
                    <i className="mdi mdi-plus me-1" />
                    Add
                  </Button>
                ) : (
                  ""
                )
              ) : (
                <Button
                  type="button"
                  color="info"
                  className="btn-rounded  mb-2 me-2"
                  onClick={e => changeTable(e, "Add", data.title)}
                  style={{
                    backgroundColor: "#556ee6",
                    color: "white",
                    border: "none",
                  }}
                >
                  <i className="mdi mdi-plus me-1" />
                  Add
                </Button>
              )}
              <Button
                type="button"
                color="info"
                className="btn-rounded  mb-2 me-2"
                onClick={e => changeTable(e, "Table", data.name)}
                style={{
                  backgroundColor: "#556ee6",
                  color: "white",
                  border: "none",
                }}
              >
                Table
              </Button>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  )
}

FundingCard.propTypes = {
  t: PropTypes.any,
  data: PropTypes.object,
  changeTable: PropTypes.func,
  title: PropTypes.string,
  addButton: PropTypes.bool,
}

export default FundingCard
