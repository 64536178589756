import React, { useState } from "react"
import PropTypes from 'prop-types'


import {
  Col,
  Row,
  Button
} from "reactstrap"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import MultiRow from "UI/Multirow"
import { postUploadFile } from "data/fileuplaod"


const MultiRowTable = ({fileUplaodType,deletedDataTable,addMoreTable,handleValidSubmits,inputList,changeTableRow,saveButton,type,multiRowheader,country,city}) => {

  const [headerTitle, setHeaderTitle] = useState(multiRowheader !== undefined ?multiRowheader:[])


  const [cityList,setCityList] = useState(city)
  const [citys,setCitys] = useState([])
  
  // const changeTableRow = (e, i,type) => {
  //   console.log(type)
  //   const data = [...addDataTable]
  //   if(data.length === parseInt(i) + 1 ){
  //     data.push({ ...inputList})
  //     data[i]['id'] =Date.now().toString()
  //   }
  //    if(type == 'servicetype' || type == 'country' || type == 'city' ){
  //     data[i][type] =e
  //     if(type == 'country'){
  //       const cityLists = [...cityList]
  //       const cityFilter = cityLists.filter(d=> e.value === d.country)
  //       setCitys(cityFilter)
  //     }
  //   }else{
  //   const { name, value } = e.target;
  //   if(name == 'file'){
  //     console.log(e.target.files[0])
  //     postUploadFile(e.target.files[0],fileUplaodType).then(result=>{
  //       if(result.success){
  //         data[i][name] =result.photo
  //     setAddDataTable([...data])

  //       }else{
  //         data[i][type] =''
  //       }
  //     })
  //   }else{
  //     data[i][name] =value;
  //     console.log(data)
  //     setAddDataTable([...data])
  //   }
   
  //   }
 
  
  // }
  const handleAddMreValidSubmit=(e,v)=>{
   
    handleValidSubmits(e,v)
  }
  return (
    <React.Fragment>
      <div>
       
          <Row style={{ justifyContent: "center" }}>
            <Col lg={12}>
            <Row>
       
      </Row>
      
        <MultiRow 
        headerTitle={headerTitle} 
        // tableTitle="Country Airport"  
        addRow={() => addRow()}
         addDataTable={addMoreTable} 
         deletedDataTable={(e, i) => deletedDataTable(e, i)} 
         changeTableRow={(e, i) => changeTableRow(e, i,inputList) }
        handleValidSubmit ={(e,v)=>handleAddMreValidSubmit(e,v)}
         opration={saveButton}
         type={type}
         citys={citys}
         country={country !== undefined ?country:[]}
       />
       
             
            </Col>
          </Row>
       
      </div>
    </React.Fragment>
  )
}
MultiRowTable.propTypes = {
  deletedDataTable:PropTypes.func,
  changeTableRow:PropTypes.func,
  inputList:PropTypes.object,
  addMoreTable :PropTypes.object,
  handleAddMreValidSubmit : PropTypes.func,
  handleValidSubmits:PropTypes.func,
  saveButton:PropTypes.string,
  type:PropTypes.string,
  multiRowheader:PropTypes.array,
  country:PropTypes.array,
  city:PropTypes.array,
  typeofInput:PropTypes.string,
  tabInput:PropTypes.string,
  singleItem:PropTypes.string,
  document:PropTypes.array,
  roll:PropTypes.array,
  fileUplaodType:PropTypes.string
}
export default MultiRowTable
