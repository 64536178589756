import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"


import { getCustomer,postCustomer,getCustomerStatus } from "data/customer"
import InputFormElement from "UI/InputFormElement"
import { associate, validation } from "constants/layout"
import UiLoading from "UI/loading"
const Customer = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [addButton,setAddButton] = useState(true)
  const [operationsTable, setOperationTable] = useState('')
  const [singleItem, setSingleItem] = useState({})
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [inputType, setInputType] = useState([])
  const [cardElement, setCardElement] = useState([
    {
      name: 'Customer',
      title: 'Customer',
       total:0,
      active:0,
      inactive:0,
      statusList:'All',
      getStatusData: getCustomerStatus,
      getData: getCustomer,
      getPostData:postCustomer,
    
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
  
        },
        {
          dataField: "name",
          text: "Email",
          sort: true,
        },
        {
          dataField: "name",
          text: "Mobile",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 0,prod)}
                  />  
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
       
       
      ],
      inputType: [
          { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
          { title: 'Episode Name', name: 'episodename', type: 'text', value: '', disabled: '', required: true },
          { title: 'Episode Start Date', name: 'episodestartdate', type: 'datetime-local', value: '', disabled: '', required: true, },
          { title: 'Episode End Date', name: 'episodeenddate', type: 'datetime-local', value: '', disabled: '', required: true, },
          { title: 'Episode Image', name: 'episodeimage', type: 'file', value: [],label:'Live Bulatine Image', disabled: '', required: true, },
          { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton:false,
  },   
  {
    name: 'Inquiry',
    title: 'Inquiry',
     total:0,
    active:0,
    inactive:0,
    statusList:'All',
    getStatusData: getCustomerStatus,
    getData: getCustomer,
    getPostData:postCustomer,
  
    HeaderColumns: [
      {
        dataField: "_id",
        text: "SL. No",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => index + 1,
      },
      {
        dataField: "name",
        text: "Name",
        sort: true,

      },
      {
        dataField: "name",
        text: "Email",
        sort: true,
      },
      {
        dataField: "name",
        text: "Mobile",
        sort: true,
      },
      {
        dataField: "",
        text: "Status",
        sort: true,
        formatter: (cell, prod, index) =>
          prod.status == true ? <p>ON</p> : <p>OFF</p>,
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => (
          <>
            <div className="d-flex gap-3">
              <Link to="#" className="text-success">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={e => changeTable(e, "Edit", 0,prod)}
                />
              </Link>
              <Link to="#" className="text-danger">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={e => changeTable(e, "Deleted", 0,prod)}
                />  
              </Link>
              <Link to="#" className="text-danger">
                <i
                  className="mdi mdi-eye font-size-18"
                  id="deletetooltip"
                  onClick={e => changeTable(e, "View", 0,prod)}
                />
              </Link>
            </div>
          </>
        ),
      },
     
     
    ],
    inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Episode Name', name: 'episodename', type: 'text', value: '', disabled: '', required: true },
        { title: 'Episode Start Date', name: 'episodestartdate', type: 'datetime-local', value: '', disabled: '', required: true, },
        { title: 'Episode End Date', name: 'episodeenddate', type: 'datetime-local', value: '', disabled: '', required: true, },
        { title: 'Episode Image', name: 'episodeimage', type: 'file', value: [],label:'Live Bulatine Image', disabled: '', required: true, },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
    ],
    isLoading: true,
    addButton:false,
},
  {
    name: 'Subcribers',
    title: 'Subcribers',
     total:0,
    active:0,
    inactive:0,
    statusList:'All',
    getStatusData: getCustomerStatus,
    getData: getCustomer,
    getPostData:postCustomer,
  
    HeaderColumns: [
      {
        dataField: "_id",
        text: "SL. No",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => index + 1,
      },
      {
        dataField: "name",
        text: "Name",
        sort: true,

      },
      {
        dataField: "name",
        text: "Email",
        sort: true,
      },
      {
        dataField: "name",
        text: "Mobile",
        sort: true,
      },
      {
        dataField: "",
        text: "Status",
        sort: true,
        formatter: (cell, prod, index) =>
          prod.status == true ? <p>ON</p> : <p>OFF</p>,
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => (
          <>
            <div className="d-flex gap-3">
              <Link to="#" className="text-success">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={e => changeTable(e, "Edit", 0,prod)}
                />
              </Link>
              <Link to="#" className="text-danger">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={e => changeTable(e, "Deleted", 0,prod)}
                />  
              </Link>
              <Link to="#" className="text-danger">
                <i
                  className="mdi mdi-eye font-size-18"
                  id="deletetooltip"
                  onClick={e => changeTable(e, "View", 0,prod)}
                />
              </Link>
            </div>
          </>
        ),
      },
     
     
    ],
    inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Episode Name', name: 'episodename', type: 'text', value: '', disabled: '', required: true },
        { title: 'Episode Start Date', name: 'episodestartdate', type: 'datetime-local', value: '', disabled: '', required: true, },
        { title: 'Episode End Date', name: 'episodeenddate', type: 'datetime-local', value: '', disabled: '', required: true, },
        { title: 'Episode Image', name: 'episodeimage', type: 'file', value: [],label:'Live Bulatine Image', disabled: '', required: true, },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
    ],
    isLoading: true,
    addButton:false,
},
 
  ])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    for(let i=0;i<cardElementStatus.length;i++){
        cardElementStatus[i].getStatusData(cardElementStatus[i].name,cardElementStatus[i].statusList).then((res) => {
        cardElementStatus[i].active = res.active
        cardElementStatus[i].inactive = res.inactive
        cardElementStatus[i].total = res.total
        cardElementStatus[i].isLoading = false
        setCardElement([...cardElementStatus])
      })
    }
  setIsLoading(false)
  }, [])
  const changeTable = async (e, type, titles, prod) => {
    console.log(type);
    setIsLoading(true)
    const cardElementList = [...cardElement]
    setAddButton(cardElementList.find(d => d.name === titles).addButton)
    setTitle(titles)
    if (type == true || type == false) {
      setColumns([...cardElementList.find(d => d.name === titles).HeaderColumns])
      setOperationTable('Table')
      setStatus(type)
      singleItem.status = type
      await getCustomer(titles, type).then(result => {
        if (result.success) {
          setData(result.data)
        } else {
          setData([])
        }

      })
      setIsLoading(false)
    } else if (type == 'Table') {

      setColumns(cardElementList.find(d => d.name === titles).HeaderColumns)
      setOperationTable('Table')
      singleItem.status = true
      setSingleItem({ ...singleItem })
      await getCustomer(titles, "All").then(result => {
        console.log(result)
        if (result.success) {
          setData(result.data)
        } else {
          setData([])
        }
      })
      setIsLoading(false)
    } else if (type == 'View' || type == 'Deleted' || type == 'Edit') {

      const dropDownData = [...cardElementList.find(d => d.name === titles).inputType]
      setInputType([...cardElementList.find(d => d.name === titles).inputType])
      setOperationTable(type)


      await getCustomer(titles, prod._id).then(result => {

        if (result.success) {
          for (let i = 0; i < dropDownData.length; i++) {

            if (dropDownData[i].type === 'select' || dropDownData[i].type === 'consType') {
              const filterDrop = [...dropDownData[i].value]
              result.data[dropDownData[i].name] = filterDrop.find(d => d.value === result.data[Object.keys(result.data).filter(k => k == dropDownData[i].name)])
            }
          }

          setSingleItem({ ...result.data })
        } else {
          setSingleItem({})
        }

      })

      setIsLoading(false)
    } else if (type == 'Add') {
      const AddSingle = {}

      setInputType([...cardElementList.find(d => d.name === titles).inputType])
      setOperationTable('Add')

      AddSingle.status = true;
      setSingleItem({ ...AddSingle })
      setIsLoading(false)
    }
  }


  const postData = (e, values) => {
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    postCustomer(values, title, operationsTable).then(result => {
      if (result.success) {
        setColumns([...cardElementList.find(d => d.name === title).HeaderColumns])
        setOperationTable('Table')
        getCustomer(title, status).then(result => {
          if (result.success) {
            setData(result.data)
          } else {
            setData([])
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  console.log(cardElement)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Customer | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == '' ?
              <Breadcrumbs
                title={"Citizen"}
                breadcrumbItem={"Subscriptions"}
                bredcumlink={"customer-dashboard"}
              />
              : <Breadcrumbs
                title={'Subcribers'}
                breadcrumbItem={title}
                bredcumlink={'customer'}
              />
          }

          <Row>
            {

              operationsTable == '' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}>
                    {
                      isLoading ?

                        <UiLoading />

                        :
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type, title) => changeTable(e, type, title)}
                          addButton={d.addButton}
                        />
                    }

                  </Col>
                ))
                :
                operationsTable == 'Table' ?
                  <Card>
                    {
                      isLoading ?
                        <UiLoading />
                        : <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type, title) => changeTable(e, type, title)}
                          columns={columns}
                          addButton={addButton}
                        />
                    }

                  </Card>
                  :
                  operationsTable == 'Add' || operationsTable == 'Edit' || operationsTable == 'Deleted' || operationsTable == 'View' ?
                    <>
                      {
                        isLoading ?
                          <UiLoading />
                          :
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'Customer'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type, title) => changeTable(e, type, title)}
                            inputType={inputType}
                            postData={postData}
                          />
                      }
                    </>
                    : ''
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default Customer
