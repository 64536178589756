import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { url } from "routes"
import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"
import { getPrice,getPriceStatus,postPrice} from "data/price"
import { getpageStructureLinkStatus,getpageStructurLink,postPageStructureLink} from "data/pageStructureLink"
import { getpageStructure } from "data/pageStructure"
import MultiRowTable from "UI/Multirow/MultiRowTable"
import InputFormElement from "UI/InputFormElement"
import UiLoading from "UI/loading"
import SubGalleryCardElementWithEvent from "UI/SubGalleryCardElementWithEvent"
// import { getpageStructurLink } from "data/pageStructureLink"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { isEmpty } from "data/middlefunctions"
import { Link } from "react-router-dom"
import { getEmployee } from "data/systemadmin/employee"
import CustomeTab from "UI/CustomTab"
import CustomImageTab from "UI/CustomImageTab"
const MasterPriceList = () => {
  var sum1='';
  var sum2='';
  var sum3='';
  var sum4='';
  var sum5='';
  var sum6='';
  var unitprice='';
  const [messageLoading,setMessagesLoading] = useState(false)
  const [messages,setMessages] = useState('')
  const [saveData,setSaveData] = useState(false)
  const [page,setPage] = useState()
  const [typeofpage,settypeofpage] = useState()
  const [allPageLoading,setAllPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  const [addButton,setAddButton]=useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({pagedescription:'',status:true})
  const [productStructure,setProductStructure] = useState([])
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [pageDescription,setPageDescription] = useState([{name:'goapla'}])
  const [inputElemtnType,setInputElemtnType] = useState('')
  const [inputType, setInputType] = useState([
    { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
    { title: 'dynamicpage', name: 'uploadtype', type: 'hidden', value: '', disabled: '' },
    { title: 'page', name: 'page', type: 'hidden', value: 'page', disabled: '' },
    { title: 'typeofpage', name: 'typeofpage', type: 'hidden', value: 'typeofpage', disabled: '' },
    { title: 'Title', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Short Description', name: 'sortdescription', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
    { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: '', gride: 12 },
    { title: 'Image', name: 'image', type: 'file', value: [], single: true,label:"Image", uploadtype: 'page' },
    { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] },
    { title: '', name: 'uploadtype', type: 'hidden', value: 'page', disabled: '' }
  ])
  const [sortTreeStatus,setSortTreeStatus] = useState(false)
  const [cardElement, setCardElement] = useState([])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
     getPrice('','All Table').then(result=>{
      console.log(result)
       if(result.success){
        setColumns([
          {
            dataField: "_id",
            text: "SL. No",
            sort: true,
          
            formatter: (cell, prod, index) => index + 1,
          },
          {
            dataField: "supplier",
            text: "Supplier",
            sort: true,
           
          },
          {
            dataField: "productcategory",
            text: "Parent Category",
            sort: true,
           
          },
          {
            dataField: "supplier",
            text: "Child Category",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${d.name}${(prod.categortdata.length-1) != ind ?'-':''}`) :'No Category'
            )
          },
          {
            dataField: "region",
            text: "Supply Region",
            sort: true,
           
          },
          {
            dataField: "name",
            text: "Package Name",
            sort: true,
           
          },
          {
            dataField: "customername",
            text: "Customer Type",
            sort: true,
           
          },

          {
            dataField: "modeofprice",
            text: "Plan Type",
            sort: true,
           
          },
          {
            dataField: "typeofmeter",
            text: "Type Of Meter",
            sort: true,
           
          },
          {
            dataField: "billingtype",
            text: "Biling Type",
            sort: true,
           
          },
          {
            dataField: "billingmode",
            text: "Biling Mode",
            sort: true,
           
          },
          {
            dataField: "start",
            text: "Start Date",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.start != undefined && prod.start != null ?prod.start.split('T')[0] :'No Start Date'
          )
           
          },
          {
            dataField: "end",
            text: "End Date",
            sort: true,
            formatter: (cell, prod, index) =>(
                prod.end != undefined && prod.end != null ?prod.end.split('T')[0] :'No End Date'
            )
           },
           {
            dataField: "unitrate",
            text: "G_Unit Rate",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ? prod.categortdata.map((d,ind)=> `${parseFloat(d.gunitrate).toFixed(2)}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
          },
          
          
          {
            dataField: "nightrate",
            text: "G_Night Rate",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${parseFloat(d.gnightrate).toFixed(2)}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
          },
          {
            dataField: "peakrate",
            text: "G_Peak Rate",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${parseFloat(d.gpeakrate).toFixed(2)}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
          },
          
         
          
          {
            dataField: "unitrate",
            text: "N_Unit Rate",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ? prod.categortdata.map((d,ind)=> `${parseFloat(d.unitrate).toFixed(2)}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
          },
          
          
          {
            dataField: "nightrate",
            text: "N_Night Rate",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${parseFloat(d.nightrate).toFixed(2)}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
          },
          {
            dataField: "peakrate",
            text: "N_Peak Rate",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${parseFloat(d.peakrate).toFixed(2)}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
          },
          {
            dataField: "annualunit",
            text: "Annual Unit",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${parseFloat(d.annualunit).toFixed(2)}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
          },
          {
            dataField: "consumptiontype",
            text: "Consumption Type",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${d.consumptiontype}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
          },
          {
            dataField: "dayconsumption",
            text: "Day/All time Usage",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${d.dayconsumption != undefined ? d.dayconsumption :0}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
          },
          {
            dataField: "nightconsumption",
            text: "Night Usage",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${d.nightconsumption != undefined ? d.nightconsumption :0}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
          },
          {
            dataField: "peakconsumption",
            text: "Peak Usage",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${d.peakconsumption != undefined ?d.peakconsumption:0}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
          },
          {
            dataField: "discount",
            text: "Discount",
            sort: true,
           
          },
          {
            dataField: "vat",
            text: "Total Unit Cost",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${d.sum3}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
          },
          {
            dataField: "vat",
            text: "Standing Charges",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${parseFloat(d.standingcharge).toFixed(2)}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
          },
          {
            dataField: "carbon",
            text: "Carbon Tax / Unit",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${d.carbon}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'

            )
          },
          {
            dataField: "carbon",
            text: "Toatal Carbon Tax",
            sort: true,
            formatter: (cell, prod, index) =>(
              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${d.sum6}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'

            )
          },
         
          {
            dataField: "carbon",
            text: "Total Price Ex. VAT",
            sort: true,
            formatter: (cell, prod, index) =>(

              prod.categortdata.length !=0  ?prod.categortdata.map((d,ind)=> `${d.sum3}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
                
          },
          {
            dataField: "carbon",
            text: "Vat",
            sort: true,
            formatter: (cell, prod, index) =>(

              prod.categortdata.length !=0 ?prod.categortdata.map((d,ind)=> `${d.vat}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
                
          },
         
          {
            dataField: "carbon",
            text: "Vat Total",
            sort: true,
            formatter: (cell, prod, index) =>(

              prod.categortdata.length !=0  ? prod.categortdata.map((d,ind)=> `${d.sum5}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
                
          },
          {
            dataField: "carbon",
            text: "Total Price. Inc Vat",
            sort: true,
            formatter: (cell, prod, index) =>(

              prod.categortdata.length !=0  ?prod.categortdata.map((d,ind)=> `${d.total}${(prod.categortdata.length-1) != ind ?'-':''}`) :'Data Is Revised'
            )
                
          },
       ])
        setOperationTable('Table')
        setData(result.data)
        setIsLoading(false)
       }else{
        setData([])
        setIsLoading(false)
       }

     })
  
  
}, [])

  const subchangeTable = async (e,prod) => {
  
    setIsLoading(true)
    let d=[]
    setOperationTable('Sub Card')
    await getpageStructurLink(prod._id,'All','Category').then(async results=>{
     console.log(results)
    for(let i=0; i < results.data.length;i++){
      await getPriceStatus(results.data[i]._id, 'all').then(result=>{
        console.log(result)
        d.push({
          pagelink:prod._id,
          _id: results.data[i]._id,
          name: results.data[i].name,
          title: results.data[i].name,
          productstructure:results.data[i].productstructure,
          total:result.total,
          active:result.active,
          inactive:result.inactive,
          getStatusData: getPriceStatus,
          getData: getPrice,
          getPostData:postPrice,
          statusList:'All',
          HeaderColumns: [
            {
              dataField: "_id",
              text: "SL. No",
              sort: true,
              // eslint-disable-next-line react/display-name
              formatter: (cell, prod, index) => index + 1,
            },
            {
              dataField: "packagename",
              text: "Supplier",
              sort: true,
              formatter: (cell, prod, index) => (
                prod.supplier != undefined && prod.supplier != null ? prod.supplier.name:'' 

              )
            },
            {
              dataField: "packagename",
              text: "Package",
              sort: true,
              formatter: (cell, prod, index) => (
                prod.pagelink != undefined && prod.pagelink != null ? prod.pagelink.name:'' 

              )
            },
            {
              dataField: "packagename",
              text: "Category",
              sort: true,
              formatter: (cell, prod, index) => (
                prod.category != undefined && prod.category != null ? prod.category.name:'' 

              )
            },
            {
              dataField: "typeofmeter",
              text: "Type Of Meter",
              sort: true,
              formatter: (cell, prod, index) => (
                prod.typeofmeter != undefined && prod.typeofmeter != null ? prod.typeofmeter.name:'' 

              )
            },
            {
              dataField: "typeofmeter",
              text: "Region",
              sort: true,
              formatter: (cell, prod, index) => (
                prod.region != undefined && prod.region != null ? prod.region.name:'' 

              )
            },
            {
              dataField: "typeofmeter",
              text: "Bill Mode",
              sort: true,
              formatter: (cell, prod, index) => (
                prod.billingmode != undefined && prod.billingmode != null ? prod.billingmode.name:'' 

              )
            },
            
            {
              dataField: "dayrate",
              text: "Day Rate",
              sort: true,
             
            },
            {
              dataField: "nightrate",
              text: "Night Rate",
              sort: true,
             
            },
            {
              dataField: "peakrate",
              text: "Peak Rate",
              sort: true,
             
            },
            {
              dataField: "peakrate",
              text: "Peak Rate",
              sort: true,
             
            },
            {
              dataField: "unitrate",
              text: "Total Price Exc.",
              sort: true,
              formatter: (cell, prod, index) =>{
                sum1 = prod.usage.consumptiontype == 'All Day' ? ((prod.dayrate/100) * (prod.annualunit * (prod.usage.dayconsumption/100))):
                prod.usage.consumptiontype == 'Night'? ((prod.dayrate/100)* (prod.annualunit * (prod.usage.dayconsumption)/100)) + ((prod.nightrate/100)* (prod.annualunit * (prod.usage.nightconsumption/100)))
                :  (parseFloat(prod.dayrate/100) * (prod.annualunit * (prod.usage.dayconsumption/100)))+ ( parseFloat(prod.nightrate/100)* prod.annualunit * (prod.usage.nightconsumption/100))+(parseFloat(prod.peakrate/100)* parseFloat(prod.annualunit * (prod.usage.peakconsumption/100)))
                return sum1
              }
            },
            {
              dataField: "peakrate",
              text: "Discount Unit Price",
              sort: true,
              formatter: (cell, prod, index) => (
                prod.discountvalue != undefined && prod.discountvalue != null ? prod.discountvalue:'' 

              )
            },
            {
              dataField: "dayrate",
              text: "Discount Dat Rate",
              sort: true,
              formatter: (cell, prod, index) => {
             prod.dayrate= (prod.dayrate)-(prod.dayrate * (prod.discountvalue/100))
                 return prod.dayrate
              }

              
            },
            {
              dataField: "nightrate",
              text: "Discount Night Rate",
              sort: true,
              formatter: (cell, prod, index) => {
             prod.nightrate= (prod.nightrate)-(prod.nightrate * (prod.discountvalue/100))
                 return prod.nightrate
              }

              
            },
            {
              dataField: "nightrate",
              text: "Discount Peak Rate",
              sort: true,
              formatter: (cell, prod, index) => {
             prod.peakrate= (prod.peakrate)-(prod.peakrate * (prod.discountvalue/100))
                 return prod.peakrate
              }

              
            },
            {
              dataField: "unitrate",
              text: "Total Price Exc.",
              sort: true,
              formatter: (cell, prod, index) =>{
                sum1 = prod.usage.consumptiontype == 'All Day' ? ((prod.dayrate/100) * (prod.annualunit * (prod.usage.dayconsumption/100))):
                prod.usage.consumptiontype == 'Night'? ((prod.dayrate/100)* (prod.annualunit * (prod.usage.dayconsumption)/100)) + ((prod.nightrate/100)* (prod.annualunit * (prod.usage.nightconsumption/100)))
                :  (parseFloat(prod.dayrate/100) * (prod.annualunit * (prod.usage.dayconsumption/100)))+ ( parseFloat(prod.nightrate/100)* prod.annualunit * (prod.usage.nightconsumption/100))+(parseFloat(prod.peakrate/100)* parseFloat(prod.annualunit * (prod.usage.peakconsumption/100)))
                return sum1
              }
            },
          ],
  
          isLoading:false,
          addButton: false,
        })
      })
    }
    setCardElement([...d])
    
    setIsLoading(false)
     
    })
    

  
  }
  const changeTable = async (e, type, index, title,prod,c) => {
    
    setIsLoading(true)
    e.preventDefault()
    setIndexValue(index)
   
    setPage(prod._id)
    settypeofpage(prod.pagelink)
    const inputLists =[...inputType]
    if (type == 'Table' || type == true || type == false) {
     const cardElementList = [...cardElement]
     setAddButton(cardElementList[cardElementList.findIndex(d=> d.title == title)].addButton)
     setTitle(cardElementList[cardElementList.findIndex(d=> d.title == title)].title)
     setSortTreeStatus(cardElementList[cardElementList.findIndex(d=> d.title == title)].title)
      setColumns(cardElementList[cardElementList.findIndex(d=> d.title == title)].HeaderColumns)
      setOperationTable('Table')
    await cardElementList[cardElementList.findIndex(d=> d.title == title)].getData(cardElementList[cardElementList.findIndex(d=> d.title == title)]._id,type == 'Table'? "All" :type).then(result => {
       console.log(result.data)
        if (result.success) {
          setPageDescription(result.description)
          setData(result.data)
          setIsLoading(false)
        } else {
          setData([])
          setIsLoading(false)
        }
      })
     
    
    }else if (type == 'Add') {
      setTitle(title)
      for(let i=0;i<inputLists.length;i++){
        if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
          inputLists[i].value=[]
          await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(results => {
            if(inputLists[i].filtertype){
              console.log(results.data)
              for (let k = 0; k < results.data.length; k++) {
               
                if(prod.productstructure.includes(results.data[k]._id)){
                  let data = {
                      label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                      value: results.data[k]._id,
                  }
                  inputLists[i].value.push(data)
                }
              }
            }else{
              for (let k = 0; k < results.data.length; k++) {
             
                let data = {
                    label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                    value: results.data[k]._id,
                }
                inputLists[i].value.push(data)
              }
            }
              
              })
                
           
            }
        }
        singleItem.page=prod._id
        singleItem.typeofpage=prod.pagelink
        singleItem.status =true
        console.log(singleItem)
        setSingleItem({...singleItem})
        setOperationTable('Add')
        setInputType([...inputLists])
        setIsLoading(false)   
    }else{
    
      setInputType([...inputLists])
      if(type == 'Edit' || type == 'View' || type == 'Deleted'){
       
        await getEvent(prod.name,prod._id).then(async result=>{
         
          for(let i=0;i<inputLists.length;i++){
        
            if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
              inputLists[i].value=[]
              await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(results => {
                if(inputLists[i].filtertype){
              console.log(results.data)
              for (let k = 0; k < results.data.length; k++) {
                 
                if(prod.productstructure.includes(results.data[k]._id)){
                  let data = {
                      label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                      value: results.data[k]._id,
                  }
                  inputLists[i].value.push(data)
                }
              }
            }else{
              for (let k = 0; k < results.data.length; k++) {
             
                let data = {
                    label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                    value: results.data[k]._id,
                }
                inputLists[i].value.push(data)
              }
            }
                  })
                  setSingleItem(result.data)
                  setInputType([...inputLists])
                  setOperationTable(type)
                 
                }else{
                  setSingleItem(result.data)
                  setInputType([...inputLists])
                  setOperationTable(type)
                  
                }
            }
        
   
        })
      }
      setIsLoading(false)
    }
  }
  

  const postData = (e, values) => {
    setIsLoading(true)
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
 
    if(values.name !== undefined) {
      values['slug'] = values.name.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.name;
    }
   
   
    cardElementList[cardElementList.findIndex(d=> d.title == title)].getPostData(isEmpty(values),cardElementList[cardElementList.findIndex(d=> d.title == title)]._id, operationsTable).then(async result => {
     
      if (result.success) {
       setOperationTable('Table')
        
        await cardElement[cardElementList.findIndex(d=> d.title == title)].getData(values.page, "All").then(results => {
         
            if (results.success) {
              setColumns(cardElementList[cardElementList.findIndex(d=> d.title == title)].HeaderColumns)
              setData(results.data)
              setIsLoading(false)
            } else {
              setData([])
              setIsLoading(false)
            }
          })
         
      } else {
        alert('Hello')
      }
    })
  }
  const pageDescriptionList=async(e,id,index,typeData,name)=>{
    setIsLoading(true)
    setTitle(name)
    await getPageContent(id).then((result)=>{
      console.log(result)
      if(result.success){
        setData(result.data)
        setSingleItem(result.page)
        setOperationTable('Page Description')
        setIsLoading(false)
      }else{
        setData([])
        setSingleItem({})
        setOperationTable('Page Description')
        setIsLoading(false)
      }
    })
  }
  const submitFormData=async(e,data)=>{
    e.preventDefault()
    console.log(data)
    await postPageContent(data).then(result=>{
      
    })
  }
  const pageGalleryList=async(e,id,index,typeData)=>{
    console.log("demo")
    setIsLoading(true)
    await getPageGallery(id).then((result)=>{
      console.log(result)
      if(result.success){
        setData(result.data)
        setSingleItem(result.single)
        setOperationTable("Gallery")
        setIsLoading(false)
      }else{
        setData([])
        setSingleItem({})
        setOperationTable("Gallery")
        setIsLoading(false)
      }
    })
  }
  const submitImageData=async(e,data)=>{
    console.log("hello")
    await postPageGallery(data).then(result=>{
      
    })
  }
  const selctdropDownList=async (e, rowData,title)=>{
    setSearchFilter(e)
    const cardElements = [...cardElement]
    setMessagesLoading(true)
    setMessages(`${e.label} Data Loading ...`)    
  await postMiscellaneous(isEmpty({datalist:rowData}), title, e.label).then(result => {
    console.log(result)
    if(result.success) {
      setMessages(`${e.label} Data Successfully ...`)   
      if(e.label == "Export" || e.label == "Sample"){
        window.location.href=`${url.commonUrls}/download/${result.data.fileName}`
      }else{
         setIsLoading(true) 

         setColumns([...cardElements.find(d => d.name === title).HeaderColumns])
          getMiscellaneous(title, status).then(result => {
            if (result.success) {
              setSearchFilter({})
              setData(result.data)
              setOperationTable('Table')
              setIsLoading(false)
            } else {
              setData([])
              setOperationTable('Table')
              setIsLoading(false)
            }
  
          })
       }
      } else {
        alert('Hello')
      }
    })
   
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Product List | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
              <Breadcrumbs
                title={"Dynamic Page Dashboard"}
                breadcrumbItem={"Product List"}
                bredcumlink={"dynamic-page-structure"}
              />
              : <Breadcrumbs
                title={'Product List'}
                breadcrumbItem={title}
                bredcumlink={'all-product'}
              />
          }

          <Row>
            {
              operationsTable == 'Sub Card' ?
              isLoading?
              <UiLoading cardElementOption={!isLoading} />:
              cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}> 
                
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type) => changeTable(e, type,indexValue,d.name,d )}
                          addButton={d.addButton}
                          isLoading={isLoading}
                        />
                  </Col>
                ))
                :
              operationsTable == 'Card' ?
              isLoading?
              <UiLoading cardElementOption={!isLoading} />:
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}> 
                
                        <SubGalleryCardElementWithEvent
                          data={d}
                          changeTable={(e,) => subchangeTable(e,d)}
                          addButton={d.addButton}
                          isLoading={isLoading}
                        />
                  </Col>
                ))
                :
                operationsTable == 'Gallery' ?
                  
                  isLoading ?
                   <UiLoading cardElementOption ={!isLoading ?true :false}/>
                :
                <CustomImageTab  data={data} singleItem={singleItem} title={title} submitFormData={(e,data)=>submitImageData(e,data)}/>
                
                :operationsTable == 'Page Description' ?
                  
                isLoading ?
                 <UiLoading cardElementOption ={!isLoading ?true :false}/>
              :
              <CustomeTab  data={data} singleItem={singleItem} submitFormData={(e,data)=>submitFormData(e,data)}/>
              
              :
                operationsTable == 'Table' ?
                isLoading?
                <UiLoading cardElementOption={!isLoading} />
                :
                <>
                 {
                   messageLoading ?  
                    <p style={{textAlign: "center",color: 'green',paddingTop: '16px'}}>{messages}</p>
                   :''

                 }
                   <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type) => changeTable(e, type, indexValue,title,{page:page,typeofpage:typeofpage})}
                          columns={columns}
                          addButton={false}
                          selctdropDownList={(e,rowData)=>selctdropDownList(e,rowData,indexValue)}
                        />
                             </>
                :
              
               isLoading?
          <UiLoading cardElementOption={!isLoading} />
          :
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'InformationPage'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type) => changeTable(e, type, indexValue,title,{page:page,typeofpage:typeofpage})}
                            inputType={inputType}
                            postData={postData}
                            isLoading={isLoading}
                            saveData={saveData}
                          />
                      
               
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}


export default MasterPriceList
