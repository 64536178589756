import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"


import { getBlog, getBlogStatus, postBlog } from "data/cms/blog"
import InputFormElement from "UI/InputFormElement"
import { associate, validation } from "constants/layout"
import UiLoading from "UI/loading"
import { getpageStructure } from "data/pageStructure"
import { getEmployee } from "data/systemadmin/employee"
import { isEmpty } from "data/middlefunctions"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import MultiRow from "UI/Multirow"
import MultiRowTable from "UI/Multirow/MultiRowTable"
const Blog = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [operationsTable, setOperationTable] = useState('')
  const [singleItem, setSingleItem] = useState({ status: true })
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [inputType, setInputType] = useState([])
  const [inputElemtnType, setInputElemtnType] = useState('')
  const [cardElement, setCardElement] = useState([
    {
      name: 'Main Page Contents',
      title: 'Main Page Contents',
      total: 0,
      active: 0,
      inactive: 0,
      statusList: 'All',
      getStatusData: getBlogStatus,
      getData: getBlog,
      getPostData: postBlog,

      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Job Name",
          sort: true,

        },


        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "",
          text: "Job Details",
          sort: true,
          formatter: (cell, prod, index) =>

            prod.mainpagedescriptionlink !== undefined && prod.mainpagedescriptionlink !== null ?
              <div className="d-flex gap-3">
                {
                  prod.mainpagedescriptionlink.map((d, index) =>
                    <Button
                      type="submit"
                      color="success"
                      key={index}
                      onClick={() => addDescription(prod._id, d._id, prod, d, 'Main Page Contents')}
                      disabled={d.descriptiontype == 'Blank' ? true : ""}
                    >
                      {d.name}
                    </Button>
                  )}


              </div>
              : 'No Description'
        },

      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'dynamicpage', name: 'uploadImages', type: 'hidden', value: '', disabled: '' },
        { title: 'pagelink', name: 'pagelink', type: 'hidden', value: 'pagelink', disabled: '' },
        { title: 'productdescription', name: 'productdescription', type: 'hidden', value: 'productdescription', disabled: '' },
        { title: `Name`, name: 'name', type: 'text', value: '', disabled: true },
        { title: 'Description', name: 'description', type: 'hidden', value: '', uploadImages: 'dynamicpage', multiple: true, disabled: '', required: true, gride: 12 },
      ],
      isLoading: true,
      addButton: false,
    },
    {
      name: 'Blog',
      title: 'Blog Master',
      total: 0,
      active: 0,
      inactive: 0,
      statusList: 'All',
      getStatusData: getBlogStatus,
      getData: getBlog,
      getPostData: postBlog,

      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Blog Name",
          sort: true,

        },


        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 'Blog', prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 'Blog', prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 'Blog', prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },


      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'metatitle', name: 'metatitle', type: 'hidden', value: 'metatitle', disabled: '' },
        { title: 'uploadType', name: 'uploadType', type: 'hidden', value: 'blog', disabled: '' },
        { title: 'slug', name: 'slug', type: 'hidden', value: 'slug', disabled: '' },
        { title: 'Blog Name', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Author', name: 'employee', type: 'select', value: [], outputDropDown: 'name', outputData: getEmployee, table: 'Employee', status: true, required: true, gride: 4 },
        { title: 'Publishing Date', name: 'publishdate', type: 'datetime-local', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Category', name: 'linkcategory', type: 'select', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Blog Category', status: true, required: true, gride: 12 },
        { title: 'Short Description', name: 'blogsort', type: 'textarea', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Image', multiple: true, name: 'image', type: 'file', value: [], label: 'Image', uploadType: 'blog', gride: 12 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: 'Blog Description',
      title: 'Blog Contents',
      total: 0,
      active: 0,
      inactive: 0,
      statusList: 'All',
      getStatusData: getBlogStatus,
      getData: getBlog,
      getPostData: postBlog,

      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Blog Name",
          sort: true,

        },


        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "",
          text: "Contents Details",
          sort: true,
          formatter: (cell, prod, index) =>

            prod.description !== undefined && prod.description !== null ?
              <div className="d-flex gap-3">
                {
                  prod.description.map((d, index) =>
                    <Button
                      type="submit"
                      color="success"
                      key={index}
                      onClick={() => addDescription(prod._id, d._id, prod, d, 'Blog Description')}
                    >
                      {d.name}
                    </Button>
                  )}


              </div>
              : 'No Description'
        },


      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'uploadImages', name: 'uploadType', type: 'hidden', value: 'blog', disabled: '' },
        { title: 'pagelink', name: 'pagelink', type: 'hidden', value: 'pagelink', disabled: '' },
        { title: 'productdescription', name: 'productdescription', type: 'hidden', value: 'productdescription', disabled: '' },
        { title: `Name`, name: 'name', type: 'text', value: '', disabled: true },
        { title: 'Description', name: 'description', type: 'hidden', value: '', uploadType: 'blog', multiple: true, disabled: '', required: true, gride: 12 },
      ],
      isLoading: true,
      addButton: false,
    },
    {
      name: 'Information',
      title: 'Information Master',
      total: 0,
      active: 0,
      inactive: 0,
      statusList: 'All',
      getStatusData: getBlogStatus,
      getData: getBlog,
      getPostData: postBlog,

      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Information Name",
          sort: true,

        },


        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 'Information', prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 'Information', prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 'Information', prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },


      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'metatitle', name: 'metatitle', type: 'hidden', value: '', disabled: '', required: true },
        { title: 'slug', name: 'slug', type: 'hidden', value: '', disabled: '', required: true },
        { title: 'Informatin Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: 'Information Description',
      title: 'Information Contents',
      total: 0,
      active: 0,
      inactive: 0,
      statusList: 'All',
      getStatusData: getBlogStatus,
      getData: getBlog,
      getPostData: postBlog,

      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Information Name",
          sort: true,

        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "",
          text: "Contents Details",
          sort: true,
          formatter: (cell, prod, index) =>

            prod.description !== undefined && prod.description !== null ?
              <div className="d-flex gap-3">
                {
                  prod.description.map((d, index) =>
                    <Button
                      type="submit"
                      color="success"
                      key={index}
                      onClick={() => addDescription(prod._id, d._id, prod, d, 'Information Description')}
                    >
                      {d.name}
                    </Button>
                  )}


              </div>
              : 'No Description'
        },


      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'uploadImages', name: 'uploadType', type: 'hidden', value: 'information', disabled: '' },
        { title: 'pagelink', name: 'pagelink', type: 'hidden', value: 'pagelink', disabled: '' },
        { title: 'productdescription', name: 'productdescription', type: 'hidden', value: 'productdescription', disabled: '' },
        { title: `Name`, name: 'name', type: 'text', value: '', disabled: true },
        { title: 'Description', name: 'description', type: 'hidden', value: '', uploadImages: 'dynamicpage', multiple: true, disabled: '', required: true, gride: 12 },
      ],
      isLoading: true,
      addButton: false,
    },
    {
      name: 'Poll',
      title: 'Poll Master',
      total: 0,
      active: 0,
      inactive: 0,
      statusList: 'All',
      getStatusData: getBlogStatus,
      getData: getBlog,
      getPostData: postBlog,

      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Poll Name",
          sort: true,

        },


        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 'Poll', prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 'Poll', prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 'Poll', prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },


      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'uploadType', type: 'hidden', name: 'uploadType', value: 'poll' },
        { title: 'metatitle', name: 'metatitle', type: 'hidden', value: 'metatitle', disabled: '', required: true },
        { title: 'slug', name: 'slug', type: 'hidden', value: 'slug', disabled: '', required: true, },
        { title: 'Poll Name', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 6 },
        { title: 'Category', name: 'pollcategory', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Poll Category', status: true, required: true, gride: 6 },
        { title: 'Start Date', name: 'pollopendate', type: 'datetime-local', value: '', disabled: '', required: true, gride: 4 },
        { title: 'End Date', name: 'pollclosedate', type: 'datetime-local', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Image', multiple: true, name: 'image', type: 'file', value: [], label: 'Image', uploadType: 'poll', gride: 4 },
        { title: 'Short Description', name: 'sortdescription', type: 'textarea', value: '', disabled: '', required: true },
        { title: 'Poll Status', name: 'polltatus', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Poll Open', name: 'polltatus', value: true }, { title: 'Poll Close', name: 'polltatus', value: false }] },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }

      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: 'Poll Description',
      title: 'Poll Contents',
      total: 0,
      active: 0,
      inactive: 0,
      statusList: 'All',
      getStatusData: getBlogStatus,
      getData: getBlog,
      getPostData: postBlog,

      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Poll Name",
          sort: true,

        },


        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "",
          text: "Contents Details",
          sort: true,
          formatter: (cell, prod, index) =>

            prod.description !== undefined && prod.description !== null ?

              prod.description.length !== 0 ?
                <div className="d-flex gap-3">
                  {
                    prod.description.map((d, index) =>
                      <Button
                        type="submit"
                        color="success"
                        key={index}
                        onClick={() => addDescription(prod._id, d._id, prod, d, 'Poll Description')}
                      >
                        {d.name}
                      </Button>
                    )}


                </div>
                : 'No Description'


              : 'No Description'
        },


      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'uploadType', name: 'uploadType', type: 'hidden', value: 'poll', disabled: '' },
        { title: 'pagelink', name: 'pagelink', type: 'hidden', value: 'pagelink', disabled: '' },
        { title: 'productdescription', name: 'productdescription', type: 'hidden', value: 'productdescription', disabled: '' },
        { title: `Name`, name: 'name', type: 'text', value: '', disabled: true },
        { title: 'Description', name: 'description', type: 'hidden', value: '', uploadImages: 'poll', multiple: true, disabled: '', required: true, gride: 12 },
      ],
      isLoading: true,
      addButton: false,
    },
    {
      name: 'Poll Option',
      title: 'Poll Options',
      total: 0,
      active: 0,
      inactive: 0,
      statusList: 'All',
      getStatusData: getBlogStatus,
      getData: getBlog,
      getPostData: postBlog,

      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Poll Name",
          sort: true,

        },
        {
          dataField: "",
          text: "Options",
          sort: true,
          formatter: (cell, prod, index) =>

            <div className="d-flex gap-3">

              <Button
                type="submit"
                color="success"
                key={index}
                onClick={() => differentPollOption(prod)}
              >
                Poll Option
              </Button>



            </div>

        },





      ],

      isLoading: true,
      addButton: false,
    },
    {
      name: 'Career',
      title: 'Career Master',
      total: 0,
      active: 0,
      inactive: 0,
      statusList: 'All',
      getStatusData: getBlogStatus,
      getData: getBlog,
      getPostData: postBlog,

      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Job Name",
          sort: true,

        },


        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 'Career', prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 'Career', prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 'Career', prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },


      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'metatitle', name: 'metatitle', type: 'hidden', value: 'metatitle', disabled: '', required: true },
        { title: 'uploadType', name: 'uploadType', type: 'hidden', value: 'career', disabled: '' },
        { title: 'slug', name: 'slug', type: 'hidden', value: 'slug', disabled: '', required: true },
        { title: 'Job Name', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Department', name: 'department', type: 'select', value: [], outputDropDown: 'name', outputData: getEmployee, table: 'Department', status: true, required: true, gride: 4 },
        { title: 'Designation', name: 'designation', type: 'select', value: [], outputDropDown: 'name', outputData: getEmployee, table: 'Designation', status: true, required: true, gride: 4 },
        { title: 'Number Of Positions', name: 'numberofposition', type: 'text', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Job Open Date', name: 'jobopendate', type: 'datetime-local', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Job Close Date', name: 'jobclosedate', type: 'datetime-local', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Short Description', name: 'sortdescription', type: 'textarea', value: '', disabled: '', required: true },
        { type: 'hidden', name: 'imagetype', value: 'product' },
        { title: 'Image', multiple: true, name: 'image', type: 'file', value: [], label: 'Image', uploadType: 'career', gride: 12 },
        { title: 'Job Status', name: 'jobstatus', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Job Open', name: 'jobstatus', value: true }, { title: 'Job Close', name: 'jobstatus', value: false }] },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: 'Career Description',
      title: 'Career Contents',
      total: 0,
      active: 0,
      inactive: 0,
      statusList: 'All',
      getStatusData: getBlogStatus,
      getData: getBlog,
      getPostData: postBlog,

      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Job Name",
          sort: true,

        },


        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "",
          text: "Job Details",
          sort: true,
          formatter: (cell, prod, index) =>

            prod.description !== undefined && prod.description !== null ?
              <div className="d-flex gap-3">
                {
                  prod.description.map((d, index) =>
                    <Button
                      type="submit"
                      color="success"
                      key={index}
                      onClick={() => addDescription(prod._id, d._id, prod, d, 'Career Description')}
                    >
                      {d.name}
                    </Button>
                  )}


              </div>
              : 'No Description'
        },

      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'uploadImages', name: 'uploadImages', type: 'hidden', value: 'dynamicpage', disabled: '' },
        { title: 'pagelink', name: 'pagelink', type: 'hidden', value: 'pagelink', disabled: '' },
        { title: 'productdescription', name: 'productdescription', type: 'hidden', value: 'productdescription', disabled: '' },
        { title: `Name`, name: 'name', type: 'text', value: '', disabled: true },
        { title: 'Description', name: 'description', type: 'hidden', value: '', uploadImages: 'dynamicpage', multiple: true, disabled: '', required: true, gride: 12 },
      ],
      isLoading: true,
      addButton: false,
    },
   
  ])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    for (let i = 0; i < cardElementStatus.length; i++) {
      cardElementStatus[i].getStatusData(cardElementStatus[i].name, cardElementStatus[i].statusList).then((res) => {
        cardElementStatus[i].active = res.active
        cardElementStatus[i].inactive = res.inactive
        cardElementStatus[i].total = res.total
        cardElementStatus[i].isLoading = false
        setCardElement([...cardElementStatus])
      })
    }
    setIsLoading(false)
  }, [])
  const changeTable = async (e, type, titles, prod) => {
    console.log(type);
    setIsLoading(true)
    const cardElementList = [...cardElement]

    setTitle(titles)
    if (type == 'Table' || type == true || type == false) {
      setOperationTable('Table')
      setColumns(cardElementList.find(d => d.name === titles).HeaderColumns)
      setOperationTable('Table')
      singleItem.status = true
      setSingleItem({ ...singleItem })
      await cardElementList.find(d => d.name === titles).getData(titles, "All").then(result => {
        console.log(result)
        if (result.success) {
          setData(result.data)
        } else {
          setData([])
        }
      })
      setIsLoading(false)
    } else if (type == 'View' || type == 'Deleted' || type == 'Edit') {
      setOperationTable(type)
      console.log(titles)




      await cardElementList.find(d => d.name === titles).getData(titles, prod._id).then(async result => {

        if (result.success) {
          for (let i = 0; i < cardElementList.find(d => d.name === titles).inputType.length; i++) {
            if (cardElementList.find(d => d.name === titles).inputType[i].type === 'multiple' || cardElementList.find(d => d.name === titles).inputType[i].type === 'select') {
              cardElementList.find(d => d.name === titles).inputType[i].value = []
              await cardElementList.find(d => d.name === titles).inputType[i].outputData(cardElementList.find(d => d.name === titles).inputType[i].table, cardElementList.find(d => d.name === titles).inputType[i].status).then(results => {
                for (let k = 0; k < results.data.length; k++) {

                  let data = {
                    label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList.find(d => d.name === titles).inputType[i].outputDropDown)],
                    value: results.data[k]._id,
                  }
                  cardElementList.find(d => d.name === titles).inputType[i].value.push(data)
                }
              })
            }
          }

          setSingleItem({ ...result.data })
          setInputType([...cardElementList.find(d => d.name === titles).inputType])
          setIsLoading(false)
        } else {
          setSingleItem({})
          setInputType([...cardElementList.find(d => d.name === titles).inputType])
          setIsLoading(false)
        }

      })

    } else if (type == 'Add') {
      setOperationTable('Add')
      for (let i = 0; i < cardElementList.find(d => d.name === titles).inputType.length; i++) {
        if (cardElementList.find(d => d.name === titles).inputType[i].type === 'multiple' || cardElementList.find(d => d.name === titles).inputType[i].type === 'select') {
          cardElementList.find(d => d.name === titles).inputType[i].value = []
          await cardElementList.find(d => d.name === titles).inputType[i].outputData(cardElementList.find(d => d.name === titles).inputType[i].table, cardElementList.find(d => d.name === titles).inputType[i].status).then(results => {
            for (let k = 0; k < results.data.length; k++) {

              let data = {
                label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList.find(d => d.name === titles).inputType[i].outputDropDown)],
                value: results.data[k]._id,
              }
              cardElementList.find(d => d.name === titles).inputType[i].value.push(data)
            }
          })
        }
      }


      setInputType([...cardElementList.find(d => d.name === titles).inputType])
      setOperationTable('Add')

      setIsLoading(false)
    }
  }


  const postData = (e, values, type) => {

    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (values.name !== undefined) {
      values['slug'] = values.name.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.name;
    }
    if (values.title !== undefined) {
      values['slug'] = values.title.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.title;
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    cardElementList.find(d => d.name === title).getPostData(isEmpty(values), title, operationsTable).then(result => {
      if (result.success) {
        setColumns([...cardElementList.find(d => d.name === title).HeaderColumns])
        setOperationTable('Table')
        cardElementList.find(d => d.name === title).getData(title, status).then(result => {
          if (result.success) {
            setData(result.data)
          } else {
            setData([])
          }

        })
      } else {
        alert('Hello')
      }
    })
  }




  const addDescription = async (prodid, id, pro, prod, type) => {
    console.log(prod)
    const allInputList = [...cardElement.find(d => d.name === type).inputType]
    // const cardElementList = [...cardElement]
    await getBlog(type, `${prodid}-${id}`).then(results => {
      console.log(results)
      if (Object.keys(results.data).length == 0) {
        if (prod.descriptiontype === 'file') {
          allInputList[5].label = 'Upload Image'
          allInputList[5].name = 'image'
          allInputList[5].type = prod.descriptiontype;
          allInputList[5].value = []
        } else if (prod.descriptiontype === 'Add More') {
          allInputList[5].name = prod.descriptiontype
          allInputList[5].type = prod.descriptiontype;

        } else if (prod.descriptiontype === 'videourl') {
          allInputList[5].title = 'Video Url'
          allInputList[5].name = 'video'
          allInputList[5].type = 'text';

        } else if (prod.descriptiontype === 'refurl') {
          allInputList[5].name = 'url'
          allInputList[5].type = 'text';

        } else if (prod.descriptiontype === 'quotation') {
          allInputList[5].name = 'quotation'
          allInputList[5].type = prod.descriptiontype

        } else if (prod.descriptiontype === 'Add More One Text Box and One Text Area') {

          setInputElemtnType('Add Mare Text')
        } else {
          allInputList[5].name = prod.descriptiontype
          allInputList[5].type = prod.descriptiontype;


        }
        setInputType([...allInputList])
        setSingleItem({ name: pro.name, productdescription: id, pagelink: prodid })
        setOperationTable('Add')
      } else {
        if (prod.descriptiontype === 'file') {
          allInputList[5].label = 'Upload Image'
          allInputList[5].name = 'image'
          allInputList[5].type = prod.descriptiontype;
          allInputList[5].value = []
        } else if (prod.descriptiontype === 'Add More') {
          allInputList[5].name = prod.descriptiontype
          allInputList[5].type = prod.descriptiontype;

        } else if (prod.descriptiontype === 'videourl') {
          allInputList[5].title = 'Video Url'
          allInputList[5].name = 'video'
          allInputList[5].type = 'text'

        } else if (prod.descriptiontype === 'refurl') {
          allInputList[5].title = 'Ref Url'
          allInputList[5].name = 'url'
          allInputList[5].type = 'text'

        } else if (prod.descriptiontype === 'quotation') {
          allInputList[5].title = 'Quotation'
          allInputList[5].name = 'quotation'
          allInputList[5].type = prod.descriptiontype

        } else if (prod.descriptiontype === 'Add More One Text Box and One Text Area') {

          setInputElemtnType('Add Mare Text')
        } else {

          allInputList[5].title = 'Description'
          allInputList[5].name = prod.descriptiontype
          allInputList[5].type = prod.descriptiontype;
        }
        setInputType([...allInputList])
        setSingleItem({ name: pro.name, ...results.data })
        setOperationTable('Edit')
      }
      console.log(results)
    })
  }
  const differentPollOption = (prod) => {
    console.log(prod)
    setSingleItem(prod)
    setOperationTable('Add More')
  }
  const postAddMoreData = (e, values, type) => {

    const FilteValue = []
    values[type].filter(d => Object.keys(d).map((o, k) => o != 'id' ? d[o] !== '' ? FilteValue.push(d) : '' : ''))
    console.log(FilteValue)
    const cardElementList = [...cardElement]
    singleItem[type] = FilteValue
    console.log(singleItem)
    cardElementList.find(d => d.name === title).getPostData(isEmpty(singleItem), title, 'Edit').then(result => {
      if (result.success) {
        setColumns([...cardElementList.find(d => d.name === title).HeaderColumns])
        setOperationTable('Table')
        cardElementList.find(d => d.name === title).getData(title, status).then(result => {
          if (result.success) {
            setData(result.data)
          } else {
            setData([])
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  const postAddMoreText = (e, values, type) => {
    console.log(values[type])
    const FilteValue = []
    const cardElementList = [...cardElement]
    values[type].pop()
    singleItem[type] = values[type]
    cardElementList.find(d => d.name === title).getPostData(singleItem, cardElementList.find(d => d.name === title).name, operationsTable).then(result => {
      if (result.success) {
        setColumns([...cardElementList.find(d => d.name === title).HeaderColumns])
        setOperationTable('Table')
        cardElementList.find(d => d.name === title).getData(title, status).then(result => {
          if (result.success) {
            setData(result.data)
          } else {
            setData([])
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Page Contents | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == '' ?
              <Breadcrumbs
                title={"CMS Dashboard"}
                breadcrumbItem={"Page Contents"}
                bredcumlink={"cms"}
              />
              : <Breadcrumbs
                title={'Page Contents'}
                breadcrumbItem={title}
                bredcumlink={'cms-page'}
              />
          }

          <Row>
            {

              operationsTable == '' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}>
                    {
                      isLoading ?

                        <UiLoading />

                        :
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type, title) => changeTable(e, type, title)}
                          addButton={d.addButton}
                        />
                    }

                  </Col>
                ))
                :
                operationsTable == 'Table' ?
                  <Card>
                    {
                      isLoading ?
                        <UiLoading />
                        : <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type, title) => changeTable(e, type, title)}
                          columns={columns}
                        />
                    }

                  </Card>
                  :
                  operationsTable == 'Add' || operationsTable == 'Edit' || operationsTable == 'Deleted' || operationsTable == 'View' ?
                    <>
                      {
                        isLoading ?
                          <UiLoading />
                          :
                          inputElemtnType === 'Add Mare Text' ?
                            <Card>
                              <CardBody>
                                <MultiRowTable
                                  handleValidSubmits={(e, v) => postAddMoreText(e, v, 'addmoretext')}
                                  inputList={{ id: Date.now().toString(), title: '', value: '' }}
                                  type={'addmoretext'}
                                  addMoreTable={singleItem['addmoretext'] !== undefined ? singleItem['addmoretext'] : []}
                                  multiRowheader={[{ title: "Sl. No." }, { title: "title" }, { title: 'value' }, { title: 'Deleted' }]}

                                  saveButton={operationsTable}
                                />
                              </CardBody>
                            </Card>

                            :
                            <InputFormElement
                              operationsTable={operationsTable}
                              singleItem={singleItem}

                              bredcumlink={'InformationPage'}
                              viewIcon={false}
                              filetype='single'

                              title={title}
                              changeTable={(e, type, title) => changeTable(e, type, title)}
                              inputType={inputType}
                              postData={postData}
                            />
                      }
                    </>
                    : operationsTable == 'Add More' ?
                      <Card>
                        <CardBody>
                          <MultiRowTable
                            handleValidSubmits={(e, v) => postAddMoreData(e, v, 'polloption')}
                            inputList={{ id: Date.now().toString(), poll: '', }}
                            type='polloption'
                            addMoreTable={singleItem.polloption !== undefined ? singleItem.polloption : []}
                            multiRowheader={[{ title: "Sl. No." }, { title: "Poll Option" }, { title: 'Deleted' }]}
                            saveButton={'Save'}
                          />
                        </CardBody>
                      </Card>


                      : ''
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default Blog
