import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,

} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { removeNodeAtPath } from 'react-sortable-tree';

import { url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"


import { getDynamicPageStatus, getDynamicPage, postDynamicPage } from "data/seo/AllDynamicPages"
import InputFormElement from "UI/InputFormElement"

import SortTreeRelation from "UI/sortTreeRelation"
import UiLoading from "UI/loading"
const AllDynamicPages = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  const [addButton,setAddButton]=useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({status:true})
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [inputType, setInputType] = useState([])
  const [treeData,setTreeData] = useState([])
  const [cardElement, setCardElement] = useState([
    {
      name: 'ALl Dynamic Page SEO',
      title: 'ALl Dynamic Page SEO',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getDynamicPageStatus,
      getData: getDynamicPage,
      getPostData:postDynamicPage,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,

        },
        {
          dataField: "metatitle",
          text: "Meta Title",
          sort: true,

        },
        {
          dataField: "slug",
          text: "Meta Slug",
          sort: true,

        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 0,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Meta Title', name: 'metatitle', type: 'text', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Meta Slug', name: 'slug', type: 'text', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Meta Description', name: 'metadescription', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Canonical Tag', name: 'canonicaltag', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Rebots', name: 'rebots', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
      ],
      isLoading: true,
      addButton: false,
     
    },
    {
      name: 'Og Titile',
      title: 'Og Titile',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getDynamicPageStatus,
      getData: getDynamicPage,
      getPostData:postDynamicPage,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Description Title",
          sort: true,

        },
        {
          dataField: "descriptiontype",
          text: "Description Type",
          sort: true,

        },
        {
          dataField: "validationtype",
          text: "Validation Type",
          sort: true,

        },
        {
          dataField: "validationnumber",
          text: "Validation Number",
          sort: true,

        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 0,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        {title:'Const Type', name:'conspage',type:'consType',value:[{label:'Home',value:'Home'},{label:'Login',value:'Login'},{label:'Forgot Password',value:'Forgot Password'}]},
        { title: 'Title', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton: false,
     
    },
    {
      name: 'Og Description',
      title: 'Og Description',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getDynamicPageStatus,
      getData: getDynamicPage,
      getPostData:postDynamicPage,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Description Title",
          sort: true,

        },
        {
          dataField: "descriptiontype",
          text: "Description Type",
          sort: true,

        },
        {
          dataField: "validationtype",
          text: "Validation Type",
          sort: true,

        },
        {
          dataField: "validationnumber",
          text: "Validation Number",
          sort: true,

        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 0,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Title', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Different Option', name: 'descriptiontype', type: 'consType', value: [{label:'blank',value:'blank'},{label:'Input',value:'Input'},{label:'Add More',value:'Add More'},{label:'Video',value:'Video'},{label:'Image',value:'Image'},{label:'Ref Url',value:'Ref Url'},{label:'Description',value:'Description'},{label:'Image',value:'image'}], disabled: '', required: true, gride: 12 },
        { title: 'Validation Type', name: 'validationtype', type: 'consType', value: [{label:'String',value:'String'},{label:'Number',value:'Number'},{label:'Number & String',value:'Number & String'},{label:'Number,String & Special Character',value:'Number,String & Special Character'}], disabled: '', required: true, gride: 12 },
        { title: 'Validation Number', name: 'validationnumber', type: 'text', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton: false,
     
    },
    {
      name: 'Og Image',
      title: 'Og Image',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getDynamicPageStatus,
      getData: getDynamicPage,
      getPostData:postDynamicPage,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Description Title",
          sort: true,

        },
        {
          dataField: "descriptiontype",
          text: "Description Type",
          sort: true,

        },
        {
          dataField: "validationtype",
          text: "Validation Type",
          sort: true,

        },
        {
          dataField: "validationnumber",
          text: "Validation Number",
          sort: true,

        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 0,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Title', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Different Option', name: 'descriptiontype', type: 'consType', value: [{label:'blank',value:'blank'},{label:'Input',value:'Input'},{label:'Add More',value:'Add More'},{label:'Video',value:'Video'},{label:'Image',value:'Image'},{label:'Ref Url',value:'Ref Url'},{label:'Description',value:'Description'},{label:'Image',value:'image'}], disabled: '', required: true, gride: 12 },
        { title: 'Validation Type', name: 'validationtype', type: 'consType', value: [{label:'String',value:'String'},{label:'Number',value:'Number'},{label:'Number & String',value:'Number & String'},{label:'Number,String & Special Character',value:'Number,String & Special Character'}], disabled: '', required: true, gride: 12 },
        { title: 'Validation Number', name: 'validationnumber', type: 'text', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton: false,
     
    },
  ])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    for(let i=0;i<cardElementStatus.length;i++){
        cardElementStatus[i].getStatusData(cardElementStatus[i].name,cardElementStatus[i].statusList).then((res) => {
        cardElementStatus[i].active = res.active
        cardElementStatus[i].inactive = res.inactive
        cardElementStatus[i].total = res.total
        cardElementStatus[i].isLoading = false
        setCardElement([...cardElementStatus])
      })
    }
  }, [])
  const changeTable = async (e, type, index,prod) => {
    e.preventDefault()
    setIsLoading(true)
    setIndexValue(index)
    const cardElementList = [...cardElement]
    setAddButton(cardElementList[index].addButton)
    setTitle(cardElementList[index].title)

  if (type == 'Table' || type == true || type == false) {
      setColumns(cardElementList[index].HeaderColumns)
      setOperationTable('Table')
    await cardElement[index].getData(cardElementList[index].name,type == 'Table'? "All" :type).then(result => {
        
        if (result.success) {
          setData(result.data)
        } else {
          setData([])
        }
      })
      setIsLoading(false)
    }else{
    
      setInputType([...cardElement[index].inputType])
      if(type == 'Edit' || type == 'View' || type == 'Deleted'){
        if(cardElement[index].name === 'Different Page Relation'){
          setSortTreeStatus(true)
        }
        await cardElement[index].getData(cardElement[index].name,prod._id).then(async results=>{
          setSingleItem(results.data)
          
          setIsLoading(false)
          setInputType([...cardElementList[index].inputType])
          setOperationTable(type)
        })
      }

    }
  }


  const postData = (e, values) => {
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    setIsLoading(true)
    cardElementList[indexValue].getPostData(values,cardElementList[indexValue].name, operationsTable).then(result => {
      if (result.success) {
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        cardElementList[indexValue].getData(cardElementList[indexValue].name, status).then(result => {
          if (result.success) {
            setData(result.data)
            setIsLoading(false)
          } else {
            setData([])
            setIsLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
 

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Page Structure | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
              <Breadcrumbs
                title={"Page Structure"}
                breadcrumbItem={"Page Structure"}
                bredcumlink={"page-structure"}
              />
              : <Breadcrumbs
                title={'Page Structure'}
                breadcrumbItem={title}
                bredcumlink={'page-structure'}
              />
          }

          <Row>
            {

              operationsTable == 'Card' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}> 
                
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type) => changeTable(e, type, index)}
                          addButton={d.addButton}
                        />
                  </Col>
                ))
                :
                operationsTable == 'Table' ?
               
                       isLoading ?
                     <UiLoading cardElementOption ={!isLoading ?true :false}/>
                  :
                     <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type) => changeTable(e, type, indexValue)}
                          columns={columns}
                          addButton={addButton}
                          selctdropDownList={(e,rowData)=>selctdropDownList(e,rowData,title)}
                          searchFilter={searchFilter}
                          selectSearchData={(e,rowData)=>selectSearchData(e,rowData,title)}
                          searchValueLists={(typelist,typedata)=>searchValueLists(typelist,typedata)}
                          isLoading={isLoading}
                        />
                    

                
                  :       
                  isLoading ?
                  <UiLoading cardElementOption ={!isLoading ?true :false}/>
               : 
                            <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}
                            bredcumlink={'page-structure'}
                            viewIcon={false}
                            filetype='single'
                            title={title}
                            changeTable={(e, type) => changeTable(e, type, indexValue)}
                            inputType={inputType}
                            postData={postData}
                            isLoading={isLoading}
                          />
                          }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default AllDynamicPages
