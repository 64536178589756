import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { authProtectedRoutes, url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"
import { getEmployee, postEmployee,getEmployeeStatus } from "data/systemadmin/employee"
import InputFormElement from "UI/InputFormElement"
import UiLoading from "UI/loading"
import { getCompany } from "data/systemadmin/company"
import { isEmpty } from "data/middlefunctions"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { result } from "lodash"
import { getSupplierStatus,getSupplier,postSupplier } from "data/systemadmin/supplier"

const CompanyList = () => {
  const [searchFilter,setSearchFilter] = useState({})
  const [indexValue,setIndexValue] = useState()
  const [addButton,setAddButton] = useState(false)
  const [titleData,setTitleData]= useState(localStorage.getItem('company')!== undefined && localStorage.getItem('company')!== null ?JSON.parse(localStorage.getItem('company')) :{})
  const [isLoading, setIsLoading] = useState(false)
  const [operationsTable, setOperationTable] = useState('')
  const [singleItem, setSingleItem] = useState({status:true,typeofuser:'Super User'})
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [inputType, setInputType] = useState([])
  const [subModuleId,setSubModuleId] = useState('')
  const [cardElement, setCardElement] = useState([
    {
      name: 'Company List',
      title: 'Supplier',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getSupplierStatus,
      getData: getSupplier,
      getPostData:postSupplier,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
        
        {
          dataField: "associate",
          text: "Associate",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.associate == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 0, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'product', disabled: '' },
        { title: 'Supplier Name', name: 'name', type: 'text', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Supplier Url', name: 'url', type: 'text', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Business Email', name: 'email', type: 'text', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Business Land Line', name: 'phone1', type: 'text', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Business Mobile', name: 'phone', type: 'text', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Finance Email', name: 'financeemail', type: 'text', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Finance Land Line', name: 'financeland', type: 'text', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Finance Mobile', name: 'phone2', type: 'text', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Address', name: 'address', type: 'textarea', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Open Text', name: 'opentext', type: 'textarea', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Open Text 1', name: 'opentext1', type: 'textarea', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true , gride: 12 },
        { title: 'Payment Condition', name: 'paymentcondtion', type: 'description', value: '', disabled: '', required: true , gride: 12 },
        { title: 'Document List', name: 'documentlist', type: 'multiple', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Type Of Document', status: true, disabled: '', required: true, },
        { title: 'Image', single: true, name: 'image', type: 'file', value: [],label:'Logo', uploadtype: 'product', },
        { title: 'Supplier Associate', name: 'supplierassociate', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'supplierassociate', value: true }, { title: 'In Active', name: 'supplierassociate', value: false }],gride:12 },
        { title: 'Company Associate', name: 'associate', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'associate', value: true }, { title: 'In Active', name: 'associate', value: false }],gride:12 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }],gride:12 },
      ],
      isLoading: true,
      addButton: true,
    },
    
  ])

  useEffect(async () => {
    console.log("lhoadjla")
    const cardElementStatus = [...cardElement]
    for(let i=0;i<cardElementStatus.length;i++){
        cardElementStatus[i].getStatusData(cardElementStatus[i].name,cardElementStatus[i].statusList).then((res) => {

        cardElementStatus[i].active = res.active
        cardElementStatus[i].inactive = res.inactive
        cardElementStatus[i].total = res.total
        cardElementStatus[i].isLoading = false
        setCardElement([...cardElementStatus])
      })
    
    }
    // setIsLoading(false)
  }, [])
  const changeTable = async (e, type, index, prod) => {
    setIndexValue(index)
    const cardElementList = [...cardElement]
    setTitle(cardElementList[index].title)
    setAddButton(cardElementList[index].addButton)
    if (type == 'Table' || type == true || type == false) {
      setIsLoading(true)
      await cardElement[index].getData(cardElementList[index].name, type == 'Table'?"All":status).then(result => {
        if (result.success) {
          setData(result.data)
          setIsLoading(false)
          setColumns(cardElementList[index].HeaderColumns)
          setOperationTable('Table')      
        } else {
          setData([])
          setOperationTable('Table')
          setColumns(cardElementList[index].HeaderColumns)
          setIsLoading(false)
        }
      })
    } else if (type == 'View' || type == 'Deleted' || type == 'Edit') {
      setIsLoading(true)
      await cardElement[index].getData(cardElement[index].name, prod._id).then(async result => {
        
        if (result.success) {
      
          for(let i=0;i<cardElementList[index].inputType.length;i++){
            if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
              cardElementList[index].inputType[i].value=[]
              await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
                  for (let k = 0; k < results.data.length; k++) {
                 
                          let data = {
                              label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                              value: results.data[k]._id,
                          }
                          cardElementList[index].inputType[i].value.push(data)
                        }
                  })
                  setInputType([...cardElementList[index].inputType])
                  setOperationTable(type)
                  setSingleItem({ ...result.data })
                  setIsLoading(false)
                }else{
                  setInputType([...cardElementList[index].inputType])
                  setOperationTable(type)
                  setSingleItem({ ...result.data })
                  setIsLoading(false)
                }
              
            }
        
        } else {
          setSingleItem({})
          setOperationTable(type)
          setIsLoading(false)
        }

      })
     
    }else if (type == 'Add') {
      setIsLoading(true)
      setOperationTable('Add')
      for(let i=0;i<cardElementList[index].inputType.length;i++){
        if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
          cardElementList[index].inputType[i].value=[]
          await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
              for (let k = 0; k < results.data.length; k++) {
             
                      let data = {
                          label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                          value: results.data[k]._id,
                      }
                      cardElementList[index].inputType[i].value.push(data)
                    }
              })
            }
        }
     
  
        setInputType([...cardElementList[index].inputType])
        setOperationTable('Add')
  
        setIsLoading(false)
    }
  }


  const postData = (e, values) => {
    console.log(values)
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    if(values.name !== undefined ){
      values['slug'] =values.name.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.name;
    }
    if( values.title !== undefined){
      values['slug'] =values.title.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.title;
    
    }

    if(title === 'Employee Module Assign'){
      delete values['modulepesmission'];
      values.submodule= subModuleId;
      cardElementList.find(d => d.name === 'Employee Module Assign').getPostData(isEmpty(values), 'Employee Sub Module Assign', 'Edit').then(result => {
        if (result.success) {
          setIsLoading(true)
          setColumns([...cardElementList.find(d => d.name === 'Employee Module Assign').HeaderColumns])
          setOperationTable('Table')
          getSupplier(title, status).then(result => {
            if (result.success) {
              setData(result.data)
              setIsLoading(false)
            } else {
              setData([])
              setIsLoading(false)
            }
  
          })
        } else {
          alert('Hello')
        }
      })
     }else if(title === 'Employee Document Upload'){
      cardElementList.find(d => d.name === 'Employee Document Upload').getPostData(isEmpty(values), 'Employee Document Upload', operationsTable).then(result => {
        if (result.success) {
          setIsLoading(true)
          setColumns([...cardElementList.find(d => d.name === 'Employee Document Upload').HeaderColumns])
          setOperationTable('Table')
          getSupplier(title, status).then(result => {
            if (result.success) {
              setData(result.data)
              setIsLoading(false)
            } else {
              setData([])
              setIsLoading(false)
            }
  
          })
        } else {
          alert('Hello')
        }
      })
     }else{
      cardElementList.find(d => d.title === title).getPostData(isEmpty(values), cardElementList.find(d => d.title === title).name, operationsTable).then(result => {
        if (result.success) {
          setColumns([...cardElementList.find(d => d.title === title).HeaderColumns])
          setOperationTable('Table')
          getSupplier(title, status).then(result => {
            if (result.success) {
              setData(result.data)
            } else {
              setData([])
            }
  
          })
        } else {
          alert('Hello')
        }
      })
     }
  }

 
  // Document Upload

 
   const selctdropDownList=async (e, rowData,index)=>{
    setSearchFilter(e)
    const cardElementList = [...cardElement]
    setMessagesLoading(true)
    setMessages(`${e.label} Data Loading ...`)    
  await cardElementList[index].getData(isEmpty({datalist:rowData}), title, e.label).then(result => {
    console.log(result)
    if(result.success) {
      setMessages(`${e.label} Data Successfully ...`)   
      if(e.label == "Export" || e.label == "Sample"){
        window.location.href=`${url.commonUrls}/download/${result.data.fileName}`
      }else{
         setIsLoading(true) 

         setColumns([...cardElementList[index].HeaderColumns])
         cardElementList[index].getData(cardElementList[index].title, status).then(result => {
            if (result.success) {
              setSearchFilter({})
              setData(result.data)
              setOperationTable('Table')
              setIsLoading(false)
            } else {
              setData([])
              setOperationTable('Table')
              setIsLoading(false)
            }
  
          })
       }
      } else {
        alert('Hello')
      }
    })
   
  }
  const selectSearchData=(e)=>{
    console.log(e)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Supplier | {titleData.name}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == '' ?
              <Breadcrumbs
                title={"System Admin"}
                breadcrumbItem={"Supplier"}
                bredcumlink={"company-list"}
              />
              : <Breadcrumbs
                title={'Supplier'}
                breadcrumbItem={title}
                bredcumlink={'company-list'}
              />
          }

          <Row>
            {

              operationsTable == '' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}>
                    {
                      isLoading ?

                        <UiLoading />

                        :
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type, title) => changeTable(e, type, index)}
                          addButton={d.addButton}
                        />
                    }

                  </Col>
                ))
                :
                operationsTable == 'Table' ?
                  <Card>
                    {
                      isLoading ?
                        <UiLoading />
                        : <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type, title) => changeTable(e, type, indexValue)}
                          columns={columns}
                          addButton={addButton}
                          selctdropDownList={(e,rowData)=>selctdropDownList(e,rowData,indexValue)}
                          searchFilter={searchFilter}
                          selectSearchData={(e,rowData)=>selectSearchData(e,rowData,indexValue)}
                          searchValueLists={(typelist,typedata)=>searchValueLists(typelist,typedata)}
                          isLoading={isLoading}
                        />
                    }

                  </Card>
                  :
                  operationsTable == 'Add' || operationsTable == 'Edit' || operationsTable == 'Deleted' || operationsTable == 'View' ?
                    <>
                      {
                        isLoading ?
                          <UiLoading />
                          :
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'employee'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type) => changeTable(e, type,indexValue)}
                            inputType={inputType}
                            postData={postData}
                          />
                      }
                    </>
                    : ''
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default CompanyList
