import {url} from '../routes'

export const postUploadFile = async(dat,type,name)=>{
    var data = new FormData()
    data.append('name',name)
    data.append('type',type)
data.append('file', dat)
    return fetch(`${url.fileUpload}`,{
        method:'POST',
        body:data
    })
    .then(res=> res.json())
    .then(result=>{
        return result
    })
    .catch(err=>{
        return err.response
    })
}

export const deleteUploadFile = async(dat,type)=>{
    var data = new FormData()
    data.append('type',type)
    data.append('file', dat)
    return fetch(`${url.fileUpload}`,{
        method:'DELETE',
        body:data
    })
    .then(res=> res.json())
    .then(result=>{
        return result
    })
    .catch(err=>{
        return err.response
    })
}