import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"
import { Link } from "react-router-dom"
import { removeNodeAtPath } from 'react-sortable-tree';
import SortTreeRelation from "UI/sortTreeRelation"
import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"
import { getpageStructureLinkStatus, getpageStructurLink, postPageStructureLink,getPageContent,getPageGallery,postPageContent,postPageGallery } from "data/pageStructureLink"
import InputFormElement from "UI/InputFormElement"
import { getpageStructure } from "data/pageStructure"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { getEmployee } from "data/systemadmin/employee"
import { isEmpty } from "data/middlefunctions"
import UiLoading from "UI/loading"
import CustomImageTab from "UI/CustomImageTab"
import CustomeTab from "UI/CustomTab"
import { getSupplier, getSupplierStatus } from "data/systemadmin/supplier"

const TypeOfProduction = () => {
  const [saveData,setSaveData] = useState(false)
  
  const [typeofpage,setTypeOfPage] = useState('')
  const [allPageLoading, setAllPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  const [addButton, setAddButton] = useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({ pagedescription: '', status: true,eventstructure:false })
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [pagedescriptionid, setPageDescriptionId] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [cardValue, setCardValue] = useState({})
  const [treeData,setTreeData] = useState([])
  const [inputType, setInputType] = useState([
    { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
    { title: `productcategoryid`, name: 'productcategoryid', type: 'hidden', value: 'productcategoryid', disabled: '', },
    { title: `Type Of Content`, name: 'pagedescription', type: 'text', value: '', disabled: true, gride: 4 },
    { title: 'Title', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Supplier', name: 'supplier', type: 'select', value: [], outputDropDown: 'name', outputData: getSupplier, table: 'Company List',filterData:getMiscellaneous,filterTable :'Category',filterType:true,filterList:'supplier', status: true, required: true, gride: 4 },
    { title: 'Category', name: 'category', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Category', filterData:getMiscellaneous,filterTable :'Category',filterType:true,filterList:'dependancycategory', status: true, required: true, gride: 4 },
    { title: 'Customer Type', name: 'customertype', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Customer Type', filterData:getMiscellaneous,filterTable :'Category',filterType:true,filterList:'customertype', status: true, required: true, gride: 4 },
    // { title: 'Supply Region', name: 'supplyregion', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous,table: 'Supply Region',filterData:getMiscellaneous,filterTable :'Category',filterType:true,filterList:'supplyregion', status: true, required: true, gride: 4 },
    { title: 'Type Of Meter', name: 'typeofmeter', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Type Of Meter',filterData:getMiscellaneous,filterTable :'Category',filterType:true,filterList:'typeofmeter', status: true, required: true, gride: 4 },
    // { title: 'Bill Payment', name: 'billpayment', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Bill Payment',filterData:getMiscellaneous,filterTable :'Category',filterType:true,filterList:'billpayment', status: true, required: true, gride: 4 },
    // { title: 'Billing Mode', name: 'billmode', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Billing Mode',filterData:getMiscellaneous,filterTable :'Category',filterType:true,filterList:'billmode', status: true, required: true, gride: 4 },
    { title: 'Package Start Date', name: 'startdate', type: 'date', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Package End date', name: 'enddate', type: 'date', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Coupone Amount', name: 'couponeamount', type: 'text', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Exit Vale', name: 'exitvalue', type: 'text', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Contarct Length', name: 'contractlength', type: 'text', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Discount', name: 'discount', type: 'multiple', value: [], outputDropDown: 'name', outputData: getMiscellaneous, table: 'Discount Value', status: 'Drop Down', required: true, gride: 4 },
    { title: 'Short Description', name: 'sortdescription', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
    { title: 'Image', name: 'image', type: 'file', value: [], single: true,label:"Image", uploadtype: 'page' },
    { title: 'Page Structure', name: 'pagestructurelink', type: 'multiple', value: [], outputDropDown: 'name', outputData: getpageStructure, table: 'Different Description Type', status: true, required: true, gride: 4 },
    { title: 'Product Structure', name: 'eventstructurelink', type: 'multiple', value: [], outputDropDown: 'name', outputData: getpageStructure, table: 'Different Description Type', status: true, required: true, gride: 4 },
    { title: 'Dependancy Product', name: 'pagedependency', type: 'multiple', value: [], outputDropDown: 'name', outputData: getpageStructurLink, table: 'Different Description Type', status: "Drop Down", required: true, gride: 4 },
    { title: 'Product', name: 'product', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'product', value: true }, { title: 'In Active', name: 'product', value: false }] },
    { title: 'Event', name: 'event', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'event', value: true }, { title: 'In Active', name: 'event', value: false }] },
    { title: 'Gallery', name: 'gallery', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'gallery', value: true }, { title: 'In Active', name: 'gallery', value: false }] },
    { title: 'Description Structure', name: 'descriptions', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'None', name: 'descriptions', value: 'none' }, { title: 'Product', name: 'descriptions', value: 'product' },{ title: 'Page', name: 'descriptions', value: 'page' },{ title: 'Both', name: 'descriptions', value: 'Both' }] },
    { title: 'Discount Setup', name: 'discountsetup', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'discountsetup', value: true }, { title: 'In Active', name: 'discountsetup', value: false }] },
    { title: 'Type of Coupone', name: 'typeofcoupone', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: '%', name: 'typeofdiscoutn', value: '%' }, { title: 'Amount', name: 'typeofdiscoutn', value: 'Amount' }] },
    { title: 'Mode Of Price', name: 'modeofprice', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Variable', name: 'modeofprice', value: 'variable' }, { title: 'Fixed', name: 'modeofprice', value: 'Fixed' }] },
    { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] },
    { title: '', name: 'uploadtype', type: 'hidden', value: 'page', disabled: '' }
  ])

  const [cardElement, setCardElement] = useState([])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    await getSupplierStatus('Category', 'All').then(async result => {
     
      const d = []
      if (result.success) {
        for (let i = 0; i < result.data.length; i++) {
             if(result.data[i].packagestatus){
              await getpageStructureLinkStatus(result.data[i]._id, 'Category').then(results => {
                 d.push({
                   _id: result.data[i]._id,
                   name: result.data[i].name,
                   title: result.data[i].name,
                   typeofpage:result.data[i].typeofpage,
                   total: results.total,
                   active: results.active,
                   inactive: results.inactive,
                   getStatusData: getpageStructurLink,
                   getData: getpageStructurLink,
                   getPostData: postPageStructureLink,
                   statusList: 'All',
                   HeaderColumns: [
                     {
                       dataField: "_id",
                       text: "SL. No",
                       sort: true,
                       // eslint-disable-next-line react/display-name
                       formatter: (cell, prod, index) => index + 1,
                     },
                     {
                       dataField: "name",
                       text: "Content Title",
                       sort: true,
                   },
                     {
                       dataField: "name",
                       text: "Supplier",
                       sort: true,
                       formatter: (cell, prod, index) =>(
                         prod.supplier != undefined && prod.supplier != null ? prod.supplier.name :'No Supplier'
                       )
 
                     },
                  {
                   dataField: "name",
                   text: "Catrgory",
                   sort: true,
                   formatter: (cell, prod, index) =>(
                     prod.category != undefined && prod.category != null ? prod.category.map(d=> `${d.name},`) :'No Category'
                   )
                  },
                  {
                   dataField: "name",
                   text: "Customer",
                   sort: true,
                  },
                   {
                   dataField: "startdate",
                   text: "Package Start",
                   sort: true,
                      },
                     {
                       dataField: "enddate",
                       text: "Package End",
                       sort: true,
                     },
                     {
                       dataField: "exitvalue",
                       text: "Exit Value",
                       sort: true,
                   },
                       {
                         dataField: "contractlength",
                         text: "Contract Length",
                         sort: true,
                     },
                       {
                         dataField: "modeofprice",
                         text: "Mode Of Price",
                         sort: true,
                     },
                       {
                         dataField: "couponeamount",
                         text: "Coupone",
                         sort: true,
                     },
                     {
                       dataField: "discount",
                       text: "Discount",
                       sort: true,
                       formatter: (cell, prod, index) =>(
                         prod.discount != undefined && prod.discount != null ? prod.discount.map(d=> `${d.name},`) :'No Category'
                       )
                     },
                    //  {
                    //    dataField: "pagedescriptionlink",
                    //    text: "Page Description",
                    //    sort: true,
                    //    formatter: (cell, prod, index) =>
                    //    <Button
                    //    color="success"
                    //    onClick={e => pageDescriptionList(e,prod._id,parseInt(d.length-1),'Page Description')}
                    //  >
                    //  Page Description
                    //  </Button>
                    //  },
                    //  {
                    //    dataField: "pagedescriptionlink",
                    //    text: "Page Structure",
                    //    sort: true,
                    //    formatter: (cell, prod, index) =>
                    //    <Button
                    //    color="success"
                    //    onClick={e => pageGalleryList(e,prod._id,parseInt(d.length-1),'Gallery')}
                    //  >
                    //  Page Gallery
                    //  </Button>
                    //  },
                    //  {
                    //    dataField: "pagedescriptionlink",
                    //    text: "Page Structure",
                    //    sort: true,
                    //    formatter: (cell, prod, index) =>
                    //    <Button
                    //    color="success"
                    //    onClick={e => descriptionRelationShip(e,prod._id,parseInt(d.length-1),'Page Structure')}
                    //  >
                    //  Page Structure
                    //  </Button>
                    //  },
                    //  {
                    //    dataField: "pagedescriptionlink",
                    //    text: "Product Structure",
                    //    sort: true,
                    //    formatter: (cell, prod, index) =>
                    //    <Button
                    //    color="success"
                    //    onClick={e => descriptionRelationShip(e,prod,parseInt(d.length-1),'Event Page Structure')}
                    //  >
                    //    Product Structure
                    //  </Button>
                       
                    //  },
                     {
                       dataField: "",
                       text: "Status",
                       sort: true,
                       formatter: (cell, prod, index) =>
                         prod.status == true ? <p>ON</p> : <p>OFF</p>,
                     },
                     {
                       dataField: "action",
                       isDummyField: true,
                       text: "Action",
                       // eslint-disable-next-line react/display-name
                       formatter: (cell, prod, index) => (
                         <>
                           <div className="d-flex gap-3">
                             <Link to="#" className="text-success">
                               <i
                                 className="mdi mdi-pencil font-size-18"
                                 id="edittooltip"
                                 onClick={e => changeTable(e, "Edit",parseInt(d.length-1), prod,result.data[i]._id,result.data[i])}
                               />
                             </Link>
                             <Link to="#" className="text-danger">
                               <i
                                 className="mdi mdi-delete font-size-18"
                                 id="deletetooltip"
                                 onClick={e => changeTable(e, "Deleted", parseInt(d.length-1), prod, result.data[i]._id,result.data[i].name)}
                               />
                             </Link>
                             <Link to="#" className="text-danger">
                               <i
                                 className="mdi mdi-eye font-size-18"
                                 id="deletetooltip"
                                 onClick={e => changeTable(e, "View", parseInt(d.length-1), prod, result.data[i]._id,result.data[i].name)}
                               />
                             </Link>
                           </div>
                         </>
                       ),
                     },
     
     
                   ],
     
                   isLoading: false,
                   addButton: true,
                 })
               })
             }
            
          

        }
        setCardElement(d)
        setAllPageLoading(false)
      }
    })
    // console.log(cardElementStatus)

    setIsLoading(false)

  }, [])
 
  const changeTable = async (e, type, index, prod, pagedescriptionid,prods) => {
    console.log(index)
    setTypeOfPage()
    // let inputTypes =[]
    let inputLists = [...inputType]
   
    
    
    // const inputLists = [...inputTypes]
    console.log(pagedescriptionid)
    setPageDescriptionId(pagedescriptionid)
    e.preventDefault()
    setCardValue(prods)

    setIndexValue(index)
    setAllPageLoading(true)
    const cardElementList = [...cardElement]
  
      setAddButton(true)
      setTitle(prods.name)
    

    if (type == 'Table' || type == true || type == false) {
      setColumns(cardElementList[index].HeaderColumns)
      setOperationTable('Table')

      await cardElement[index].getData(pagedescriptionid, type == 'Table' ? `All` : `${type}`,'Category').then(result => {

        if (result.success) {
          setData(result.data)
          setAllPageLoading(false)
        } else {
          setData([])
          setAllPageLoading(false)
        }
      })

    } else if (type == 'Add') {

      if (prod.name !== undefined) {
        setTitle(prod.name)
      } else {
        setTitle(cardElementList[index].title)
      }



      // for (let i = 0; i < inputLists.length; i++) {
      //   if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
      //     inputLists[i].value = []
      //     console.log(inputLists[i].title == 'Page Dependency')
      //     if(inputLists[i].title == 'Page Dependency'){
      //         await getpageStructurLink(pagedescriptionid,'Drop Down').then(result=>{
               
      //            if(result.success){
      //             for (let k = 0; k < result.data.length; k++) {
  
      //               let data = {
      //                 label: result.data[k].name,
      //                 value: result.data[k]._id,
      //               }
      //               inputLists[i].value.push(data)
      //             }
      //            }
      //         })
      //     }else{

      //       await inputLists[i].outputData(inputLists[i].table, inputLists[i].status).then(results => {
      //         for (let k = 0; k < results.data.length; k++) {
  
      //           let data = {
      //             label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
      //             value: results.data[k]._id,
      //           }
      //           inputLists[i].value.push(data)
      //         }
      //       })
      //     }
      //   }
      // }

      for(let i=0;i<inputLists.length;i++){
        
        if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
          inputLists[i].value=[]
          await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(async results => {
            console.log(inputLists[i].filterType)
            if(inputLists[i].filterType && inputLists[i].filterType != undefined && inputLists[i].filterType != null){
              console.log(results.data)
              for (let k = 0; k < results.data.length; k++) {
                let filetData =[]
                await inputLists[i].filterData(inputLists[i].filterTable,pagedescriptionid).then(result=>{
                  console.log(result)
                  if(result.success){
                    filetData=result.data[inputLists[i].filterList] != undefined && result.data[inputLists[i].filterList] != null ?result.data[inputLists[i].filterList] :[] 
                  }else{
                    filetData=[]
                  }
                
                })
                console.log(filetData.includes(results.data[k]._id))
                if(filetData.includes(results.data[k]._id)){
                  let data = {
                      label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                      value: results.data[k]._id,
                  }
                  inputLists[i].value.push(data)
                }
              }
            }else{
              for (let k = 0; k < results.data.length; k++) {
             
                let data = {
                    label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                    value: results.data[k]._id,
                }
                inputLists[i].value.push(data)
              }
            }
              
              })
                
           
            }
        }
      console.log(singleItem)
      setSingleItem({ status: true,eventstructure:false, pagedescription: prods.name, productcategoryid: prods._id })

      setInputType([...inputLists])
      setOperationTable('Add')
      setAllPageLoading(false)
      // setIsLoading(false)
    } else {
       if (type == 'Edit' || type == 'View' || type == 'Deleted') {

        await getpageStructurLink(prod.name, prod._id).then(async results => {
        
  
          setSingleItem({ pagedescription: prods.name, ...results.data })

          for(let i=0;i<inputLists.length;i++){
        
            if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
              inputLists[i].value=[]
             await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(async resul => {
                console.log(inputLists[i].filterType)
                if(inputLists[i].filterType && inputLists[i].filterType != undefined && inputLists[i].filterType != null){
                  console.log(resul.data)
                  for (let k = 0; k < resul.data.length; k++) {
                    let filetData =[]
                    await inputLists[i].filterData(inputLists[i].filterTable,pagedescriptionid).then(result=>{
                      console.log(result)
                      if(result.success){
                        filetData=result.data[inputLists[i].filterList] != undefined && result.data[inputLists[i].filterList] != null ?result.data[inputLists[i].filterList] :[] 
                      }else{
                        filetData=[]
                      }
                    
                    })
                 
                    if(filetData.includes(resul.data[k]._id)){
                      let data = {
                          label: resul.data[k][Object.keys(resul.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                          value: resul.data[k]._id,
                      }
                      inputLists[i].value.push(data)
                    }
                  }
                }else{
                  for (let k = 0; k < resul.data.length; k++) {
                 
                    let data = {
                        label: resul.data[k][Object.keys(resul.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                        value: resul.data[k]._id,
                    }
                    inputLists[i].value.push(data)
                  }
                }
                  
                  })
                    
               
                }
            }
      
          setInputType([...inputLists])
          setOperationTable(type)
          setAllPageLoading(false)
        })
      }

    }
  }


  const postData = (e, values) => {
   console.log(indexValue)
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    // if (operationsTable == 'Edit') {
    //   delete values['productcategoryid'];
    // }
    if (values.name !== undefined) {
      values['slug'] = values.name.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.name;
    }
    if (values.title !== undefined) {
      values['slug'] = values.title.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.title;
    }
    setAllPageLoading(true)
    setSaveData(true)
    postPageStructureLink(isEmpty(values), cardElementList[indexValue].name, operationsTable).then(result => {
      console.log(result.data.productcategoryid)
      alert(`Data ${operationsTable} Successfully`)
      setSaveData(false)
      if (result.success) {
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        getpageStructurLink(result.data.productcategoryid,true, 'Category').then(result => {
          if (result.success) {
            setData(result.data)
            setAllPageLoading(false)
          } else {
            setData([])
            setAllPageLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  const descriptionRelationShip=async (e,prod,index,title)=>{
  
    setOperationTable('Tree')
    const cardElementStatus = [...cardElement]
    setIndexValue(index)
    setIsLoading(true)
    await getpageStructurLink(prod.name,prod._id).then((result)=>{
      if (result.success) {
     
        setSingleItem(result.data)
        if(title === 'Event Page Structure'){

        
        if(result.data.eventstructurerelation !== undefined && result.data.eventstructurerelation !==null && result.data.eventstructurerelation.length != 0){
          const treeDatas =[]
          for(let i=0;i<result.data.eventstructurerelation.length;i++){
              const d={
                title:result.data.eventstructurerelation[i].titles.name,
                titles:result.data.eventstructurerelation[i].titles._id,
                children:[],
                expanded: true,
              }
              if(result.data.eventstructurerelation[i].children !== 0){
                for(let j=0;j<result.data.eventstructurerelation[i].children.length;j++){
                  d.children.push({
                    title:result.data.eventstructurerelation[i].children[j].titles.name,
                    titles:result.data.eventstructurerelation[i].children[j].titles._id,
                    children:[],
                    expanded: true,
                  })
                  if(result.data.eventstructurerelation[i].children[j].children !== 0){
                    for(let k=0;k<result.data.eventstructurerelation[i].children[j].children.length;k++){
                      d.children[j].children.push({
                        title:result.data.eventstructurerelation[i].children[j].children[k].titles.name,
                        titles:result.data.eventstructurerelation[i].children[j].children[k].titles._id,
                        children:[],
                        expanded: true,
                      })
                      if(result.data.eventstructurerelation[i].children[j].children[k].children !== 0){
                        for(let l=0;l<result.data.eventstructurerelation[i].children[j].children[k].children.length;l++){
                          d.children[j].children[k].children.push({
                            title:result.data.eventstructurerelation[i].children[j].children[k].children[l].titles.name,
                            titles:result.data.eventstructurerelation[i].children[j].children[k].children[l].titles._id,
                            children:[],
                            expanded: true,
                          })
                          if(result.data.eventstructurerelation[i].children[j].children[k].children[l].children !== 0){
                            for(let m=0;m<result.data.eventstructurerelation[i].children[j].children[k].children[l].children.length;m++){
                              d.children[j].children[k].children[l].children.push({
                                title:result.data.eventstructurerelation[i].children[j].children[k].children[l].children[m].titles.name,
                                titles:result.data.eventstructurerelation[i].children[j].children[k].children[l].children[m].titles._id,
                                children:[],
                                expanded: true,
                              })
                            }
                            
                          }
                        }
                       
                      }
                    }
                  
                  }
                
                }
            
                  
              }
             
              treeDatas.push(d)
          }
          setTreeData(treeDatas)
        
        }else{
          setTreeData([])
        }
      }else{
        if(result.data.pagestructurerelation.length != 0){
          const treeDatas =[]
          for(let i=0;i<result.data.pagestructurerelation.length;i++){
              const d={
                title:result.data.pagestructurerelation[i].titles.name,
                titles:result.data.pagestructurerelation[i].titles._id,
                children:[],
                expanded: true,
              }
              if(result.data.pagestructurerelation[i].children !== 0){
                for(let j=0;j<result.data.pagestructurerelation[i].children.length;j++){
                  d.children.push({
                    title:result.data.pagestructurerelation[i].children[j].titles.name,
                    titles:result.data.pagestructurerelation[i].children[j].titles._id,
                    children:[],
                    expanded: true,
                  })
                  if(result.data.pagestructurerelation[i].children[j].children !== 0){
                    for(let k=0;k<result.data.pagestructurerelation[i].children[j].children.length;k++){
                      d.children[j].children.push({
                        title:result.data.pagestructurerelation[i].children[j].children[k].titles.name,
                        titles:result.data.pagestructurerelation[i].children[j].children[k].titles._id,
                        children:[],
                        expanded: true,
                      })
                      if(result.data.pagestructurerelation[i].children[j].children[k].children !== 0){
                        for(let l=0;l<result.data.pagestructurerelation[i].children[j].children[k].children.length;l++){
                          d.children[j].children[k].children.push({
                            title:result.data.pagestructurerelation[i].children[j].children[k].children[l].titles.name,
                            titles:result.data.pagestructurerelation[i].children[j].children[k].children[l].titles._id,
                            children:[],
                            expanded: true,
                          })
                          if(result.data.pagestructurerelation[i].children[j].children[k].children[l].children !== 0){
                            for(let m=0;m<result.data.pagestructurerelation[i].children[j].children[k].children[l].children.length;m++){
                              d.children[j].children[k].children[l].children.push({
                                title:result.data.pagestructurerelation[i].children[j].children[k].children[l].children[m].titles.name,
                                titles:result.data.pagestructurerelation[i].children[j].children[k].children[l].children[m].titles._id,
                                children:[],
                                expanded: true,
                              })
                            }
                            
                          }
                        }
                       
                      }
                    }
                  
                  }
                
                }
            
                  
              }
             
              treeDatas.push(d)
          }
          setTreeData(treeDatas)
        
        }
      }
        setIsLoading(false)
      } else {
        setData({})
        setIsLoading(false)
      }
    })
  }
  const saveTreeData=()=>{
    const cardElementList = [...cardElement]
 
     setIsLoading(true)
     postPageStructureLink( {_id:singleItem._id,treeData:treeData},title, 'UpdateTree').then(result => {
      if (result.success) {
        // window.location.href=`${process.env.PUBLIC_URL}/page-structure-link`
        console.log(result.data.productcategoryid)
        
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        cardElementList[indexValue].getData(result.data.productcategoryid,'Category').then(result => {
          if (result.success) {
            setData(result.data)
            setIsLoading(false)
          } else {
            setData([])
            setIsLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  const treeDataValue=(tree)=>{
   
    setTreeData([...tree])
  }
  const removeNode =(rowInfo,indexValue)=>{
    const cardElements = [...cardElement]
    let {node, treeIndex, path} = rowInfo;
 
 
    
  const remove_data = removeNodeAtPath({
      treeData: treeData,
      path: path,   // You can use path from here
      getNodeKey: ({node: TreeNode, treeIndex: number}) => {
          // console.log(number);
          return number;
      },
      ignoreCollapsed: false,
   })
   setTreeData(remove_data)

   postPageStructureLink({
    _id:singleItem._id,
    remove_data:remove_data,
    rowid:rowInfo.node.titles
  },title ,'TreeDeleted').then(result=>{
    console.log(result)
  })
  }
  const pageDescriptionList=async(e,id,index,typeData)=>{
    setIsLoading(true)
    await getPageContent(id).then((result)=>{
      console.log(result)
      if(result.success){
        setData(result.data)
        setSingleItem(result.page)
        setOperationTable(typeData)
        setIsLoading(false)
      }else{
        setData([])
        setSingleItem({})
        setOperationTable(typeData)
        setIsLoading(false)
      }
    })
  }
  const submitFormData=async(e,data)=>{
    e.preventDefault()
    
    await postPageContent(data).then(result=>{
      
    })
  }
  const pageGalleryList=async(e,id,index,typeData)=>{
   
    setIsLoading(true)
    await getPageGallery(id).then((result)=>{
     
      if(result.success){
        setData(result.data)
        setSingleItem(result.single)
        setOperationTable("Gallery")
        setIsLoading(false)
      }else{
        setData([])
        setSingleItem({})
        setOperationTable("Gallery")
        setIsLoading(false)
      }
    })
  }
  const submitImageData=async(e,data)=>{
    console.log("hello")
    await postPageGallery(data).then(result=>{
      
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Category Manager | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
              <Breadcrumbs
                title={"Dynamic Page Dashboard"}
                breadcrumbItem={"Page Manager"}
                bredcumlink={"dynamic-page-structure"}
              />
              : <Breadcrumbs
                title={'Category Link'}
                breadcrumbItem={title}
                bredcumlink={'category-list'}
              />
          }

          <Row>
            {


              allPageLoading ?
                <UiLoading cardElementOption={!allPageLoading} />
                :
                operationsTable == 'Card' ?
                  cardElement.map((d, index) => (
                    <Col sm={4} md={3} key={index}>

                      <GalleryCardElementwithEvent
                        data={d}
                        changeTable={(e, type) => changeTable(e, type, index, { pagedescription: d.name,category:d._id }, d._id,d)}
                        addButton={d.addButton}
                        isLoading={isLoading}
                      />
                    </Col>
                  ))
                  :
                  operationsTable == 'Table' ?
                  isLoading ?
                  <UiLoading cardElementOption ={!isLoading}/>
               :
                    <Card>
                      <ReactTable
                        title={title}
                        datas={data}
                        changeTable={(e, type) => changeTable(e, type, indexValue, { pagedescription: cardValue.name }, cardValue._id,cardValue)}
                        columns={columns}
                        addButton={addButton}
                        selctdropDownList={(e,rowData)=>selctdropDownList(e,rowData,title)}
                        searchFilter={searchFilter}
                        selectSearchData={(e,rowData)=>selectSearchData(e,rowData,title)}
                        searchValueLists={(typelist,typedata)=>searchValueLists(typelist,typedata)}
                        isLoading={isLoading}
                      />




                    </Card>
                    :operationsTable == 'Tree' ?
                  
                    isLoading ?
                     <UiLoading cardElementOption ={!isLoading ?true :false}/>
                  :
                  <SortTreeRelation   isLoading={isLoading} treeData={treeData} saveTreeData={()=>saveTreeData()} treeDataValue={(tree)=>treeDataValue(tree)} removeNode={(rowInfo)=>removeNode(rowInfo,indexValue)}/>
                  
                  :operationsTable == 'Gallery' ?
                  
                  isLoading ?
                   <UiLoading cardElementOption ={!isLoading ?true :false}/>
                :
                <CustomImageTab  data={data} singleItem={singleItem} submitFormData={(e,data)=>submitImageData(e,data)}/>
                
                :operationsTable == 'Page Description' ?
                  
                isLoading ?
                 <UiLoading cardElementOption ={!isLoading ?true :false}/>
              :
              <CustomeTab  data={data} singleItem={singleItem} submitFormData={(e,data)=>submitFormData(e,data)}/>
              
              :      
                  isLoading ?
                  <UiLoading cardElementOption ={!isLoading}/>
               :

                    <InputFormElement
                      operationsTable={operationsTable}
                      singleItem={singleItem}

                      bredcumlink={'InformationPage'}
                      viewIcon={false}
                      filetype='single'

                      title={title}
                      changeTable={(e, type) => changeTable(e, type, indexValue, { pagedescription: cardValue.name }, cardValue._id,cardValue)}
                      inputType={inputType}
                      postData={postData}
                      isLoading={isLoading}
                      saveData={saveData}
                    />


            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default TypeOfProduction
